import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tw, { styled } from 'twin.macro'

import { ReactComponent as ArrowTopRightIcon } from '@/assets/images/icon-arrow-top-right.svg'
import { Button, ClipboardCopy, Tooltip } from '@/components/shared/'
import { useK2Activites, useNetworkBasedLinkFactories } from '@/hooks'
import { Transaction } from '@/hooks/useK2Activites'
import { formatNumber, humanReadableTxn } from '@/utils/global'

import styles from './styles.module.scss'

const tableHeader = ['Txn Hash', 'Description', 'Age ago', 'Amount']

interface IProps {
  initialized: boolean
}

export const TransactionHistoryTable: FC<IProps> = ({ initialized }) => {
  const [filteredActivites, setFilteredActivites] = useState<Transaction[]>([])
  const { makeEtherscanLink } = useNetworkBasedLinkFactories()
  const navigate = useNavigate()
  const { activitiesForHomePage } = useK2Activites()
  const [selectedDateRange, setSelectedDateRange] = useState(31557120)

  const DATE_RANGE_SELECTOR = [
    { id: 0, valueInSeconds: 86400, label: '1 day' },
    { id: 1, valueInSeconds: 604800, label: '7 days' },
    { id: 2, valueInSeconds: 2592000, label: '30 days' },
    { id: 3, valueInSeconds: 31557120, label: '12 months' }
  ]

  useEffect(() => {
    if (activitiesForHomePage.length > 0) {
      const filtered = activitiesForHomePage.filter((txn) => {
        return txn.secondsSince <= selectedDateRange
      })
      setFilteredActivites(filtered)
    }
  }, [activitiesForHomePage, selectedDateRange])

  return (
    <div className={styles.table}>
      <div className="flex items-center justify-between pb-6">
        <div className={styles.title}>Transaction History</div>
        <div className="left-align flex items-center p-1 gap-2 border border-tabBackground bg-tabBackground rounded-lg">
          {DATE_RANGE_SELECTOR.map((item, index) => (
            <TabItem
              isActive={selectedDateRange === item.valueInSeconds}
              key={index}
              onClick={() => {
                setSelectedDateRange(item.valueInSeconds)
              }}>
              {item.label}
            </TabItem>
          ))}
        </div>
      </div>
      {initialized ? (
        <table className={styles['auto-table']}>
          <thead className={styles.thead}>
            <tr className={styles.tr}>
              {tableHeader.map((header, idx) => (
                <th key={idx} className={`${styles.th} ${idx === 0 ? styles['left-text'] : ''}`}>
                  {' '}
                  <div className="inline-flex items-center">
                    <span className="mr-2">{header}</span>
                    {idx === 0 ? <Tooltip message="Transaction hash." /> : ''}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {filteredActivites.map((txn, index) => (
              <tr key={index} className={styles.tr}>
                <td className={`${styles.td} ${styles['txn-cell']}`}>
                  <span className="pr-2">{humanReadableTxn(txn.txnHash)}</span>
                  <ClipboardCopy copyText={txn.txnHash} />
                  <a
                    href={makeEtherscanLink(txn.txnHash)}
                    target="_blank"
                    rel="noreferrer"
                    className={'pl-1'}>
                    <ArrowTopRightIcon />
                  </a>{' '}
                </td>

                <td className={styles.td}>{txn.type}</td>
                <td className={styles.td}>{txn.ageAgo}</td>
                <td className={styles.td}>{formatNumber(txn.amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <>
          <hr className="border-grey200" />
          <p className="text-grey125 text-sm pt-4">No transactions to show.</p>
          <p className="text-grey125 text-sm pb-6">Get started below.</p>
          <Button
            onClick={() => {
              navigate('/supply')
            }}
            className="h-10 mr-4">
            Deposit Now
          </Button>
          <Button
            onClick={() => {
              navigate('/borrow')
            }}
            className="h-10">
            Start Borrowing
          </Button>
        </>
      )}
    </div>
  )
}

const TabItem = styled.div<{ isActive: boolean }>`
  ${tw`flex-1 py-2.5 rounded-lg text-sm text-center text-textBase font-medium bg-tabBackground hover:bg-navItemHover cursor-pointer min-w-[90px]`}

  ${(props) => props.isActive && tw`bg-activeTabBackground text-activeTabText`}
`
