import { BigNumber, constants, ethers } from 'ethers'
import { parseEther } from 'ethers/lib/utils.js'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useProvider } from 'wagmi'

import { ContractContext } from '@/context/ContractContext'
import { UserContext } from '@/context/UserContext'
import { bigToNum } from '@/utils/global'

import { useCustomAccount } from './useCustomAccount'
import { useTokens } from './useTokens'

export type BorrowAvailableToBorrowT = {
  availableToBorrow: number
  availableToBorrowUSD: number
}

export const useK2AvailableToBorrow = (outstandingInterest: BigNumber) => {
  const { account } = useCustomAccount()
  const {
    k2PoolContract,
    partitionedLinearInterestRateModelContract,
    kETHVaultContract,
    rETHTokenContract,
    dETHTokenContract,
    stETHContract,
    k2LendingDepositorContract
  } = useContext(ContractContext)
  const { ethPrice } = useContext(UserContext)
  const { DETH, ETH, RETH, STETH } = useTokens()

  const [result, setResult] = useState<BorrowAvailableToBorrowT>()

  const provider = useProvider()

  const fetchAvailableToBorrow = async (durationInSeconds = 0) => {
    if (
      k2PoolContract &&
      kETHVaultContract &&
      account &&
      rETHTokenContract &&
      dETHTokenContract &&
      stETHContract &&
      k2LendingDepositorContract &&
      partitionedLinearInterestRateModelContract
    ) {
      try {
        const debtPostion = await k2PoolContract.getDebtor(account.address)
        const isDebtPostionAvailable =
          debtPostion.debtor.toLowerCase() === account.address.toLowerCase()
        const currentBorrowAmount = isDebtPostionAvailable ? debtPostion.principalAmount : 0

        let duration
        if (isDebtPostionAvailable) {
          if (durationInSeconds !== 0) {
            duration = durationInSeconds
          } else {
            const currentUnixTimestamp: number = Math.floor(Date.now() / 1000)
            duration = debtPostion.endTimestamp - currentUnixTimestamp
          }
        } else {
          duration = (await k2PoolContract.borrowDuration()).toNumber()
        }

        const kethUserBalance: BigNumber = await kETHVaultContract.balanceOf(account.address)
        const rethUserBalance: BigNumber = await rETHTokenContract.balanceOf(account.address)
        const dethUserBalance: BigNumber = await dETHTokenContract.balanceOf(account.address)
        const stethUserBalance: BigNumber = await stETHContract.balanceOf(account.address)
        const ethUserBalance: BigNumber = await provider.getBalance(account.address)
        const stETHAmount = await k2LendingDepositorContract.getPayAmountForKETH(
          parseEther('1'),
          STETH.address
        )

        const rETHAmount = await k2LendingDepositorContract.getPayAmountForKETH(
          parseEther('1'),
          RETH.address
        )
        const dETHAmount = await k2LendingDepositorContract.getPayAmountForKETH(
          parseEther('1'),
          DETH.address
        )

        const ETHAmount = await k2LendingDepositorContract.getPayAmountForKETH(
          parseEther('1'),
          ETH.address
        )

        const stETHBalance = stethUserBalance
          .mul(parseEther('1'))
          .div(stETHAmount)
          .mul(BigNumber.from('100'))
          .div(BigNumber.from('105'))

        const rETHBalance = rethUserBalance
          .mul(parseEther('1'))
          .div(rETHAmount)
          .mul(BigNumber.from('100'))
          .div(BigNumber.from('105'))

        const dETHBalance = dethUserBalance
          .mul(parseEther('1'))
          .div(dETHAmount)
          .mul(BigNumber.from('100'))
          .div(BigNumber.from('105'))

        const ETHBalance = ethUserBalance
          .mul(parseEther('1'))
          .div(ETHAmount)
          .mul(BigNumber.from('100'))
          .div(BigNumber.from('105'))

        const maxBalance = [
          kethUserBalance,
          rETHBalance,
          dETHBalance,
          stETHBalance,
          ETHBalance
        ].reduce((a, i) => (a.gt(i) ? a : i), constants.Zero)

        const availableToBorrowBN =
          await partitionedLinearInterestRateModelContract.getMaxBorrowableAmount(
            maxBalance.add(outstandingInterest),
            currentBorrowAmount,
            0,
            0,
            duration
          )

        const availableToBorrow = bigToNum(availableToBorrowBN)
        const kETHValutTotalSupply: BigNumber = await kETHVaultContract.totalSupply()
        const kETHValutTotalAssets: BigNumber = await kETHVaultContract.totalAssets()
        const ethValue =
          (availableToBorrow * bigToNum(kETHValutTotalAssets)) / bigToNum(kETHValutTotalSupply)
        const availableToBorrowUSD = ethValue * ethPrice

        setResult({
          availableToBorrow,
          availableToBorrowUSD
        })
      } catch (err) {
        setResult({
          availableToBorrow: 0,
          availableToBorrowUSD: 0
        })
        console.log('k2PoolContract, kETHVault reading err: ', err)
      }
    }
  }

  useEffect(() => {
    fetchAvailableToBorrow()
  }, [
    outstandingInterest.toString(),
    k2PoolContract,
    kETHVaultContract,
    rETHTokenContract,
    dETHTokenContract,
    account,
    ethPrice,
    stETHContract,
    k2LendingDepositorContract
  ])

  return { result, fetchAvailableToBorrow }
}
