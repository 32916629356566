import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tw, { styled } from 'twin.macro'

import { ReactComponent as CloseIcon } from '@/assets/images/close-icon.svg'
import NativeDelegationLogoSrc from '@/assets/images/native-delegation-logo.gif'

import { Button } from '../../shared'
import ModalConfirmLearnDelegate from '../Modals/ModalConfirmLearnDelegate'
import ModalNativeDelegation from '../Modals/ModalNativeDelegation'

export const NativeDelegationCard = () => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [isHidden, setIsHidden] = useState<boolean>(false)

  const handleLearn = () => {
    window.open(
      'https://docs.restaking.cloud/middleware/tutorials/install-native-delegation',
      '_blank'
    )
    setIsOpen(false)
    navigate('/supply')
  }

  return (
    <>
      {!isHidden && (
        <Card>
          <div className="absolute top-3 right-3 cursor-pointer" onClick={() => setIsHidden(true)}>
            <CloseIcon fill="black" />
          </div>
          <img src={NativeDelegationLogoSrc} className="w-32 h-32" />
          <div className="flex flex-col gap-4">
            <div className="font-semibold">Got Validators?</div>
            <div className="font-medium text-sm">
              Earn extra yield without changing your details
            </div>
            <div className="text-sm">
              <Button size="lg" onClick={() => setIsOpen(true)}>
                Native Delegation
              </Button>
            </div>
          </div>
          <ModalNativeDelegation
            open={isOpen}
            onClose={() => setIsOpen(false)}
            onConfirm={handleLearn}
          />
          <ModalConfirmLearnDelegate
            open={isConfirmModalOpen}
            onClose={() => setIsConfirmModalOpen(false)}
          />
        </Card>
      )}
    </>
  )
}

const Card = styled.div`
  ${tw`w-full py-6 flex gap-6 items-center justify-center rounded-2xl text-black mb-12 text-[32px] relative`}

  background: linear-gradient(180deg, #15eb8b 0%, #45e1e3 100%);
`
