import React, { FC, useEffect, useState } from 'react'

import { ReactComponent as ArrowTopRightIcon } from '@/assets/images/icon-arrow-top-right.svg'
import { ReactComponent as SearchIcon } from '@/assets/images/icon-search.svg'
import { ClipboardCopy, Tooltip } from '@/components/shared/'
import { useK2Activites, useNetworkBasedLinkFactories } from '@/hooks'
import { Transaction } from '@/hooks/useK2Activites'
import { humanReadableTxn } from '@/utils/global'

import styles from './styles.module.scss'

const tableHeader = [
  { title: 'Txn Hash', tooltip: 'Transaction hash.' },
  { title: 'Age ago', tooltip: 'Transaction happened.' },
  { title: 'Amount', tooltip: 'Amount of tokens received.' },
  { title: 'Type', tooltip: 'Type of the transaction.' }
]

export const TransactionTable: FC = () => {
  const { makeEtherscanLink } = useNetworkBasedLinkFactories()
  const [searchQuery, setSearchQuery] = useState('')
  const [displayedTransactions, setDisplayedTransactions] = useState<Transaction[]>([])

  const { activitiesForClaimPage } = useK2Activites()

  useEffect(() => {
    if (!activitiesForClaimPage) return
    const filteredTransactions = activitiesForClaimPage.filter((txn) =>
      txn.txnHash.includes(searchQuery)
    )
    setDisplayedTransactions(filteredTransactions)
  }, [searchQuery, activitiesForClaimPage])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.currentTarget.value)
  }

  return (
    <>
      <div className={`${styles['header-container']}`}>
        <div className={`${styles.title} ${styles['left-align']}`} style={{ textAlign: 'left' }}>
          Transaction
        </div>
        <div className={styles['search-container']}>
          <SearchIcon className={styles['search-icon']} />
          <input
            type="text"
            placeholder="Search by address / Txn hash"
            className={styles['search-bar']}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className={styles.table}>
        <table className={styles['auto-table']}>
          <thead className={styles.thead}>
            <tr className={styles.tr}>
              {tableHeader.map((header, idx) => (
                <th
                  key={idx}
                  className={`${styles.th} ${styles['no-border-top']} ${
                    idx === 0 ? styles['left-text'] : ''
                  }`}>
                  {' '}
                  <div className="inline-flex items-center">
                    <span className="mr-2">{header.title}</span>
                    <Tooltip message={header.tooltip} />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {displayedTransactions.map((txn, index) => (
              <tr key={index} className={styles.tr}>
                <td className={`${styles.td} ${styles['txn-cell']}`}>
                  <span className="pr-2">{humanReadableTxn(txn.txnHash)}</span>
                  <ClipboardCopy copyText={txn.txnHash} />
                  <a
                    href={makeEtherscanLink(txn.txnHash)}
                    target="_blank"
                    rel="noreferrer"
                    className={'pl-1'}>
                    <ArrowTopRightIcon />
                  </a>{' '}
                </td>
                <td className={styles.td}>{txn.ageAgo}</td>
                <td className={styles.td}>{txn.amount}</td>
                <td className={styles.td}>{txn.type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
