import { ethers } from 'ethers'
import { FC, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { mainnet, useNetwork } from 'wagmi'

import { ReactComponent as YellowAlertIcon } from '@/assets/images/icon-alert-yellow-big.svg'
import { ReactComponent as TopRightIcon } from '@/assets/images/icon-arrow-top-right.svg'
import { ReactComponent as TopRightYellowIcon } from '@/assets/images/icon-arrow-top-right-yellow.svg'
import KETHIcon from '@/assets/images/icon-keth-new.svg'
import { ModalSlashDebtRepayment } from '@/components/app/Modals'
import {
  CompletedTxView,
  ErrorModal,
  LoadingModal,
  ModalDialog,
  Tooltip
} from '@/components/shared'
import { UserContext } from '@/context/UserContext'
import {
  useConfig,
  useFarmingModes,
  useIfRSTDebtor,
  useK2RestakingInfo,
  useK2TerminateSBP,
  useMakeRealTxHash,
  useNetworkBasedLinkFactories,
  useTokens
} from '@/hooks'
import { bigToNum, numToBig, readablePrice, roundNumber } from '@/utils/global'
import { formatNumber } from '@/utils/global'
import { addTokenToMetaMask } from '@/utils/tokens'

export const BorrowInfoSection: FC = () => {
  const { result } = useK2RestakingInfo()
  const [fee, setFee] = useState(0)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [terminateSBPfailed, setTerminateSBPfailed] = useState(false)
  const { onTerminateSBP, isTerminatingSBP, txResult, onClear, validationError, hasSlashed } =
    useK2TerminateSBP()
  const { K2_FARMING_MODE } = useFarmingModes()

  const { chain: activeChain } = useNetwork()
  const config = useConfig()
  const isMainnet = activeChain?.id == mainnet.id && config.isPrivateMainnet == 'true'

  const { isRSTDebtor, ticker, tokenUrl } = useIfRSTDebtor()

  const { hash } = useMakeRealTxHash(txResult?.hash)
  const { makeEtherscanLink } = useNetworkBasedLinkFactories()
  const { refreshData } = useContext(UserContext)
  const { K2Pool } = useTokens()

  const navigate = useNavigate()

  const handleConfirmModalOpen = () => {
    setOpenConfirmModal(true)
  }

  const handleTerminateSBP = async () => {
    const wasSucceed = await onTerminateSBP(isRSTDebtor)
    if (!wasSucceed) setTerminateSBPfailed(true)
    else {
      refreshData()
    }
  }

  const handleGoToHome = () => {
    navigate('/')
  }

  const handleCloseSuccessModal = () => {
    onClear()
  }

  return (
    <>
      <div className="flex-1 md:flex-6 items-stretch rounded-2xl bg-background100 border border-borderColor overflow-hidden">
        <div className="w-full h-28 overflow-hidden">
          <img src={K2_FARMING_MODE?.bg} alt="card-bg" className="w-full h-full object-fill" />
        </div>
        <div className="w-full p-4 flex flex-col gap-4">
          <div className="w-full -mt-12 flex flex-col">
            <div className="relative h-18 mb-2.5">
              {K2_FARMING_MODE?.icons.map((icon: any, idx: number) => (
                <div key={idx} className="absolute top-0" style={{ left: 48 * idx }}>
                  {<img src={icon} alt="icon" />}
                </div>
              ))}
            </div>
            <p className="text-2xl text-textBase font-semibold mb-1">K2 Restaking</p>
            <p className="text-textLabel">Borrow K2 for economic restaking security.</p>
          </div>
          <div className="w-full bg-grey600" style={{ height: 1 }}></div>
          <div className="w-full pt-2 flex gap-4">
            <div className="flex-1 h-22 rounded-2xl bg-background200 py-2 px-4">
              <div className="flex items-center text-textLabel text-sm mb-3">
                <p className="pr-2">Total Borrowed</p>
                <Tooltip message="The amount of K2 security for your borrower position." />
              </div>
              {result?.isDebtPosition ? (
                <>
                  <div className="flex justify-between items-center">
                    <p className="text-3xl text-white font-bold">
                      ${readablePrice(result?.totalBorrowed || 0)}
                    </p>
                    <div className="flex items-center gap-3">
                      <div className="flex gap-1 items-center">
                        <img src={KETHIcon} alt="K2" className="w-4" />
                        <p className="text-sm text-textBase">ETH</p>
                      </div>
                      <div className="flex gap-1 items-center">
                        <p className="text-sm text-textLabel">
                          {roundNumber(bigToNum(result?.kethValue || 0), 3)}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <p className="text-textLabe ">{isMainnet ? 'Restricted Info' : '--'}</p>
              )}
            </div>
            <div className="flex-1 h-22 rounded-2xl bg-grey950 py-2 px-4">
              <div className="flex items-center text-textLabel text-sm mb-3">
                <p className="pr-2">Slashing Debt</p>
                <Tooltip message="Any slashing that occurs will be taken from your interest and your duration will be adjusted. You can top up anytime to extend the duration. If unpaid slashing debt accrues to 30% or more of the remaining interest the position will automatically be canceled and remaining funds returned to you." />
              </div>
              {result?.isDebtPosition ? (
                <>
                  <div className="flex justify-between items-center text-yellowAlert text-3xl font-semibold">
                    <div className="flex items-center">
                      <YellowAlertIcon className="mr-2" />$
                      {formatNumber(result?.unpaidSlashDebtUsd)}
                    </div>
                    <div
                      className={
                        result?.unpaidSlashDebtUsd > 0 ? 'cursor-pointer' : 'cursor-not-allowed'
                      }
                      onClick={result?.unpaidSlashDebtUsd > 0 ? handleConfirmModalOpen : () => {}}>
                      <TopRightYellowIcon className="ml-2" />
                    </div>
                  </div>
                </>
              ) : (
                <p className="text-textLabe ">{isMainnet ? 'Restricted Info' : '--'}</p>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col mt-4">
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center">
                <p className="text-sm text-white mr-2">Duration</p>
                <Tooltip message="The amount of time left on your borrower position. This will be automatically readjusted with slashing." />
              </div>
              <p className="text-sm text-textLabel mb-1">
                {result?.isDebtPosition ? <span>{result?.duration}</span> : <span>--</span>}
              </p>
            </div>
            {isRSTDebtor && (
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <p className="text-sm text-white mr-2">{ticker} Token</p>
                  <Tooltip message="RST Token Address." />
                </div>
                <a
                  className="text-sm text-textLabel mb-1 flex items-center gap-1"
                  href={tokenUrl}
                  target="_blank"
                  rel="noreferrer">
                  Etherscan <TopRightIcon />
                </a>
              </div>
            )}
            <div className="flex items-center">
              <div>
                <p className="text-sm text-white mr-2">Add K2 to Metamask</p>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  addTokenToMetaMask(K2Pool)
                }}>
                <TopRightIcon />
              </div>
            </div>
          </div>
        </div>
        {validationError && (
          <p className="font-medium text-sm flex items-center gap-2 text-red mt-4 ml-4">
            {validationError.error}
          </p>
        )}
        {result?.isDebtPosition && (
          <div
            className="text-base text-center bg-background100 border border-grey500 rounded-lg px-3 py-2 w-1/4 ml-3 mt-2 mb-18 cursor-pointer"
            onClick={handleTerminateSBP}>
            Cancel SBP
          </div>
        )}
      </div>
      <LoadingModal
        open={isTerminatingSBP}
        message={hasSlashed ? 'You need to top-up your slashing debt to terminate.' : ''}
        title="Termination Pending"
        onClose={() => {}}
      />
      <ErrorModal
        open={!!terminateSBPfailed}
        onClose={() => {
          setTerminateSBPfailed(false)
        }}
        title="Error!"
        message={'It looks like something went wrong.'}
        actionButtonContent="Try Again"
        onAction={() => {
          setTerminateSBPfailed(false)
        }}
      />
      <ModalDialog open={!!txResult} onClose={handleCloseSuccessModal}>
        <CompletedTxView
          goToContent="Home"
          title="Success"
          txLink={makeEtherscanLink(hash)}
          onGoToClick={handleGoToHome}
          message={
            <span className="text-sm text-grey300">{`You’ve successfully canceled your borrower position.`}</span>
          }
        />
      </ModalDialog>
      <ModalSlashDebtRepayment
        open={openConfirmModal}
        setOpenConfirmModal={setOpenConfirmModal}
        unpaidDebt={result?.unpaidSlashDebt || ethers.BigNumber.from('0')}
        unpaidDebtUsd={result?.unpaidSlashDebtUsd || 0}
        fee={fee}
      />
    </>
  )
}
