import { useContext } from 'react'

import { EnvironmentContext } from '@/context/EnvironmentContext'

export type TokenT = {
  id: number
  symbol: string
  label: string
  icon: string
  address?: string
}

export const useTokens = () => {
  const data = useContext(EnvironmentContext)

  return data
}
