import { FC } from 'react'

import { ACTIVITY_TYPE } from '@/constants/activity'
import { useNetworkBasedLinkFactories } from '@/hooks'

const Description: FC<{ activity: any }> = ({ activity }) => {
  const { makeEtherscanLink } = useNetworkBasedLinkFactories()

  switch (activity.type) {
    case ACTIVITY_TYPE.K2Deposit:
      return (
        <span className="description">
          Supplied kETH{' '}
          <a
            href={makeEtherscanLink(activity.tx)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.K2Withdraw:
      return (
        <span className="description">
          Withdrew kETH{' '}
          <a
            href={makeEtherscanLink(activity.tx)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.K2Borrow:
      return (
        <span className="description">
          Borrowed kETH{' '}
          <a
            href={makeEtherscanLink(activity.tx)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.K2Claim:
      return (
        <span className="description">
          Claimed kETH{' '}
          <a
            href={makeEtherscanLink(activity.tx)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.K2NodeOperatorDeposit:
      return (
        <span className="description">
          Native Delegation{' '}
          <a
            href={makeEtherscanLink(activity.tx)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.K2NodeOperatorWithdraw:
      return (
        <span className="description">
          Withdrew kETH from node operator{' '}
          <a
            href={makeEtherscanLink(activity.tx)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.K2NodeOperatorClaim:
      return (
        <span className="description">
          Claimed node operator kETH{' '}
          <a
            href={makeEtherscanLink(activity.tx)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.K2Slash:
      return (
        <span className="description">
          Slashed{' '}
          <a
            href={makeEtherscanLink(activity.tx)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.K2Terminate:
      return (
        <span className="description">
          Canceled borrower position{' '}
          <a
            href={makeEtherscanLink(activity.tx)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.K2Liquidate:
      return (
        <span className="description">
          Liquidated{' '}
          <a
            href={makeEtherscanLink(activity.tx)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.K2TopUp:
      return (
        <span className="description">
          Paid slashing debt{' '}
          <a
            href={makeEtherscanLink(activity.tx)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.K2IncreaseDebt:
      return (
        <span className="description">
          Increased debt{' '}
          <a
            href={makeEtherscanLink(activity.tx)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    default:
      return <></>
  }
}

export default Description
