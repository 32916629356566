import { BigNumber } from 'ethers'
import { useContext, useState } from 'react'

import { ContractContext } from '@/context/ContractContext'
import { RAY } from '@/utils/contractFunctions'
import { handleErr, notifyHash, noty } from '@/utils/global'

import { useTokens } from '.'
import { useAllowanceCheck } from './useAllowanceCheck'
import { useConfig } from './useConfig'
import { useCustomAccount } from './useCustomAccount'

export type ValidationError = {
  error: string
}

export const useK2TerminateSBP = () => {
  const { k2PoolContract, kETHVaultContract, RSTContract } = useContext(ContractContext)
  const { account, isGnosis } = useCustomAccount()
  const config = useConfig()
  const { KETH } = useTokens()

  const [txResult, setTxResult] = useState<any>()
  const [isTerminatingSBP, setIsTerminatingSBP] = useState(false)
  const [validationError, setValidationError] = useState<ValidationError>()
  const [hasSlashed, setHasSlashed] = useState<boolean>(false)

  const { allowance, isApproving, handleApproveToken } = useAllowanceCheck(
    KETH?.address,
    k2PoolContract?.address!
  )

  const onTerminateSBP = async (isRSTDebtor: boolean = false) => {
    const debtPostion = await k2PoolContract?.getDebtor(account.address)
    const slashAmount: BigNumber = debtPostion.slashAmount

    // const assumedLiquidity = await k2PoolContract.assumedLiquidity()
    // const outstandingInterest = await k2PoolContract.getOutstandingInterest(account.address)
    // const maxSlashableRatio = await k2PoolContract.maxSlashableRatio_RAY()
    // const maxSlashableAmount = outstandingInterest.mul(maxSlashableRatio).div(RAY)
    // if (assumedLiquidity.gt(maxSlashableAmount)) {
    //   setValidationError({
    //     error: `Assumed liquidity should be smaller than maxSlashableAmount`
    //   })
    //   return
    // }

    setIsTerminatingSBP(true)
    let tx

    try {
      if (isRSTDebtor) {
        const _tx = await RSTContract?.terminateRST()
        await _tx.wait()
      }
      if (slashAmount.gt(0)) {
        setHasSlashed(true)
        const minTransferLimit = await kETHVaultContract?.minTransferAmount()
        const amount = slashAmount.lt(minTransferLimit) ? minTransferLimit : slashAmount

        let isError = false
        if (!allowance || allowance.lt(amount)) {
          isError = await handleApproveToken(amount)
        }
        if (isError) {
          noty('Something went wrong when approving. Terminating SBP failed.')
          setIsTerminatingSBP(false)
          return false
        } else tx = await k2PoolContract?.topUpAndTerminate(amount)
      } else {
        tx = await k2PoolContract?.terminate(account.address)
      }
      if (tx) {
        if (!isGnosis) notifyHash(tx.hash, config.networkId)
        await tx.wait()
        setTxResult(tx)
      }
    } catch (err) {
      console.log('Terminating SBP error: ', err)
      noty(handleErr(err, 'Something went wrong. Terminating SBP failed.'))
      setIsTerminatingSBP(false)
      setHasSlashed(false)
      return false
    }
    setHasSlashed(false)
    setIsTerminatingSBP(false)

    return true
  }

  const onClear = () => {
    setTxResult(undefined)
  }

  return { onTerminateSBP, onClear, hasSlashed, isTerminatingSBP, txResult, validationError }
}
