import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  CompletedTxView,
  ErrorModal,
  LoadingModal,
  ModalDialog,
  TextInput
} from '@/components/shared'
import {
  useK2kETHEarnings,
  useMakeRealTxHash,
  useNetworkBasedLinkFactories,
  useTokens
} from '@/hooks'
import { readablePrice, roundNumber } from '@/utils/global'

import styles from '../Farming/CardFarmingManage/styles.module.scss'

export const CardClaim = () => {
  const navigate = useNavigate()
  const [failedToClaim, setFailedToClaim] = useState(false)
  const {
    result: kETHEarningsResult,
    txResults,
    onClaim,
    isClaiming,
    onClear
  } = useK2kETHEarnings()
  const { KETH } = useTokens()

  const { makeEtherscanLink } = useNetworkBasedLinkFactories()

  const handleClaim = async () => {
    const wasSucceed = await onClaim()
    if (!wasSucceed) setFailedToClaim(true)
  }

  const handleGoToHome = () => {
    navigate('/')
  }

  return (
    <div className="flex-1 md:flex-5 rounded-2xl bg-background300 border border-borderColor p-5">
      <div className="px-2 py-4 flex flex-col gap-1">
        <div className="relative">
          <TextInput
            label="Claim"
            type="text"
            className={styles.input}
            pattern="(\.\d{0,1}|\d*\.\d{0,2})"
            disabled={true}
            value={kETHEarningsResult?.kETHEarnings}
          />
          <div className="absolute top-8 right-2 flex gap-3 items-center">
            <div className="rounded-full p-1 pt-2 pr-2 bg-background400 flex items-center gap-2">
              <img src={KETH.icon} alt="token_icon" className="w-6" />
              <p className="text-textBase text-xl font-medium leading-5">{KETH.symbol}</p>
            </div>
          </div>
        </div>
        <div>
          <p className="text-textLabel text-sm text-right mb-2">
            Value: ${readablePrice(kETHEarningsResult?.kETHEarningsUSD || 0)}
          </p>
        </div>
        <div className="py-4 flex flex-col gap-4">
          <Button
            disabled={
              isClaiming ||
              !kETHEarningsResult?.kETHEarnings ||
              Number(kETHEarningsResult?.kETHEarnings) === 0
            }
            onClick={handleClaim}>
            {isClaiming ? 'Claiming' : 'Claim'}
          </Button>
        </div>
      </div>
      <LoadingModal open={isClaiming} title="Claiming Pending" onClose={() => {}} />
      <ModalDialog open={txResults.length > 0} onClose={onClear}>
        <CompletedTxView
          goToContent="Home"
          title="Success"
          txLinks={
            txResults &&
            txResults?.map((item: any) => {
              return {
                name: item.name,
                href: makeEtherscanLink(item.tx.hash)
              }
            })
          }
          onGoToClick={handleGoToHome}
          message={
            <span className="text-sm text-grey300">{`You’ve successfully claimed earnings.`}</span>
          }
        />
      </ModalDialog>
      <ErrorModal
        open={!!failedToClaim}
        onClose={() => {
          setFailedToClaim(false)
          handleGoToHome()
        }}
        title="Error!"
        message={'It looks like something went wrong.'}
        actionButtonContent="Try Again"
        onAction={() => {
          setFailedToClaim(false)
          handleGoToHome()
        }}
      />
    </div>
  )
}
