import { ethers } from 'ethers'
import { useCallback, useContext, useEffect, useState } from 'react'

import { ContractContext } from '@/context/ContractContext'
import { bigToNum, numToBig } from '@/utils/global'

import { useCustomAccount } from './useCustomAccount'

export type BorrowInterestT = {
  interest: ethers.BigNumber
  outstandingInterest: ethers.BigNumber
}

export const useK2Interest = () => {
  const { account } = useCustomAccount()
  const { k2PoolContract, kETHVaultContract, partitionedLinearInterestRateModelContract } =
    useContext(ContractContext)

  const [result, setResult] = useState<BorrowInterestT>()

  const fetchK2Interest = async (
    borrowAmount: string,
    maxLiveness: number,
    maxCorruption: number,
    durationInSec = 0
  ) => {
    if (
      k2PoolContract &&
      kETHVaultContract &&
      partitionedLinearInterestRateModelContract &&
      account
    ) {
      try {
        const debtPostion = await k2PoolContract.getDebtor(account.address)
        const isDebtPostionAvailable =
          debtPostion.debtor.toLowerCase() === account.address.toLowerCase()
        const outstandingInterest = isDebtPostionAvailable
          ? await k2PoolContract.getOutstandingInterest(account.address)
          : 0

        const principalAmount = isDebtPostionAvailable ? debtPostion.principalAmount : 0

        const maxLivenessBN = ethers.utils.parseEther(`${maxLiveness}`)
        const maxCorruptionBN = ethers.utils.parseEther(`${maxCorruption}`)
        const borrowAmountBN = ethers.utils.parseEther(`${borrowAmount}`)
        let duration
        if (durationInSec === 0) {
          duration = await k2PoolContract.borrowDuration()
        } else {
          duration = durationInSec
        }

        const interest: ethers.BigNumber =
          await partitionedLinearInterestRateModelContract.getExpectedInterest(
            borrowAmountBN,
            principalAmount,
            maxLivenessBN,
            maxCorruptionBN,
            duration
          )
        setResult({
          interest: interest.sub(outstandingInterest),
          outstandingInterest: outstandingInterest
        })
      } catch (err) {
        setResult({
          interest: ethers.BigNumber.from(0),
          outstandingInterest: ethers.BigNumber.from(0)
        })
        console.log('k2PoolContract reading err: ', err)
      }
    }
  }

  return { result, fetchK2Interest }
}
