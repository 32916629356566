import { BigNumber, ethers } from 'ethers'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useNetwork } from 'wagmi'

import { ContractContext } from '@/context/ContractContext'
import { UserContext } from '@/context/UserContext'
import { RAY } from '@/utils/contractFunctions'

import { bigToNum, numToBig } from '../utils/global'
import { useCustomAccount } from './useCustomAccount'

export type BorrowSummaryValueT = {
  availableToBorrow: number
  kethTotalAvailable: number
  currentBorrowRate: number
  totalETHBacking: number
  totalETHBackingUsd: number
}

export const useK2BorrowSummaryValues = () => {
  const { account } = useCustomAccount()
  const { k2PoolContract, kETHVaultContract, partitionedLinearInterestRateModelContract } =
    useContext(ContractContext)
  const { ethPrice } = useContext(UserContext)
  const { chain: activeChain } = useNetwork()

  const [result, setResult] = useState<BorrowSummaryValueT>()

  const fetchData = useCallback(async () => {
    if (
      k2PoolContract &&
      kETHVaultContract &&
      partitionedLinearInterestRateModelContract &&
      account &&
      ethPrice
    ) {
      try {
        const totalBorrowableAmount: BigNumber = await k2PoolContract.getTotalBorrowableAmount()
        const totalBorrowedAmount: BigNumber = await k2PoolContract.borrowedLiquidity()
        const totalAvailable = bigToNum(totalBorrowableAmount) - bigToNum(totalBorrowedAmount)
        const kETHValutTotalSupply: BigNumber = await kETHVaultContract.totalSupply()
        const kETHValutTotalAssets: BigNumber = await kETHVaultContract.totalAssets()
        const ethValue =
          (totalAvailable * bigToNum(kETHValutTotalAssets)) / bigToNum(kETHValutTotalSupply)
        const availableToBorrow = ethValue * ethPrice

        const currentBorrowRate =
          await partitionedLinearInterestRateModelContract.getUtilizationRatio(
            totalBorrowableAmount,
            totalBorrowedAmount,
            0
          )

        const currentBorrowRatePercentage = currentBorrowRate.mul(100).div(RAY)

        let assumedLiquidity = await k2PoolContract.assumedLiquidity()
        let totalSupply = await k2PoolContract.totalSupply()
        let totalRestakedBN = assumedLiquidity.sub(totalSupply)
        let totalETHBacking = ethers.utils
          .formatEther(
            totalRestakedBN
              .div(ethers.BigNumber.from('24000000000000000000'))
              .mul(ethers.BigNumber.from('32000000000000000000'))
          )
          .toString()

        const totalETHBackingUsd = Number(totalETHBacking) * ethPrice

        setResult({
          totalETHBacking: Number(totalETHBacking),
          totalETHBackingUsd,
          availableToBorrow,
          kethTotalAvailable: totalAvailable,
          currentBorrowRate: currentBorrowRatePercentage.toNumber()
        })
      } catch (err) {
        console.log(
          'k2PoolContract, kETHVault, partitionedLinearInterestRateModelContract reading err: ',
          err
        )
        setResult({
          totalETHBacking: 0,
          totalETHBackingUsd: 0,
          availableToBorrow: 0,
          kethTotalAvailable: 0,
          currentBorrowRate: 0
        })
      }
    }
  }, [
    k2PoolContract,
    kETHVaultContract,
    partitionedLinearInterestRateModelContract,
    account,
    ethPrice
  ])

  useEffect(() => {
    fetchData()
  }, [fetchData, activeChain?.id])

  return { result }
}
