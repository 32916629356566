import './index.scss'

import { ethers } from 'ethers'
import { set } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import { mainnet, useAccount, useNetwork } from 'wagmi'

import { ReactComponent as BorrowIcon } from '@/assets/images/icon-borrow.svg'
import { ReactComponent as PlusIcon } from '@/assets/images/icon-plus.svg'
import { ReactComponent as SupplyIcon } from '@/assets/images/icon-supply.svg'
import { BorrowInfo, HeaderSummary, NativeDelegationCard } from '@/components/app'
import { TransactionHistoryTable } from '@/components/app/Tables/transactionHistoryTable'
import {
  ComingSoon,
  CompletedTxView,
  ErrorModal,
  LoadingModal,
  ModalDialog,
  Overlay,
  Tooltip
} from '@/components/shared'
import { ConfirmModal } from '@/components/shared/Modal/ConfirmModal'
import {
  useConfig,
  useCustomAccount,
  useK2PositionSummaryValues,
  useK2ReposterRegister,
  useMakeRealTxHash,
  useNetworkBasedLinkFactories
} from '@/hooks'
import { chunkAddress } from '@/utils/global'

export const Home = () => {
  // states
  const navigate = useNavigate()

  const [openReporterRegisterModal, setOpenReporterRegisterModal] = useState(false)
  const [failedRegister, setFailedRegister] = useState(false)

  const {
    onRegister,
    isRegistering,
    txResult,
    onClear,
    isReporterRegisterAvailable,
    fetchData: fetchReporterRegisterData
  } = useK2ReposterRegister()
  const { hash } = useMakeRealTxHash(txResult?.hash)
  const { makeEtherscanLink } = useNetworkBasedLinkFactories()
  const { result: positionResult } = useK2PositionSummaryValues()

  // wagmi hooks
  const { isConnected } = useAccount()
  const { account } = useCustomAccount()
  const { chain: activeChain } = useNetwork()
  const config = useConfig()
  const isMainnet = activeChain?.id == mainnet.id && config.isPrivateMainnet == 'true'

  const handleReporterRegisterOpen = () => {
    setOpenReporterRegisterModal(true)
  }

  const handleReporterRegisterModalClose = () => {
    setOpenReporterRegisterModal(false)
  }

  const handleReporterRegisterConfirm = async () => {
    const wasSucceed = await onRegister()
    if (!wasSucceed) setFailedRegister(true)
    await fetchReporterRegisterData()
  }

  const handleGoToHome = () => {
    navigate('/')
  }

  const handleCloseSuccessModal = () => {
    onClear()
  }

  return (
    <div className="home">
      {/* <ComingSoon /> */}
      <div className="content">
        <h1 className="text-5xl font-semibold my-6 mt-16 text-textBase">Welcome to K2</h1>
        <NativeDelegationCard />
        <div className="flex flex-col relative">
          {isMainnet && <Overlay />}
          <BorrowInfo />
          {isConnected ? (
            <>
              <div className="flex justify-between items-center pb-4 self-start w-full">
                <div className="flex gap-2">
                  {/* <button
                  className="flex items-center bg-tabBackground py-2 px-4 rounded-lg"
                  onClick={() => {
                    navigate('/supply')
                  }}>
                  <SupplyIcon />
                  <span className="ml-2">Supply</span>
                </button>
                <button
                  className="flex items-center bg-tabBackground py-2 px-4 rounded-lg"
                  onClick={() => {
                    navigate('/borrow')
                  }}>
                  <BorrowIcon />
                  <span className="ml-2">Borrow</span>
                </button> */}
                  {isReporterRegisterAvailable && (
                    <button
                      className="flex items-center bg-tabBackground py-2 px-4 rounded-lg"
                      onClick={handleReporterRegisterOpen}>
                      <PlusIcon />
                      <span className="ml-2">Add Reporter</span>
                    </button>
                  )}
                </div>
              </div>

              <HeaderSummary
                kETHBorrowed={positionResult?.kETHBorrowed || 0}
                kETHDeposited={positionResult?.kETHDeposited || 0}
                kETHSlash={positionResult?.kETHSlash || 0}
                kETHSlashAmount={positionResult?.kETHSlashAmount || ethers.BigNumber.from('0')}
                userDebtPosition={positionResult?.userDebtPosition || false}
                userLenderPosition={positionResult?.userLenderPosition || false}
              />
            </>
          ) : (
            <div className="w-full rounded-md p-4 bg-grey950 text-center mb-10">
              Connect your wallet to see deposits and earning
            </div>
          )}
          <TransactionHistoryTable
            initialized={
              (positionResult?.userDebtPosition || positionResult?.userLenderPosition || false) &&
              !isMainnet
            }
          />
          {/* <BSChartContainer
          style={{ width: '100%', margin: 'auto', position: 'relative' }}
          isIndexChart={true}>
          <BalanceOverTimeChart />
        </BSChartContainer> */}
        </div>
      </div>
      <ConfirmModal
        title="Reporter"
        useCloseIcon={false}
        useBackArrow={true}
        iconNeeded={false}
        message={
          <div className="px-6 pb-2">
            <div className="text-textLabel pb-4">Signup as a Reporter to earn ETH.</div>
            <div className="text-left text-primaryGreen flex items-center">
              <span className="pr-1">Register your wallet</span>
              <Tooltip
                className="ml-2"
                message="Registering as a reporter will require you to run additional software on your computer. It is free to do with no slashing risk. You will earn ETH upon successful reports."
              />
            </div>

            {account && (
              <div className="text-white word-wrap-break-word text-left">
                {chunkAddress(account.address)}
              </div>
            )}
          </div>
        }
        confirmButtonContent="Confirm"
        onConfirm={handleReporterRegisterConfirm}
        onClose={handleReporterRegisterModalClose}
        open={openReporterRegisterModal}
      />
      <LoadingModal open={isRegistering} title="Registration Pending" onClose={() => {}} />
      <ModalDialog open={!!txResult} onClose={handleCloseSuccessModal}>
        <CompletedTxView
          goToContent="Home"
          title="Success"
          txLink={makeEtherscanLink(hash)}
          onGoToClick={() => {
            handleGoToHome()
            handleCloseSuccessModal()
            setOpenReporterRegisterModal(false)
          }}
          message={
            <span className="text-sm text-grey300">{`You've successfully registered your wallet as a Reporter.`}</span>
          }
        />
      </ModalDialog>
      <ErrorModal
        open={!!failedRegister}
        onClose={() => {
          setFailedRegister(false)
          setOpenReporterRegisterModal(false)
          handleGoToHome()
        }}
        title="Error!"
        message={'It looks like something went wrong.'}
        actionButtonContent="Try Again"
        onAction={() => {
          setFailedRegister(false)
          handleGoToHome()
        }}
      />
    </div>
  )
}
