import axios from 'axios'
import { BigNumber, Contract } from 'ethers'
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react'
import { useNetwork, useSigner } from 'wagmi'

import { getTotalAssets, getTotalSupply } from '@/utils/contractFunctions'
import {
  bigToNum,
  getBsnFarmingContract,
  getDEthVaultContract,
  getERCToken,
  getInterestRateModelContract,
  getK2LendingDepositorContract,
  getK2PoolContract,
  getKETHStrategyContract,
  getKETHVaultContract,
  getPartitionedLinearInterestRateModelContract,
  getReporterRegisterContract,
  getRETHTokenContract,
  getRSTContract,
  getWstETHTokenContract
} from '@/utils/global'

import { useConfig } from '../hooks'

const queryStakingAprs = async (): Promise<{
  stETH: number
  rETH: number
  dETH: number
}> => {
  try {
    const rETHResult = (await axios.get('https://rocketpool.net/api/mainnet/payload')).data
    const beaconChainApr = Number(rETHResult.beaconChainAPR) * 100
    const rETH = Number(rETHResult.rethAPR) * 0.9
    // const dETH = (beaconChainApr * 32) / 24
    const dETH = beaconChainApr

    const stETHResult = (await axios.get('https://eth-api.lido.fi/v1/protocol/steth/apr/last')).data
    const stETH = Number(stETHResult.data.apr) * 0.9
    return {
      stETH,
      rETH,
      dETH
    }
  } catch (e) {
    console.log(e)
  }

  return {
    stETH: 0,
    rETH: 0,
    dETH: 0
  }
}

interface Props {
  children: ReactNode
}

export const ContractContext = createContext<{
  kETHStrategyContract: Contract | undefined
  dETHVaultContract: Contract | undefined
  bsnFarmingContract: Contract | undefined
  kETHVaultContract: Contract | undefined
  rETHTokenContract: Contract | undefined
  savETHTokenContract: Contract | undefined
  wstETHContract: Contract | undefined
  stETHContract: Contract | undefined
  k2PoolContract: Contract | undefined
  k2LendingDepositorContract: Contract | undefined
  dETHTokenContract: Contract | undefined
  reporterRegisterContract: Contract | undefined
  interestRateModelContract: Contract | undefined
  partitionedLinearInterestRateModelContract: Contract | undefined
  RSTContract: Contract | undefined
  tvlValues: any
  kETHApr: number
  kETHTargetApr: number
  refetchTvls: () => void
}>({
  kETHStrategyContract: undefined,
  dETHTokenContract: undefined,
  dETHVaultContract: undefined,
  bsnFarmingContract: undefined,
  kETHVaultContract: undefined,
  rETHTokenContract: undefined,
  RSTContract: undefined,
  savETHTokenContract: undefined,
  wstETHContract: undefined,
  stETHContract: undefined,
  k2PoolContract: undefined,
  k2LendingDepositorContract: undefined,
  reporterRegisterContract: undefined,
  interestRateModelContract: undefined,
  partitionedLinearInterestRateModelContract: undefined,
  tvlValues: { 0: 0, 1: 0, 2: 0 },
  kETHApr: 0,
  kETHTargetApr: 0,
  refetchTvls: () => {}
})

export const ContractProvider = ({ children }: Props) => {
  const { chain: activeChain } = useNetwork()
  const { data: signer } = useSigner()
  const config = useConfig()
  const [kETHStrategyContract, setKETHStrategyContract] = useState<Contract>()
  const [dETHVaultContract, setDETHVaultContract] = useState<Contract>()
  const [bsnFarmingContract, setBsnFarmingContract] = useState<Contract>()
  const [kETHVaultContract, setKETHVaultContract] = useState<Contract>()
  const [rETHTokenContract, setRETHTokenContract] = useState<Contract>()
  const [savETHTokenContract, setSavETHTokenContract] = useState<Contract>()
  const [wstETHContract, setWstETHContract] = useState<Contract>()
  const [stETHContract, setStETHContract] = useState<Contract>()
  const [dETHTokenContract, setDETHTokenContract] = useState<Contract>()
  const [k2PoolContract, setK2PoolContract] = useState<Contract>()
  const [k2LendingDepositorContract, setK2LendingDepositorContract] = useState<Contract>()
  const [reporterRegisterContract, setReporterRegisterContract] = useState<Contract>()
  const [interestRateModelContract, setInterestRateModelContract] = useState<Contract>()
  const [RSTContract, setRSTContract] = useState<Contract>()
  const [
    partitionedLinearInterestRateModelContract,
    setPartitionedLinearInterestRateModelContract
  ] = useState<Contract>()
  const [tvlValues, setTvlValues] = useState<any>({ 0: 0, 1: 0, 2: 0 })
  const [kETHApr, setKETApr] = useState<any>(0)
  const [kETHTargetApr, setKETHTargetApr] = useState<any>(0)

  useEffect(() => {
    async function loadContracts() {
      if (signer) {
        const contract = getKETHStrategyContract(signer, config.kETHStrategyContractAddress!)
        setKETHStrategyContract(contract)

        const dEthVaultContract = getDEthVaultContract(signer, config.dETHVaultContractAddress!)
        setDETHVaultContract(dEthVaultContract)

        const bsnFarmingContract = getBsnFarmingContract(signer, config.bsnFarmingContractAddress!)
        setBsnFarmingContract(bsnFarmingContract)

        const kETHVaultContract = getKETHVaultContract(signer, config.kETHVaultContractAddress!)
        setKETHVaultContract(kETHVaultContract)

        const savETHContract = getERCToken(signer, config.savETHTokenAddress!)
        setSavETHTokenContract(savETHContract)

        const wstETHContract = getWstETHTokenContract(signer, config.wstETHTokenAddress!)
        setWstETHContract(wstETHContract)

        const stETHContract = getERCToken(signer, config.stETHTokenAddress!)
        setStETHContract(stETHContract)

        const dETHTokenContract = getERCToken(signer, config.dETHTokenAddress!)
        setDETHTokenContract(dETHTokenContract)

        const rETHContract = getRETHTokenContract(signer, config.rETHTokenAddress!)
        setRETHTokenContract(rETHContract)

        const k2PoolContract = getK2PoolContract(signer, config.k2PoolContractAddress!)
        setK2PoolContract(k2PoolContract)

        const k2LendingDepositorContract = getK2LendingDepositorContract(
          signer,
          config.k2LendingDepositorAddress!
        )
        setK2LendingDepositorContract(k2LendingDepositorContract)

        const reporterRegisterContract = getReporterRegisterContract(
          signer,
          config.reporterRegistryAddress!
        )
        setReporterRegisterContract(reporterRegisterContract)

        const interestRateModelContract = getInterestRateModelContract(
          signer,
          config.interestRateModelAddress!
        )
        setInterestRateModelContract(interestRateModelContract)

        const partitionedLinearInterestRateModelContract =
          getPartitionedLinearInterestRateModelContract(
            signer,
            config.partitionedLinearInterestRateModelAddress!
          )
        setPartitionedLinearInterestRateModelContract(partitionedLinearInterestRateModelContract)

        const RSTContract = getRSTContract(signer, config.rstModuleContractAddress!)
        setRSTContract(RSTContract)
      }
    }

    loadContracts()
  }, [activeChain?.id, signer])

  const refetchTvls = useCallback(async () => {
    if (kETHVaultContract && kETHStrategyContract && dETHVaultContract && bsnFarmingContract) {
      const kETHTvl = await getTotalAssets(kETHStrategyContract)
      const kETHTotalSupply = bigToNum(await kETHVaultContract.totalSupply())
      const dETHTvl = await getTotalAssets(dETHVaultContract)
      const bsnTotalSupply = await getTotalSupply(bsnFarmingContract)
      const bsnTvl = (bsnTotalSupply * kETHTvl) / kETHTotalSupply // need to convert kETH to ETH value
      setTvlValues((tvl: any) => ({ ...tvl, 0: kETHTvl, 1: dETHTvl, 2: bsnTvl }))

      const kETHAssets = await kETHStrategyContract.assetsRatio()
      const stETHValue = bigToNum(
        kETHAssets.find(
          (item: { token: string }) =>
            item.token.toLowerCase() === config.wstETHTokenAddress?.toLowerCase()
        )?.valueInETH || BigNumber.from('0')
      )
      const rETHValue = bigToNum(
        kETHAssets.find(
          (item: { token: string }) =>
            item.token.toLowerCase() === config.rETHTokenAddress?.toLowerCase()
        )?.valueInETH || BigNumber.from('0')
      )
      const dETHValue = bigToNum(
        (
          kETHAssets.find(
            (item: { token: string }) =>
              item.token.toLowerCase() === config.dETHTokenAddress?.toLowerCase()
          )?.valueInETH || BigNumber.from('0')
        ).add(
          kETHAssets.find(
            (item: { token: string }) =>
              item.token.toLowerCase() === config.savETHTokenAddress?.toLowerCase()
          )?.valueInETH || BigNumber.from('0')
        )
      )
      const ethValue = kETHTvl - stETHValue - rETHValue - dETHValue
      const stakingAprs = await queryStakingAprs()
      const kETHApr =
        (stETHValue * stakingAprs.stETH +
          rETHValue * stakingAprs.rETH +
          dETHValue * stakingAprs.dETH +
          ethValue) /
        kETHTvl
      setKETApr(kETHApr)
      setKETHTargetApr(Number(await kETHStrategyContract.targetRate()) / 100)
    }
  }, [kETHVaultContract, kETHStrategyContract, dETHVaultContract, bsnFarmingContract])

  useEffect(() => {
    refetchTvls()
  }, [refetchTvls, activeChain?.id])

  return (
    <ContractContext.Provider
      value={{
        RSTContract,
        dETHTokenContract,
        stETHContract,
        kETHStrategyContract,
        dETHVaultContract,
        bsnFarmingContract,
        kETHVaultContract,
        rETHTokenContract,
        savETHTokenContract,
        wstETHContract,
        k2PoolContract,
        k2LendingDepositorContract,
        reporterRegisterContract,
        interestRateModelContract,
        partitionedLinearInterestRateModelContract,
        tvlValues,
        kETHApr,
        kETHTargetApr,
        refetchTvls
      }}>
      {children}
    </ContractContext.Provider>
  )
}
