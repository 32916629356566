import { Dialog } from '@headlessui/react'
import { FC, useContext } from 'react'

import { ReactComponent as CloseCircleIcon } from '@/assets/images/close-circle.svg'
import { ReactComponent as CloseCircleDarkIcon } from '@/assets/images/close-circle-dark.svg'
import { Button, Modal } from '@/components/shared'
import { ThemeContext } from '@/context/ThemeContext'
import { UserContext } from '@/context/UserContext'

import styles from './styles.module.scss'

interface IProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const ModalNativeDelegation: FC<IProps> = ({ open, onClose, onConfirm }) => {
  const { theme } = useContext(ThemeContext)
  const { account } = useContext(UserContext)

  return (
    <Modal open={open} onClose={onClose}>
      <Dialog.Panel className={styles.modalLayoutBig}>
        <div className="absolute top-3 right-3 cursor-pointer" onClick={onClose}>
          {theme === 'dark' ? <CloseCircleIcon /> : <CloseCircleDarkIcon />}
        </div>
        <div className="text-2xl text-white font-semibold">
          Welcome {`${account?.address.slice(0, 6)}...${account?.address.slice(-4)}`}!
        </div>
        <div className="text-sm text-white mt-2 mb-8">
          Restake your existing validator without changing withdrawal credentials or business logic.
          It only takes 10 minutes.
        </div>
        <div>
          <Button size="lg" onClick={onConfirm}>
            Restake Now
          </Button>
        </div>
      </Dialog.Panel>
    </Modal>
  )
}

export default ModalNativeDelegation
