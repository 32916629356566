import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { ClaimQuery } from '@/graphql/queries/ClaimQuery'
import { bigToNum } from '@/utils/global'

export const useK2Claim = () => {
  const [totalClaimed, setTotalClaimed] = useState(0)

  const { loading, data, refetch } = useQuery(ClaimQuery, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    if (!data || data.claims.length === 0) return
    setTotalClaimed(bigToNum(data.claims[0].amount))
  }, [data])

  return { loading, totalClaimed, refetch }
}
