import { useCallback, useContext, useEffect, useState } from 'react'

import { ContractContext } from '@/context/ContractContext'

import { TokenT, useTokens } from '.'

export const useEstimatedK2Recieved = (depositAmount: number, token?: TokenT) => {
  const { kETHVaultContract, kETHStrategyContract } = useContext(ContractContext)
  const { KETH } = useTokens()

  const [estimatedK2Recieved, setEstimatedK2Recieved] = useState<number>(0)

  const fetchEstmiatedETHReceived = useCallback(async () => {
    if (!kETHVaultContract || !depositAmount || !kETHStrategyContract) return

    try {
      if (token?.address === KETH.address) {
        setEstimatedK2Recieved(depositAmount)
      } else {
        const ethValue = kETHStrategyContract.assetValue(token, depositAmount)
        const kETHVaultTotalSupply = await kETHVaultContract.totalSupply()
        const kETHVaultTotalAssets = await kETHVaultContract.totalAssets()
        const estimatedK2Recieved = (ethValue * kETHVaultTotalSupply) / kETHVaultTotalAssets
        setEstimatedK2Recieved(estimatedK2Recieved)
      }
    } catch (err) {
      console.log('fetchEstmiatedK2Received error: ', err)
    }
  }, [kETHVaultContract, kETHStrategyContract, depositAmount, token])

  useEffect(() => {
    fetchEstmiatedETHReceived()
  }, [fetchEstmiatedETHReceived])

  return { estimatedK2Recieved }
}
