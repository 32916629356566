import React, { FC, useState } from 'react'

import { ReactComponent as MinusIcon } from '@/assets/images/icon-minus.svg'
import { ReactComponent as PlusIcon } from '@/assets/images/icon-plus.svg'
import { TextInput, Tooltip } from '@/components/shared'

import { cutDecimals } from '../../../utils/global'
import styles from './styles.module.scss'

interface NumberAdjusterProps {
  title: string
  value: number
  incrementValue: number
  label: string
  className?: string
  disabled?: boolean
  tooltip?: string
  setValue: (value: any) => void
  adjustable?: boolean
  alignLeft?: boolean
  placement?: 'top' | 'right' | 'bottom' | 'left'
}

const NumberAdjusterWithTextInput: FC<NumberAdjusterProps> = ({
  title,
  value,
  incrementValue,
  label,
  className,
  disabled,
  tooltip,
  setValue,
  adjustable = true,
  placement = 'top',
  alignLeft = false
}) => {
  const handleIncrement = () => {
    setValue((Number(value) + incrementValue).toFixed(4))
  }

  const handleDecrement = () => {
    if (Number(value) - incrementValue >= 0) setValue((Number(value) - incrementValue).toFixed(4))
  }

  return (
    <div className={`${styles.wrapper} ${className || ''}`}>
      <div className={`${styles.titleContainer}`}>
        <div className={styles.title}>{title}</div>
        {tooltip && <Tooltip alignLeft={alignLeft} placement={placement} message={tooltip} />}
      </div>
      <div className={styles.controls}>
        {disabled ? (
          <div className={styles.disabledOverlay}>--</div>
        ) : (
          <>
            <button onClick={handleDecrement} className={styles.button} disabled={!adjustable}>
              <MinusIcon />
            </button>
            <TextInput
              type="text"
              className={styles.value}
              value={value}
              pattern="(\.\d{0,1}|\d*\.\d{0,2})"
              disabled={false}
              onChange={(e) => {
                if (Number(e.target.value) >= 0) setValue(e.target.value)
              }}
            />
            {/* <div className={styles.value}>{cutDecimals(value, 4)}</div> */}
            <button onClick={handleIncrement} className={styles.button} disabled={!adjustable}>
              <PlusIcon />
            </button>
          </>
        )}
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  )
}

export default NumberAdjusterWithTextInput
