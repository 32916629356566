import tw, { styled } from 'twin.macro'

import { ReactComponent as ComingSoonIcon } from '@/assets/images/coming-soon.svg'

import Button from './Buttons'

export const ComingSoon = () => {
  return (
    <Wrapper>
      <div>
        <ComingSoonIcon />
      </div>
      <div className="p-6 flex flex-col gap-4">
        {/* <div className="flex">
          <Tag>Coming Soon</Tag>
        </div> */}
        <div className="flex flex-col gap-2">
          <div className="text-xl font-bold">Mainnet Coming Soon</div>
          {/* <div>
            Boost your earnings without altering withdrawal credentials or validator operations.
            Experience the future of staking with simplicity and efficiency.
          </div> */}
        </div>
        <div>
          <Button onClick={() => window.open('https://restaking.cloud/', '_blank')}>Website</Button>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${tw`bg-grey850 rounded-2xl w-full max-w-[827px] flex justify-center items-center px-9 mx-auto text-white mt-10`}
`

const Tag = tw.div`px-2.5 py-1 rounded-[10px] text-primary bg-[#C3F53C] bg-opacity-10 text-sm`

export const Overlay = styled.div`
  ${tw`w-full h-full absolute z-10 cursor-not-allowed`}
  background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, #121212 123.01%);
`
