import { BigNumber, ethers } from 'ethers'
import { useCallback, useContext, useEffect, useState } from 'react'

import { ContractContext } from '@/context/ContractContext'
import { UserContext } from '@/context/UserContext'
import { bigToNum, formatDuration } from '@/utils/global'

import { useCustomAccount } from './useCustomAccount'

export type BorrowSummaryValueT = {
  isDebtPosition: boolean
  totalBorrowed: number
  unpaidSlashDebt: BigNumber
  kethValue: BigNumber
  duration: string
  unpaidSlashDebtUsd: number
}

export const useK2RestakingInfo = () => {
  const { account } = useCustomAccount()
  const { k2PoolContract, kETHVaultContract } = useContext(ContractContext)
  const { ethPrice, isRefetch } = useContext(UserContext)

  const [result, setResult] = useState<BorrowSummaryValueT>()

  const fetchData = async () => {
    if (k2PoolContract && kETHVaultContract && account) {
      try {
        const debtorPosition = await k2PoolContract.getDebtor(account.address)
        const isDebtPosition = debtorPosition.debtor !== ethers.constants.AddressZero
        const kethValue: BigNumber = debtorPosition.principalAmount
        const kETHValutTotalSupply: BigNumber = await kETHVaultContract.totalSupply()
        const kETHValutTotalAssets: BigNumber = await kETHVaultContract.totalAssets()
        const ethValue = bigToNum(kethValue.mul(kETHValutTotalAssets).div(kETHValutTotalSupply))
        const totalBorrowed = ethValue * ethPrice

        const unpaidSlashDebt = debtorPosition.slashAmount

        const kETHSlashEthValue = bigToNum(
          unpaidSlashDebt.mul(kETHValutTotalAssets).div(kETHValutTotalSupply)
        )

        const currentUnixTimestamp: number = Math.floor(Date.now() / 1000)
        const duration = formatDuration(debtorPosition.endTimestamp, currentUnixTimestamp)

        setResult({
          isDebtPosition,
          totalBorrowed,
          unpaidSlashDebt,
          unpaidSlashDebtUsd: kETHSlashEthValue * ethPrice,
          kethValue,
          duration
        })
      } catch (err) {
        console.log('k2PoolContract, kETHVault reading err: ', err)
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [account, k2PoolContract, kETHVaultContract, ethPrice, isRefetch])

  return { result, fetchData }
}
