import BgGradient6 from '@/assets/images/bg-gradient-6.png'
import GreenPurpleETHIcon from '@/assets/images/icon-green-purple-eth.svg'
import { Tooltip } from '@/components/shared'
import { useK2Claim, useK2kETHEarnings } from '@/hooks'
import { formatNumber } from '@/utils/global'

export const CardClaimInfo = () => {
  const { result: kETHEarningsResult } = useK2kETHEarnings()
  const { totalClaimed } = useK2Claim()

  return (
    <div className="flex-1 md:flex-6 items-stretch rounded-2xl bg-background100 border border-borderColor overflow-hidden">
      <div className="w-full h-28 overflow-hidden">
        <img src={BgGradient6} alt="card-bg" className="w-full h-full object-fill" />
      </div>
      <div className="w-full p-4 flex flex-col gap-4">
        <div className="w-full -mt-12 flex flex-col">
          <div className="relative h-18 mb-2.5">
            <div className="absolute top-0">{<img src={GreenPurpleETHIcon} alt="icon" />}</div>
          </div>
          <p className="text-2xl text-textBase font-semibold mb-1">Claim kETH</p>
          <p className="text-textLabel">Claim your kETH earned through kETH or K2</p>
        </div>
        <div className="w-full bg-grey600" style={{ height: 1 }}></div>
        <div className="w-full pt-2 flex gap-4">
          <div className="flex-1 h-22 rounded-2xl bg-background200 py-2 px-4">
            <div className="flex gap-2 items-center text-textLabel text-sm mb-3">
              kETH Rewards to Claim <Tooltip message="kETH earned from staking your kETH." />
            </div>
            <p className="text-3xl text-primary font-bold">
              {formatNumber(Number(kETHEarningsResult?.kETHEarnings || 0))}
            </p>
          </div>
          <div className="flex-1 h-22 rounded-2xl bg-background200 py-2 px-4">
            <div className="flex gap-2 items-center text-textLabel text-sm mb-3">
              Total kETH Rewards Claimed
              <Tooltip message="Total kETH Rewards Claimed " />
            </div>
            <p className="text-3xl text-primary font-bold">{totalClaimed}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
