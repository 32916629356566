import { BigNumber, ethers } from 'ethers'
import { useContext, useState } from 'react'

import { ContractContext } from '@/context/ContractContext'
import { handleErr, notifyHash, noty } from '@/utils/global'

import { TokenT, useConfig, useTokens } from '.'
import { useCustomAccount } from './useCustomAccount'

export type ValidationError = {
  error: string
}

export type PayAmountResultT = {
  stETHpayAmount: ethers.BigNumber
  rETHpayAmount: ethers.BigNumber
  dETHpayAmount: ethers.BigNumber
  ETHpayAmount: ethers.BigNumber
}

export const useK2Borrow = () => {
  const { k2PoolContract, partitionedLinearInterestRateModelContract, k2LendingDepositorContract } =
    useContext(ContractContext)
  const { DETH, ETH, RETH, STETH } = useTokens()
  const { account, isGnosis } = useCustomAccount()
  const [validationError, setValidationError] = useState<ValidationError>()
  const config = useConfig()

  const [txResult, setTxResult] = useState<any>()
  const [isBorrowing, setIsBorrowing] = useState(false)
  const [payAmountResult, setPayAmountResult] = useState<PayAmountResultT>()

  const fetchPayAmountData = async (interest: ethers.BigNumber) => {
    if (k2LendingDepositorContract && account) {
      try {
        const stETHpayAmount = await k2LendingDepositorContract.getPayAmountForKETH(
          interest,
          STETH.address
        )
        const rETHpayAmount = await k2LendingDepositorContract.getPayAmountForKETH(
          interest,
          RETH.address
        )
        const dETHpayAmount = await k2LendingDepositorContract.getPayAmountForKETH(
          interest,
          DETH.address
        )
        const ETHpayAmount = await k2LendingDepositorContract.getPayAmountForKETH(
          interest,
          ETH.address
        )

        // console.log(
        //   interest.toString(),
        //   dETHpayAmount.toString(),
        //   dETHpayAmount.mul(BigNumber.from('105')).div(BigNumber.from('100')).toString()
        // )

        setPayAmountResult({
          stETHpayAmount: stETHpayAmount.mul(BigNumber.from('105')).div(BigNumber.from('100')),
          rETHpayAmount: rETHpayAmount.mul(BigNumber.from('105')).div(BigNumber.from('100')),
          dETHpayAmount: dETHpayAmount.mul(BigNumber.from('105')).div(BigNumber.from('100')),
          ETHpayAmount: ETHpayAmount.mul(BigNumber.from('105')).div(BigNumber.from('100'))
        })
      } catch (err) {
        setPayAmountResult({
          stETHpayAmount: ethers.BigNumber.from(0),
          rETHpayAmount: ethers.BigNumber.from(0),
          dETHpayAmount: ethers.BigNumber.from(0),
          ETHpayAmount: ethers.BigNumber.from(0)
        })
        console.log('k2LendingDepositorContract payamount reading err: ', err)
      }
    }
  }

  const onBorrowWithKETH = async (
    borrowAmount: number,
    designatedVerifier: string,
    maxLiveness: number,
    maxCorruption: number,
    availableToBorrow: number,
    isUpdatePosition: boolean,
    resetDuration: boolean,
    RSTConfig: any
  ) => {
    if (!k2PoolContract || !partitionedLinearInterestRateModelContract || !account) return

    if (availableToBorrow < borrowAmount) {
      setValidationError({
        error: `Borrow amount should be smaller than ${availableToBorrow}`
      })
      return
    }

    const borrowAmountBN = ethers.utils.parseEther(`${borrowAmount}`)

    const maxLivenessBN = ethers.utils.parseEther(`${maxLiveness}`)
    const totalBorrowableAmount: BigNumber = await k2PoolContract.getTotalBorrowableAmount()
    const totalBorrowedAmount: BigNumber = await k2PoolContract.borrowedLiquidity()
    const maxLivenessUpperLimit =
      await partitionedLinearInterestRateModelContract.maxSlashableAmountPerLivenessUpper(
        totalBorrowableAmount,
        totalBorrowedAmount,
        borrowAmountBN
      )

    if (maxLivenessUpperLimit.lt(maxLivenessBN)) {
      setValidationError({
        error: `Max Liveness should be smaller than ${maxLivenessUpperLimit.toNumber()}`
      })
      return
    }

    const maxCorruptionBN = ethers.utils.parseEther(`${maxCorruption}`)
    const maxCorruptionUpperLimit =
      await partitionedLinearInterestRateModelContract.maxSlashableAmountPerCorruptionUpper(
        totalBorrowableAmount,
        totalBorrowedAmount,
        borrowAmountBN
      )
    if (maxCorruptionUpperLimit.lt(maxCorruptionBN)) {
      setValidationError({
        error: `Max Corruption should be smaller than ${maxCorruptionUpperLimit.toNumber()}`
      })
      return
    }

    setIsBorrowing(true)

    let tx
    try {
      if (!isUpdatePosition) {
        tx = await k2PoolContract.borrow(
          0,
          designatedVerifier,
          borrowAmountBN,
          maxLivenessBN,
          maxCorruptionBN,
          RSTConfig
        )
      } else {
        tx = await k2PoolContract.increaseDebt(
          0,
          designatedVerifier,
          borrowAmountBN,
          maxLivenessBN,
          maxCorruptionBN,
          resetDuration
        )
      }
    } catch (err) {
      console.log('k2PoolContract depositToken error: ', err)
      setIsBorrowing(false)
      noty(handleErr(err, 'Something went wrong. Depositing token failed.'))
      return false
    }

    if (tx) {
      if (!isGnosis) notifyHash(tx.hash, config.networkId)
      await tx.wait()
      setTxResult(tx)
    }

    setIsBorrowing(false)

    return true
  }

  const onBorrowWithLSDToken = async (
    borrowAmount: number,
    designatedVerifier: string,
    maxLiveness: number,
    maxCorruption: number,
    availableToBorrow: number,
    isUpdatePosition: boolean,
    resetDuration: boolean,
    lsdToken: TokenT,
    payAmount: ethers.BigNumber,
    RSTConfig: any
  ) => {
    if (
      !k2LendingDepositorContract ||
      !partitionedLinearInterestRateModelContract ||
      !k2PoolContract ||
      !account
    )
      return

    // if (availableToBorrow < borrowAmount) {
    //   setValidationError({
    //     error: `Borrow amount should be smaller than ${availableToBorrow}`
    //   })
    //   return
    // }

    const borrowAmountBN = ethers.utils.parseEther(`${borrowAmount}`)

    const maxLivenessBN = ethers.utils.parseEther(`${maxLiveness}`)
    const totalBorrowableAmount: BigNumber = await k2PoolContract.getTotalBorrowableAmount()
    const totalBorrowedAmount: BigNumber = await k2PoolContract.borrowedLiquidity()

    const maxLivenessUpperLimit =
      await partitionedLinearInterestRateModelContract.maxSlashableAmountPerLivenessUpper(
        totalBorrowableAmount,
        totalBorrowedAmount,
        borrowAmountBN
      )
    if (maxLivenessUpperLimit.lt(maxLivenessBN)) {
      setValidationError({
        error: `Max Liveness should be smaller than ${maxLivenessUpperLimit.toNumber()}`
      })
      return
    }

    const maxCorruptionBN = ethers.utils.parseEther(`${maxCorruption}`)
    const maxCorruptionUpperLimit =
      await partitionedLinearInterestRateModelContract.maxSlashableAmountPerCorruptionUpper(
        totalBorrowableAmount,
        totalBorrowedAmount,
        borrowAmountBN
      )
    if (maxCorruptionUpperLimit.lt(maxCorruptionBN)) {
      setValidationError({
        error: `Max Corruption should be smaller than ${maxCorruptionUpperLimit.toNumber()}`
      })
      return
    }

    setIsBorrowing(true)
    let tx
    try {
      if (!isUpdatePosition) {
        if (lsdToken.id == ETH.id)
          tx = await k2LendingDepositorContract.borrow(
            0,
            designatedVerifier,
            borrowAmountBN,
            maxLivenessBN,
            maxCorruptionBN,
            lsdToken.address,
            payAmount,
            RSTConfig,
            { value: payAmount }
          )
        else
          tx = await k2LendingDepositorContract.borrow(
            0,
            designatedVerifier,
            borrowAmountBN,
            maxLivenessBN,
            maxCorruptionBN,
            lsdToken.address,
            payAmount,
            RSTConfig
          )
      } else {
        if (lsdToken.id == ETH.id)
          tx = await k2LendingDepositorContract.increaseDebt(
            0,
            designatedVerifier,
            borrowAmountBN,
            maxLivenessBN,
            maxCorruptionBN,
            resetDuration,
            lsdToken.address,
            payAmount,
            { value: payAmount }
          )
        else
          tx = await k2LendingDepositorContract.increaseDebt(
            0,
            designatedVerifier,
            borrowAmountBN,
            maxLivenessBN,
            maxCorruptionBN,
            resetDuration,
            lsdToken.address,
            payAmount
          )
      }
    } catch (err) {
      console.log('k2LendingDepositorContract depositToken error: ', err)
      noty(handleErr(err, 'Something went wrong. Depositing token failed.'))

      setIsBorrowing(false)
      return false
    }

    if (tx) {
      if (!isGnosis) notifyHash(tx.hash, config.networkId)
      await tx.wait()
      setTxResult(tx)
    }

    setIsBorrowing(false)

    return true
  }

  const onClear = () => {
    setTxResult(undefined)
  }

  return {
    onBorrowWithKETH,
    onBorrowWithLSDToken,
    onClear,
    isBorrowing,
    txResult,
    validationError,
    payAmountResult,
    fetchPayAmountData
  }
}
