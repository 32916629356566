import { holesky } from './holesky'

export async function addHoleskyNetwork() {
  try {
    const result = await window?.ethereum?.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x4268',
          rpcUrls: ['https://ethereum-holesky.publicnode.com'],
          chainName: 'Holesky',
          nativeCurrency: { name: 'Holesky Ether', symbol: 'ETH', decimals: 18 },
          blockExplorerUrls: ['https://holesky.etherscan.io']
        }
      ]
    })
  } catch (error) {
    console.log(error)
  }
}
