import './index.scss'

import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import { mainnet, useAccount, useNetwork } from 'wagmi'

import { ReactComponent as ArrowLeftDarkIcon } from '@/assets/images/icon-arrow-left-dark.svg'
import { ReactComponent as TopRightIcon } from '@/assets/images/icon-arrow-top-right.svg'
import { ReactComponent as ArrowLeftIcon } from '@/assets/images/icon-back.svg'
import { BorrowHeader } from '@/components/app/Borrow/BorrowHeader'
import { BorrowInfoSection } from '@/components/app/Borrow/BorrowInfoSection'
import { BorrowSection } from '@/components/app/Borrow/BorrowSection'
import { ComingSoon, Overlay } from '@/components/shared'
import { ContractContext } from '@/context/ContractContext'
import { ThemeContext } from '@/context/ThemeContext'
import { useConfig } from '@/hooks'

export const Borrow = () => {
  // states
  const params = useParams()
  const navigate = useNavigate()
  const { tvlValues, kETHApr, kETHTargetApr, refetchTvls } = useContext(ContractContext)
  const { theme } = useContext(ThemeContext)

  // wagmi hooks
  const { isConnected } = useAccount()
  const { chain: activeChain } = useNetwork()
  const config = useConfig()
  const isMainnet = activeChain?.id == mainnet.id && config.isPrivateMainnet == 'true'

  const handleGoBack = () => navigate('/home')

  useEffect(() => {
    refetchTvls()
  }, [refetchTvls])

  const handleOpenActivity = () => {
    navigate('/activity')
  }

  return (
    <div className="home">
      {/* <ComingSoon /> */}
      <div className="content">
        <div className="flex items-center gap-4 mt-10 mb-22">
          <a onClick={handleGoBack} className="cursor-pointer">
            {theme === 'dark' ? <ArrowLeftIcon /> : <ArrowLeftDarkIcon />}
          </a>
          <h2 className="text-4xl text-textBase font-semibold">Borrow</h2>
        </div>
        <div className="relative flex flex-col items-center w-full">
          {isMainnet && <StyledOverlay />}
          {/* <p className="text-lg mb-18  text-textBase">
          Borrow kETH for economic restaking collateral.
        </p> */}
          {isConnected ? (
            <>
              <BorrowHeader />
              <div className="flex flex-row gap-4 w-full">
                <div className="flex-1 md:flex-5 rounded-2xl bg-background300">
                  <BorrowSection />
                </div>
                <div className="flex-1 md:flex-5">
                  <BorrowInfoSection />
                </div>
              </div>
            </>
          ) : (
            <div className="w-full rounded-md p-4 bg-grey950 text-center mb-10">
              Connect your wallet to see deposits and earning
            </div>
          )}

          <div className="flex items-center gap-1 text-base mt-10 bg-black border border-grey500 rounded-lg px-4 py-2">
            <a onClick={handleOpenActivity} className="flex gap-2 items-center activity-link">
              View Activity <TopRightIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
const StyledOverlay = styled(Overlay)`
  z-index: 20;
`
