import { ethers } from 'ethers'
import { useContext, useEffect, useState } from 'react'

import { ContractContext } from '@/context/ContractContext'

import { useCustomAccount } from './useCustomAccount'
import { useNetworkBasedLinkFactories } from './useNetworkBasedLinkFactories'
import { useSDK } from './useSDK'

export const useIfRSTDebtor = () => {
  const [isRSTDebtor, setIsRSTDebtor] = useState<boolean>(false)
  const [ticker, setTicker] = useState<string>()
  const [tokenUrl, setTokenUrl] = useState<string>()
  const { k2PoolContract, RSTContract } = useContext(ContractContext)
  const { account, isRefetch } = useCustomAccount()
  const { k2SDK: sdk } = useSDK()
  const { makeEtherscanLink } = useNetworkBasedLinkFactories()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const debtor = await k2PoolContract?.debtors(account.address)
        const address = await RSTContract?.sbpToRST(debtor)

        if (address != ethers.constants.AddressZero) {
          const rstToken = await sdk?.contracts.rst(address)
          const ticker = await rstToken.symbol()

          const isRSTActive = await rstToken.isActive()

          setIsRSTDebtor(isRSTActive)
          setTicker(ticker)
          setTokenUrl(makeEtherscanLink(address, false, true))
        }
      } catch (error) {
        console.log('useIfRSTDebtor', error)
      }
    }

    if (k2PoolContract && RSTContract && sdk) fetchData()
  }, [RSTContract, k2PoolContract, isRefetch, sdk])

  return { isRSTDebtor, ticker, tokenUrl }
}
