import { goerli, mainnet } from 'wagmi'

import { holesky } from '../utils/holesky'

export const supportedChains =
  process.env.REACT_APP_NETWORK_ID === `${goerli.id}`
    ? [goerli]
    : process.env.REACT_APP_NETWORK_ID === `${mainnet.id}`
    ? [mainnet]
    : [holesky]

export const rpcUrls: {
  [key: number]: string
} = {
  [mainnet.id]: `${process.env.REACT_APP_MAINNET_RPC}`,
  [goerli.id]: `${process.env.REACT_APP_GOERLI_RPC}`,
  [holesky.id]: `${process.env.REACT_APP_HOLESKY_RPC}`
}

export const explorerUrls: {
  [key: number]: string
} = {
  [mainnet.id]: `https://etherscan.io`,
  [goerli.id]: `https://goerli.etherscan.io`,
  [holesky.id]: `https://holesky.etherscan.io/`
}

export const beaconUrls: {
  [key: number]: string
} = {
  [mainnet.id]: `https://beaconcha.in`,
  [goerli.id]: `https://prater.beaconcha.in`,
  [holesky.id]: `https://holesky.beaconcha.in`
}

export const BEACON_NODE_URL = 'https://info-goerli.joinstakehouse.com/'
