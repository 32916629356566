import { ethers } from 'ethers'
import { useCallback, useContext, useEffect, useState } from 'react'

import { ContractContext } from '@/context/ContractContext'
import { balanceOf } from '@/utils/contractFunctions'

import { useCustomAccount } from './useCustomAccount'

export const useK2WithdrawableAmount = () => {
  const { k2PoolContract } = useContext(ContractContext)
  const { account } = useCustomAccount()

  const [withdrawableAmount, setWithdrawableAmount] = useState('')

  const fetchWithdrawableAmount = useCallback(async () => {
    if (k2PoolContract && account) {
      const balance = await balanceOf(k2PoolContract, account.address)
      if (balance) {
        setWithdrawableAmount(ethers.utils.formatEther(balance))
      }
    }
  }, [k2PoolContract, account])

  useEffect(() => {
    fetchWithdrawableAmount()
  }, [fetchWithdrawableAmount])

  return { withdrawableAmount, fetchWithdrawableAmount }
}
