import './styles.scss'

import { Menu, Transition } from '@headlessui/react'
import { FC, PropsWithChildren, useEffect, useState } from 'react'

import { ReactComponent as TopRightIcon } from '@/assets/images/icon-arrow-top-right-grey.svg'
import { useCustomAccount } from '@/hooks'
import { TMenu } from '@/types'

import Switch from '../Switch'

interface IProps {
  options: TMenu[]
}

const Dropdown: FC<PropsWithChildren<IProps>> = ({ children, options }) => {
  const [isToggled, setIsToggled] = useState(true)

  function handleOptionClick(option: TMenu): void {
    if (option.disabled) return
    if (option.onClick) {
      option.onClick()
    }
  }

  useEffect(() => {
    if (!isToggled) {
      window.open('https://lsd.joinstakehouse.com/', '_self')
    }
  }, [isToggled])

  return (
    <Menu as="div" className="relative" style={{ height: 38 }}>
      <Menu.Button>{children}</Menu.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0">
        <Menu.Items className="menu__popup">
          {options.map((option) => (
            <div key={option.id}>
              {option.type === 'menu' && (
                <Menu.Item disabled={option.disabled}>
                  {({ active, disabled }) => (
                    <div
                      className={`${active ? 'menu__item--selected' : 'menu__item'}
                       ${disabled ? 'disabled' : ''}`}
                      onClick={() => option.onClick && option.onClick()}>
                      <span className="icon-label-row">
                        {option.icon}
                        <span className="label">{option.label}</span>
                      </span>
                      {option.secondRow && <span className="secondRow">{option.secondRow}</span>}
                    </div>
                  )}
                </Menu.Item>
              )}
              {option.type === 'separator' && <div className="separator"></div>}
              {option.type === 'link' && (
                <a
                  href={option.link ? option.link : undefined}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="menu__item"
                  onClick={option.onClick ? option.onClick : undefined}>
                  <span className="linkRow">
                    <span className="link">{option.label}</span>
                    <TopRightIcon />
                  </span>
                </a>
              )}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default Dropdown
