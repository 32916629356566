import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client'
import { ApolloProvider } from '@apollo/client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import GraphqlPlayground from '@/components/GraphqlPlayground'
import { LayoutDashboard } from '@/components/layouts'
import { KETH_SUBGRAPH } from '@/constants/dappQueries'
import BlockswapSDKProvider from '@/context/BlockswapSDKContext'
import GraphqlProvider from '@/context/GraphqlContext'
import ThemeProvider from '@/context/ThemeContext'
import UserProvider from '@/context/UserContext'
import { Activity, Borrow, Claim, Home, Supply } from '@/views'

import { ContractProvider } from './context/ContractContext'
import EnvironmentProvider from './context/EnvironmentContext'
import { useConfig } from './hooks'
import { NodeCloud } from './views/NodeCloud'

const SubApp = () => {
  const config = useConfig()
  const graphqlClient = new ApolloClient({
    link: split(
      ({ operationName }) => {
        return KETH_SUBGRAPH.includes(operationName)
      },
      new HttpLink({ uri: config.GRAPHQL_URL }),
      new HttpLink({ uri: config.LSD_GRAPHQL_URL })
    ),
    cache: new InMemoryCache()
  })

  console.log(config.GRAPHQL_URL, config.LSD_GRAPHQL_URL)

  return (
    <>
      <ApolloProvider client={graphqlClient}>
        <ThemeProvider>
          <BlockswapSDKProvider>
            <ContractProvider>
              <UserProvider>
                <EnvironmentProvider>
                  <GraphqlProvider>
                    <Routes>
                      <Route path="/" element={<LayoutDashboard />}>
                        <Route index element={<Home />} />
                        <Route path="home" element={<Home />} />
                        <Route path="supply" element={<Supply />} />
                        <Route path="borrow" element={<Borrow />} />
                        <Route path="node-cloud" element={<NodeCloud />} />
                        <Route path="claim" element={<Claim />} />
                        <Route path="activity" element={<Activity />} />
                        <Route path="*" element={<Home />} />
                      </Route>
                      <Route
                        path="/graphql-playground/:mode/:account"
                        element={<GraphqlPlayground />}
                      />
                    </Routes>
                  </GraphqlProvider>
                </EnvironmentProvider>
              </UserProvider>
            </ContractProvider>
          </BlockswapSDKProvider>
        </ThemeProvider>
      </ApolloProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  )
}
export default SubApp
