import '../styles.scss'

import { FC, useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { mainnet, useAccount, useNetwork } from 'wagmi'
import { useDisconnect } from 'wagmi'

import AccountImage from '@/assets/images/account-icon.png'
import { ReactComponent as LogoutIcon } from '@/assets/images/icon-logout.svg'
import { ReactComponent as MyActivityIcon } from '@/assets/images/icon-my-activity.svg'
import { ReactComponent as SettingsIcon } from '@/assets/images/icon-settings.svg'
import { ReactComponent as LogoIcon } from '@/assets/images/logo.svg'
import { ModalAccount } from '@/components/app'
import { ClipboardCopy } from '@/components/shared'
import { Dropdown } from '@/components/shared'
import { ThemeContext } from '@/context/ThemeContext'
import { useConfig, useCustomAccount, useTokens } from '@/hooks'
import { TMenu } from '@/types'
import { holesky } from '@/utils/holesky'
import { addTokenToMetaMask } from '@/utils/tokens'

import { ChainAlert } from './ChainAlert'
import NavItem from './NavItem'
import { NetworkMenu } from './NetworkMenu'

const Topbar: FC = () => {
  const { account } = useCustomAccount()
  const { isConnected } = useAccount()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { K2Pool, KETH } = useTokens()
  const { theme, toggleTheme } = useContext(ThemeContext)
  const { disconnect } = useDisconnect()
  const [openAccountModal, setOpenAccountModal] = useState(false)
  const { chain: activeChain } = useNetwork()
  const config = useConfig()
  const isMainnet = activeChain?.id == mainnet.id && config.isPrivateMainnet == 'true'

  const handleDisconnect = () => {
    setOpenAccountModal(false)
    disconnect()
  }

  const options: TMenu[] = isMainnet
    ? [
        {
          id: 0,
          type: 'menu',
          label: 'Account',
          icon: (
            <div
              style={{
                backgroundImage: `url(${AccountImage})`,
                width: '14px',
                height: '14px',
                backgroundSize: 'cover'
              }}></div>
          ),
          onClick: () => {},
          secondRow: (
            <div className="flex items-center ml-5">
              <span className="mr-1">
                {account ? `${account.address!.slice(0, 9)}...${account.address!.slice(-9)}` : ''}
              </span>
              <ClipboardCopy copyText={account ? account.address : ''} />
            </div>
          )
        },
        // {
        //   id: 1,
        //   type: 'menu',
        //   label: 'My Activity',
        //   icon: <MyActivityIcon />,
        //   onClick: () => handleOpenActivity()
        // },
        // {
        //   id: 2,
        //   type: 'menu',
        //   label: 'Settings',
        //   icon: <SettingsIcon />,
        //   onClick: () => {}
        // },
        {
          id: 3,
          type: 'menu',
          label: 'Log Out',
          icon: <LogoutIcon />,
          onClick: () => setOpenAccountModal(true)
        },
        {
          id: 5,
          type: 'separator'
        },
        {
          id: 6,
          type: 'link',
          label: 'Add kETH to Metamask',
          onClick: () => {
            addTokenToMetaMask(KETH)
          }
        },
        {
          id: 7,
          type: 'link',
          label: 'Add K2 to Metamask',
          onClick: () => {
            addTokenToMetaMask(K2Pool)
          }
        },
        {
          id: 8,
          type: 'link',
          label: 'About',
          link: 'https://restaking.cloud/'
        }
      ]
    : [
        {
          id: 0,
          type: 'menu',
          label: 'Account',
          icon: (
            <div
              style={{
                backgroundImage: `url(${AccountImage})`,
                width: '14px',
                height: '14px',
                backgroundSize: 'cover'
              }}></div>
          ),
          onClick: () => {},
          secondRow: (
            <div className="flex items-center ml-5">
              <span className="mr-1">
                {account ? `${account.address!.slice(0, 9)}...${account.address!.slice(-9)}` : ''}
              </span>
              <ClipboardCopy copyText={account ? account.address : ''} />
            </div>
          )
        },
        {
          id: 1,
          type: 'menu',
          label: 'My Activity',
          icon: <MyActivityIcon />,
          onClick: () => handleOpenActivity()
        },
        {
          id: 2,
          type: 'menu',
          label: 'Settings',
          icon: <SettingsIcon />,
          onClick: () => {}
        },
        {
          id: 3,
          type: 'menu',
          label: 'Log Out',
          icon: <LogoutIcon />,
          onClick: () => setOpenAccountModal(true)
        },
        {
          id: 5,
          type: 'separator'
        },
        {
          id: 6,
          type: 'link',
          label: 'Add kETH to Metamask',
          onClick: () => {
            addTokenToMetaMask(KETH)
          }
        },
        {
          id: 7,
          type: 'link',
          label: 'Add K2 to Metamask',
          onClick: () => {
            addTokenToMetaMask(K2Pool)
          }
        },
        {
          id: 8,
          type: 'link',
          label: 'About',
          link: 'https://restaking.cloud/'
        }
      ]

  const handleChangeTheme = () => {
    if (theme === 'dark') {
      toggleTheme('light')
    } else {
      toggleTheme('dark')
    }
  }

  const handleOpenActivity = () => {
    navigate('/activity')
  }

  return (
    <>
      <div className="topbar">
        <a href="#" onClick={() => navigate('/')}>
          <LogoIcon />
        </a>

        <div className="topbar__navMenu">
          <Link to={'/supply'}>
            <NavItem active={pathname === '/supply'}>Supply</NavItem>
          </Link>
          <Link to={'/borrow'}>
            <NavItem active={pathname === '/borrow'}>Borrow</NavItem>
          </Link>
          {activeChain?.id === holesky.id && (
            <Link to={'/node-cloud'}>
              <NavItem active={pathname === '/node-cloud'}>Node Cloud</NavItem>
            </Link>
          )}
        </div>

        <div className="flex items-center gap-3 h-full">
          {isConnected && account && (
            <>
              <NetworkMenu />
              <Dropdown options={options}>
                <div className="topbar__menu-btn">
                  <div className="connect-wallet--secondary flex items-center gap-1 -mx-2">
                    {`${account.address!.slice(0, 4)}...${account.address!.slice(-2)}`}
                    <div
                      style={{
                        backgroundImage: `url(${AccountImage})`,
                        width: '20px',
                        height: '20px',
                        backgroundSize: 'cover'
                      }}></div>
                  </div>
                </div>
              </Dropdown>
              <ModalAccount
                open={openAccountModal}
                onClose={() => setOpenAccountModal(false)}
                accountAddress={account.address!}
                onDisconnect={handleDisconnect}
              />
            </>
          )}
        </div>
      </div>
      <ChainAlert />
    </>
  )
}

export default Topbar
