import { BigNumber, ethers } from 'ethers'
import { formatEther, parseEther } from 'ethers/lib/utils.js'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBalance } from 'wagmi'

import {
  CompletedTxView,
  ConfirmModal,
  ErrorModal,
  LoadingModal,
  ModalDialog,
  Tooltip
} from '@/components/shared'
import { ContractContext } from '@/context/ContractContext'
import {
  useAllowanceCheck,
  useConfig,
  useCustomAccount,
  useK2TopUpSlashAmount,
  useMakeRealTxHash,
  useNetworkBasedLinkFactories,
  useTokens
} from '@/hooks'
import { bigToNum, formatNumber } from '@/utils/global'

interface IProps {
  open: boolean
  unpaidDebt: ethers.BigNumber
  unpaidDebtUsd: number
  fee: number
  setOpenConfirmModal: (state: boolean) => void
}

const ModalSlashDebtRepayment: FC<IProps> = ({
  open,
  unpaidDebt,
  fee,
  setOpenConfirmModal,
  unpaidDebtUsd
}) => {
  const { makeEtherscanLink } = useNetworkBasedLinkFactories()
  const navigate = useNavigate()
  const { K2_LP_TOKENS, KETH } = useTokens()
  const [failedTopUp, setFailedTopUp] = useState(false)
  const [minTransferLimit, setMinTransferLimit] = useState<ethers.BigNumber>()
  const { k2PoolContract, kETHVaultContract } = useContext(ContractContext)
  const { allowance, isApproving, handleApproveToken } = useAllowanceCheck(
    KETH?.address,
    k2PoolContract?.address!
  )
  const { onTopUp, isTopingUp, txResult, onClear } = useK2TopUpSlashAmount()
  const { hash } = useMakeRealTxHash(txResult?.hash)
  const { account } = useCustomAccount()
  const config = useConfig()

  const { data: { value: balance } = {}, refetch } = useBalance({
    address: account?.address,
    formatUnits: 'ether',
    token: K2_LP_TOKENS?.[0].address as `0x${string}`,
    chainId: config.networkId
  })

  const handleConfirmModalClose = () => {
    setOpenConfirmModal(false)
  }

  useEffect(() => {
    const fetchMinTransferAmount = async () => {
      const minTransferLimit = await kETHVaultContract?.minTransferAmount()
      setMinTransferLimit(minTransferLimit)
    }
    fetchMinTransferAmount()
  }, [kETHVaultContract])

  const handleConfirm = async () => {
    const minTransferLimit = await kETHVaultContract?.minTransferAmount()

    const amount = unpaidDebt.lt(minTransferLimit) ? minTransferLimit : unpaidDebt

    let isError = false
    if (!allowance || allowance.lt(amount)) {
      isError = await handleApproveToken(amount)
    }

    if (!isError) {
      const wasSucceed = await onTopUp(amount)

      if (!wasSucceed) setFailedTopUp(true)
    } else {
      setFailedTopUp(true)
    }
  }

  const handleGoToHome = () => {
    navigate('/')
  }

  const handleCloseSuccessModal = () => {
    onClear()
    setOpenConfirmModal(false)
  }

  const topUpAmount = useMemo(
    () =>
      unpaidDebt.lt(minTransferLimit || BigNumber.from(0))
        ? minTransferLimit || BigNumber.from(0)
        : unpaidDebt,
    [unpaidDebt, minTransferLimit]
  )

  return (
    <>
      <ConfirmModal
        title="Transaction Summary"
        allowLoadingView={true}
        comment={
          unpaidDebt.lt(minTransferLimit || BigNumber.from('0')) && (
            <>
              Your slash debt is very low. You need to approve at least{' '}
              {formatEther(minTransferLimit || BigNumber.from('0'))} KETH to repay slash debt.
            </>
          )
        }
        loading={isTopingUp || isApproving}
        loadingViewTitle={
          isApproving
            ? 'Approve Transaction Pending...'
            : isTopingUp
            ? 'TopUp Transaction Pending...'
            : 'Loading...'
        }
        message={
          <div className="px-6 pb-2">
            <div className="text-white pb-4">Repay slashing debt</div>
            <hr className="border-white pb-4" />

            <div className="text-left">You need to pay</div>

            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <span className="text-white pr-2">Slashing debt </span>
              </div>
              <span className="text-yellowAlert">${formatNumber(unpaidDebtUsd)}</span>
            </div>

            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <span className="text-white pr-2">Fee </span>
                <Tooltip message="Fee." />
              </div>
              <span className="text-primaryGreen">${fee}</span>
            </div>
          </div>
        }
        confirmButtonContent={balance?.lt(topUpAmount) ? 'Insufficient funds' : 'Confirm'}
        onConfirm={handleConfirm}
        onClose={handleConfirmModalClose}
        open={open}
        confirmButtonDisabled={unpaidDebt.isZero() || balance?.lt(topUpAmount)}
      />
      <ModalDialog open={!!txResult} onClose={handleCloseSuccessModal}>
        <CompletedTxView
          goToContent="Home"
          title="Success"
          txLink={makeEtherscanLink(hash)}
          onGoToClick={handleGoToHome}
          message={
            <span className="text-sm text-grey300">{`You've successfully made a top up.`}</span>
          }
        />
      </ModalDialog>
      <ErrorModal
        open={!!failedTopUp}
        onClose={() => {
          setFailedTopUp(false)
        }}
        title="Error!"
        message={'It looks like something went wrong.'}
        actionButtonContent="Try Again"
        onAction={() => {
          setFailedTopUp(false)
        }}
      />
    </>
  )
}

export default ModalSlashDebtRepayment
