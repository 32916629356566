import { BigNumber, constants, ethers } from 'ethers'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import { goerli, mainnet, useBalance, useNetwork } from 'wagmi'

import { ReactComponent as ArrowDownIcon } from '@/assets/images/icon-dropdown-arrow.svg'
import EthIcon from '@/assets/images/icon-eth.svg'
import { TokenSelectorDropdown } from '@/components/app/Borrow/TokenSelectorDropdown'
import {
  Button,
  ClipboardCopy,
  CompletedTxView,
  ConfirmModal,
  ErrorModal,
  LoadingModal,
  ModalDialog,
  NumberAdjusterWithTextInput,
  TextInput,
  TextInputWithInsideLabel,
  TextInputWithSelector,
  Tooltip
} from '@/components/shared'
import { DEFAULT_DESIGNATED_VERIFIER } from '@/constants'
import { ContractContext } from '@/context/ContractContext'
import { UserContext } from '@/context/UserContext'
import {
  TokenT,
  useAllowanceCheck,
  useConfig,
  useCustomAccount,
  useK2AvailableToBorrow,
  useK2Borrow,
  useK2DebtPostion,
  useK2DefaultMaxLivenessAndMaxCorruption,
  useK2Interest,
  useMakeRealTxHash,
  useNetworkBasedLinkFactories,
  useTokens
} from '@/hooks'
import {
  bigToNum,
  numToBig,
  readablePrice,
  roundNumber,
  secondsToDHM,
  secondstoM
} from '@/utils/global'

import { BSCheckbox } from '../Chart'
import styles from './styles.module.scss'

const PERCENTAGE = [
  { id: 0, value: 0.25, label: '25%' },
  { id: 1, value: 0.5, label: '50%' },
  { id: 2, value: 0.75, label: '75%' },
  { id: 3, value: 1, label: 'MAX' }
]

export const BorrowSection = () => {
  const [designatedVerifier, setDesignatedVerifier] = useState('')
  const [duration, setDuration] = useState(0)
  const [resetDurationToSixMonths, setResetDurationToSixMonths] = useState(false)
  const [maxLiveness, setMaxLiveness] = useState(0)
  const [maxCorruption, setMaxCorruption] = useState(0)
  const [maxLivenessUpperLimit, setMaxLivenessUpperLimit] = useState(0)
  const [maxCorruptionUpperLimit, setMaxCorruptionUpperLimit] = useState(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isValidationError, setIsValidationError] = useState<boolean>(false)
  const config = useConfig()
  const { DETH, ETH, KETH, LSD_TOKEN_SELECTOR_OPTIONS, RETH, STETH } = useTokens()

  const [borrowAmount, setBorrowAmount] = useState('')
  const [availableToBorrow, setAvailableToBorrow] = useState(0)
  const [availableToBorrowUSD, setAvailableToBorrowUSD] = useState(0)
  const [borrowAmountInputLabel, setBorrowAmountInputLabel] = useState('Borrow Amount')
  const [availableToBorrowAmountLabel, setAvaialbleToBorrowAmountLabel] =
    useState('Available to Borrow')
  const [borrowBottonLabel, setBorrowBottonLabel] = useState('Borrow')
  const [failedBorrow, setFailedBorrow] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openLSDTokenSelectorModal, setOpenLSDTokenSelectorModal] = useState(false)
  const [selectedLSDToken, setSelectedLSDToken] = useState<TokenT>(KETH ?? {})
  const [lsdTokenBalance] = useState<Map<number, ethers.BigNumber>>(
    new Map<number, ethers.BigNumber>()
  )
  const [rstTicker, setRstTicker] = useState<string>()
  const [incentive, setIncentive] = useState<string>('10')
  const [isRSTActivated, setIsRSTActivated] = useState<boolean>(false)
  const [rstValidationError, setRstValidationError] = useState<string[]>([])

  const [fee, setFee] = useState(0)

  const navigate = useNavigate()
  const { chain: activeChain } = useNetwork()

  const isMainnet = activeChain?.id == mainnet.id && config.isPrivateMainnet == 'true'

  const { k2PoolContract, k2LendingDepositorContract } = useContext(ContractContext)
  const { refreshData } = useContext(UserContext)
  const {
    result: defaultMaxLivenessAndCorruptionResult,
    fetchData: fetchDefaultMaxLivenessAndBorrowAmount
  } = useK2DefaultMaxLivenessAndMaxCorruption()

  const { result: interestResult, fetchK2Interest } = useK2Interest()

  const { result: availableToBorrowResult, fetchAvailableToBorrow } = useK2AvailableToBorrow(
    interestResult?.outstandingInterest || BigNumber.from('0')
  )
  const { result: debtPostionResult, defaultDuration } = useK2DebtPostion()

  const {
    onBorrowWithKETH,
    onBorrowWithLSDToken,
    isBorrowing,
    txResult,
    onClear,
    validationError,
    payAmountResult,
    fetchPayAmountData
  } = useK2Borrow()
  const { hash } = useMakeRealTxHash(txResult?.hash)
  const { makeEtherscanLink } = useNetworkBasedLinkFactories()
  const {
    allowance: kETHAllowance,
    handleApproveToken: handleKETHApproveToken,
    isApproving: isKETHApproving
  } = useAllowanceCheck(KETH?.address, k2PoolContract?.address!)
  const {
    allowance: stETHAllowance,
    handleApproveToken: handleSTETHApproveToken,
    isApproving: isSTETHApproving
  } = useAllowanceCheck(STETH?.address, k2LendingDepositorContract?.address!)
  const {
    allowance: dETHAllowance,
    handleApproveToken: handleDETHApproveToken,
    isApproving: isDETHAppriving
  } = useAllowanceCheck(DETH?.address, k2LendingDepositorContract?.address!)
  const {
    allowance: rETHAllowance,
    handleApproveToken: handleRETHApproveToken,
    isApproving: isRETHApproving
  } = useAllowanceCheck(RETH?.address, k2LendingDepositorContract?.address!)

  const { account } = useCustomAccount()

  LSD_TOKEN_SELECTOR_OPTIONS?.forEach(async (token: TokenT) => {
    const { data: { value: balance } = {} } = await useBalance(
      token.symbol != 'ETH'
        ? {
            address: account?.address,
            formatUnits: 'ether',
            token: token.address as `0x${string}`,
            chainId: config.networkId
          }
        : {
            address: account?.address,
            formatUnits: 'ether',
            chainId: config.networkId
          }
    )
    if (balance) {
      lsdTokenBalance.set(token.id, balance)
    } else {
      lsdTokenBalance.set(token.id, ethers.BigNumber.from(0))
    }
  })

  useEffect(() => {
    try {
      fetchDefaultMaxLivenessAndBorrowAmount(
        ethers.utils.parseEther(borrowAmount === '.' || !borrowAmount ? '0' : borrowAmount)
      )
    } catch (error) {
      console.log(error)
    }
  }, [borrowAmount])

  useEffect(() => {
    if (!defaultMaxLivenessAndCorruptionResult) return
    setMaxLiveness(bigToNum(defaultMaxLivenessAndCorruptionResult.maxLivenessDefault))
    setMaxCorruption(bigToNum(defaultMaxLivenessAndCorruptionResult.maxCorruptionDefault))

    setMaxLivenessUpperLimit(bigToNum(defaultMaxLivenessAndCorruptionResult.maxLivenessUpperLimit))
    setMaxCorruptionUpperLimit(
      bigToNum(defaultMaxLivenessAndCorruptionResult.maxCorruptionUpperLimit)
    )
  }, [defaultMaxLivenessAndCorruptionResult])

  useEffect(() => {
    if (availableToBorrowResult) {
      setAvailableToBorrow(availableToBorrowResult?.availableToBorrow || 0)
      setAvailableToBorrowUSD(availableToBorrowResult?.availableToBorrowUSD || 0)
    }
  }, [availableToBorrowResult])

  useEffect(() => {
    if ((maxLiveness === 0 && maxCorruption === 0) || !Number(borrowAmount)) return
    fetchK2Interest(borrowAmount, maxLiveness, maxCorruption, duration)
  }, [borrowAmount, maxLiveness, maxCorruption, duration])

  useEffect(() => {
    if (debtPostionResult?.isDebtPostionAvailable) {
      setBorrowAmountInputLabel('Borrow Amount - Increase Position')
      setAvaialbleToBorrowAmountLabel('Available to Top Up')
      setBorrowBottonLabel('Update Position')
      setDuration(debtPostionResult?.duration)
      setDesignatedVerifier(debtPostionResult?.designatedVerifier)
      setMaxLiveness(debtPostionResult?.maxSlashableAmountPerLiveness)
      setMaxCorruption(debtPostionResult?.maxSlashableAmountPerCorruption)
    } else if ([goerli.id, 17000].includes(activeChain?.id || 0)) {
      setDesignatedVerifier(DEFAULT_DESIGNATED_VERIFIER)
    }
  }, [debtPostionResult, activeChain?.id])

  useEffect(() => {
    if (!interestResult || interestResult.interest.lt(ethers.BigNumber.from(0))) return
    fetchPayAmountData(interestResult.interest)
  }, [interestResult])

  const handleChangeBorrowAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (!value) setBorrowAmount('')

    if (value === '.' || !isNaN(Number(value))) {
      setBorrowAmount(value)
    }
  }

  const handleChangeDesignatedVerifier = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (!value) setDesignatedVerifier('')
    setDesignatedVerifier(value)
  }

  const handleCloseSuccessModal = () => {
    onClear()
  }

  const handleGoToHome = () => {
    navigate('/')
  }

  const handleResetDuration = () => {
    setDuration(defaultDuration)
    setResetDurationToSixMonths(true)
  }

  const handleBorrowConfirmationClick = () => {
    if (
      // if there is enough kETH to cover the interest
      (lsdTokenBalance.get(KETH?.id) || ethers.BigNumber.from(0)).gt(interestResult?.interest || 0)
    ) {
      // the user can borrow
      handleKETHBorrow()
    } else {
      // otherwise he needs to pick another LSD token to pay the interest
      setOpenConfirmModal(false)
      setOpenLSDTokenSelectorModal(true)
    }
  }

  const handleKETHBorrow = async () => {
    setOpenConfirmModal(false)

    if (!interestResult) return

    setIsLoading(true)

    let isError = false
    if (!kETHAllowance || kETHAllowance.lt(interestResult.interest)) {
      isError = await handleKETHApproveToken(interestResult.interest)
    }
    if (isError) {
      setFailedBorrow(true)
      setIsLoading(false)
      return
    }

    const RSTConfig = !isRSTActivated
      ? {
          mintRST: false,
          initialSupply: 0,
          recipientOfRemainingSupply: constants.AddressZero,
          percentageContributionToIncentives: 0,
          symbol: ''
        }
      : {
          mintRST: true,
          initialSupply: ethers.utils.parseEther(borrowAmount),
          recipientOfRemainingSupply: account.address,
          percentageContributionToIncentives: Number(incentive) * 10000,
          symbol: rstTicker
        }

    const wasSucceed = await onBorrowWithKETH(
      Number(borrowAmount),
      designatedVerifier,
      maxLiveness,
      maxCorruption,
      availableToBorrow,
      debtPostionResult?.isDebtPostionAvailable ? debtPostionResult?.isDebtPostionAvailable : false,
      resetDurationToSixMonths,
      RSTConfig
    )
    setIsLoading(false)
    if (!wasSucceed) setFailedBorrow(true)
    else {
      setBorrowAmount('')
      refreshData()
    }
  }

  const handleLSDSelectorConfirmationClick = async () => {
    if (lsdTokenBalance.get(selectedLSDToken.id)?.lt(interestResult?.interest || 0)) return

    let payAmount: ethers.BigNumber = ethers.BigNumber.from(0)
    switch (selectedLSDToken.id) {
      case STETH?.id:
        if (!payAmountResult?.stETHpayAmount) return
        if (!stETHAllowance || stETHAllowance.lt(payAmountResult?.stETHpayAmount)) {
          await handleSTETHApproveToken(payAmountResult?.stETHpayAmount)
        }
        payAmount = payAmountResult?.stETHpayAmount
        break
      case DETH?.id:
        if (!payAmountResult?.dETHpayAmount) return
        if (!dETHAllowance || dETHAllowance.lt(payAmountResult?.dETHpayAmount)) {
          await handleDETHApproveToken(payAmountResult?.dETHpayAmount)
        }
        payAmount = payAmountResult?.dETHpayAmount
        break
      case RETH?.id:
        if (!payAmountResult?.rETHpayAmount) return
        if (!rETHAllowance || rETHAllowance.lt(payAmountResult?.rETHpayAmount)) {
          await handleRETHApproveToken(payAmountResult?.rETHpayAmount)
        }
        payAmount = payAmountResult?.rETHpayAmount
        break
      case ETH?.id:
        if (!payAmountResult?.ETHpayAmount) return
        payAmount = payAmountResult?.ETHpayAmount
        break
      default:
        throw new Error('Invalid LSD token')
    }

    const RSTConfig = !isRSTActivated
      ? {
          mintRST: false,
          initialSupply: 0,
          recipientOfRemainingSupply: constants.AddressZero,
          percentageContributionToIncentives: 0,
          symbol: ''
        }
      : {
          mintRST: true,
          initialSupply: ethers.utils.parseEther(borrowAmount),
          recipientOfRemainingSupply: account.address,
          percentageContributionToIncentives: Number(incentive) * 10000,
          symbol: rstTicker
        }

    const wasSucceed = await onBorrowWithLSDToken(
      Number(borrowAmount),
      designatedVerifier,
      maxLiveness,
      maxCorruption,
      availableToBorrow,
      debtPostionResult?.isDebtPostionAvailable ? debtPostionResult?.isDebtPostionAvailable : false,
      resetDurationToSixMonths,
      selectedLSDToken,
      payAmount,
      RSTConfig
    )
    if (!wasSucceed) setFailedBorrow(true)
    else {
      refreshData()
      setBorrowAmount('')
    }
  }

  const handleBorrowClick = () => {
    setOpenConfirmModal(true)
  }

  const handleConfirmModalClose = () => {
    setOpenConfirmModal(false)
  }

  const handleLSDTokenSelectModalClose = () => {
    setOpenConfirmModal(false)
    setOpenLSDTokenSelectorModal(false)
  }

  useEffect(() => {
    if (isRSTActivated) {
      let error: string[] = []

      if ((rstTicker?.length || 0) > 4 || !rstTicker?.length)
        error = [...error, 'RST Ticker is required and should be shorter than 5 characters.']

      if (Number(incentive) < 10 || Number(incentive) > 100) {
        error = [...error, 'Incentive should be between 10% and 100%.']
      }

      setRstValidationError(error)
    } else {
      setRstValidationError([])
    }
  }, [rstTicker, incentive, isRSTActivated])

  useEffect(() => {
    if (Number(borrowAmount) > 0)
      setIsValidationError(
        Number(borrowAmount) > Number(availableToBorrow) ||
          ethers.utils.parseUnits('0.005').gt(interestResult?.interest || 0) ||
          (debtPostionResult?.isDebtPostionAvailable &&
            maxLiveness < debtPostionResult.maxSlashableAmountPerLiveness) ||
          (debtPostionResult?.isDebtPostionAvailable &&
            maxCorruption < debtPostionResult?.maxSlashableAmountPerCorruption) ||
          (maxLivenessUpperLimit > 0 && maxLiveness >= maxLivenessUpperLimit) ||
          (maxCorruptionUpperLimit > 0 && maxCorruption >= maxCorruptionUpperLimit) ||
          (debtPostionResult?.slashAmount !== undefined && debtPostionResult?.slashAmount > 0)
      )
    else setIsValidationError(false)
  }, [
    borrowAmount,
    availableToBorrow,
    interestResult?.interest,
    debtPostionResult?.isDebtPostionAvailable,
    maxLiveness,
    debtPostionResult?.maxSlashableAmountPerLiveness,
    maxCorruption,
    debtPostionResult?.maxSlashableAmountPerCorruption,
    maxLivenessUpperLimit,
    maxCorruptionUpperLimit,
    debtPostionResult?.slashAmount
  ])

  return (
    <div className="px-6 py-10 flex flex-col gap-4">
      <div className="relative">
        <TextInputWithSelector
          isError={isValidationError}
          label={borrowAmountInputLabel}
          type="text"
          selectorList={PERCENTAGE}
          className={styles.input}
          pattern="(\.\d{0,1}|\d*\.\d{0,2})"
          value={borrowAmount}
          setAmount={setBorrowAmount}
          maxAmount={availableToBorrow.toString()}
          onChange={handleChangeBorrowAmount}
          enabled={true}
        />
        <InputRightElement>
          <div className="rounded-full p-1 pr-2 bg-background400 flex items-center gap-2">
            <img src={ETH?.icon} alt="token_icon" className="w-6" />
            <p className="text-textBase text-xl font-medium leading-5">{ETH?.symbol}</p>
          </div>
        </InputRightElement>
        {Number(borrowAmount) > 0 && (
          <div className="flex flex-col gap-2">
            {validationError && (
              <p className="font-medium text-sm flex items-center gap-2 text-red">
                {validationError.error}
              </p>
            )}
            {Number(borrowAmount) > Number(availableToBorrow) && (
              <p className="font-medium text-sm flex items-center gap-2 text-red">
                Borrow amount more than max borrowable amount
              </p>
            )}
            {ethers.utils.parseUnits('0.005').gt(interestResult?.interest || 0) && (
              <p className="font-medium text-sm flex items-center gap-2 text-red">
                Borrow amount too small
              </p>
            )}
            {debtPostionResult?.isDebtPostionAvailable &&
              maxLiveness < debtPostionResult.maxSlashableAmountPerLiveness && (
                <p className="font-medium text-sm flex items-center gap-2 text-red">
                  MaxLiveness: {maxLiveness} is lower than the previous maxLiveness :{' '}
                  {debtPostionResult.maxSlashableAmountPerLiveness}
                </p>
              )}

            {debtPostionResult?.isDebtPostionAvailable &&
              maxCorruption < debtPostionResult.maxSlashableAmountPerCorruption && (
                <p className="font-medium text-sm flex items-center gap-2 text-red">
                  MaxCorruption: {maxCorruption} is lower than the previous maxCorruption :{' '}
                  {debtPostionResult.maxSlashableAmountPerCorruption}
                </p>
              )}
            {maxLivenessUpperLimit > 0 && maxLiveness >= maxLivenessUpperLimit && (
              <p className="font-medium text-sm flex items-center gap-2 text-red">
                MaxLiveness: {maxLiveness} exceeds MaxLiveness Upper Limit : {maxLivenessUpperLimit}
              </p>
            )}
            {maxCorruptionUpperLimit > 0 && maxCorruption >= maxCorruptionUpperLimit && (
              <p className="font-medium text-sm flex items-center gap-2 text-red">
                MaxCorruption: {maxCorruption} exceeds MaxCorruption Upper Limit :{' '}
                {maxCorruptionUpperLimit}
              </p>
            )}
            {debtPostionResult?.slashAmount !== undefined && debtPostionResult?.slashAmount > 0 && (
              <p className="font-medium text-sm flex items-center gap-2 text-red">
                Slashing Debt: You have to pay slashing debt.
              </p>
            )}
          </div>
        )}
      </div>

      <div className="flex justify-end items-center w-full">
        <p className="text-textLabel text-sm text-right flex items-center gap-1">
          {availableToBorrowAmountLabel}{' '}
          <Tooltip message="This is estimated max borrowable amount based on your keth balance." />:{' '}
          {isMainnet ? (
            'Restricted Info'
          ) : (
            <>
              {roundNumber(availableToBorrow, 2)} {ETH?.symbol} ($
              {readablePrice(availableToBorrowUSD)})
            </>
          )}
        </p>
      </div>

      <TextInputWithInsideLabel
        label="Designated verifier"
        type="text"
        value={designatedVerifier}
        onChange={handleChangeDesignatedVerifier}
        clearInput={() => setDesignatedVerifier('')}
        placeholder="Enter wallet or ECDSA"
        disabled={borrowAmount === '0'}
        tooltip={
          <>
            This address will be used to authenticate slashing. The Default Address is:
            <div className="flex items-center gap-1">
              {DEFAULT_DESIGNATED_VERIFIER}
              <ClipboardCopy copyText={DEFAULT_DESIGNATED_VERIFIER} />
            </div>
          </>
        }
      />

      {isRSTActivated && !debtPostionResult?.isDebtPostionAvailable && (
        <>
          <div className="flex w-full gap-2">
            <div className="w-full">
              <TextInputWithInsideLabel
                label="RST Ticker"
                type="text"
                value={rstTicker}
                onChange={(e) => setRstTicker(e.target.value)}
                clearInput={() => setRstTicker('')}
                placeholder="Name your RST"
                disabled={borrowAmount === '0'}
                tooltip={<>RST Ticker</>}
              />
            </div>
            <div className="w-full">
              <TextInputWithInsideLabel
                label="Incentives"
                type="text"
                value={incentive}
                onChange={(e) => setIncentive(e.target.value)}
                clearInput={() => setIncentive('')}
                placeholder="10%"
                disabled={borrowAmount === '0'}
                tooltip={<>Incentives</>}
              />
            </div>
          </div>
          {rstValidationError.length > 0 && (
            <div className="text-red text-xs flex flex-col">
              {rstValidationError.map((err, index) => (
                <div key={index}>{err}</div>
              ))}
            </div>
          )}
        </>
      )}
      {!debtPostionResult?.isDebtPostionAvailable && (
        <BSCheckbox
          color="white"
          className="text-sm"
          value={isRSTActivated}
          onChange={(isRSTActivated) => setIsRSTActivated(isRSTActivated)}>
          Activate my RST Token
        </BSCheckbox>
      )}

      <div className="flex justify-between w-full gap-2">
        {debtPostionResult?.isDebtPostionAvailable ? (
          <div className="relative flex flex-col items-center border border-borderColor rounded-lg p-2">
            <div className="flex items-center">
              <div className="text-textLabel font-semibold mr-1">Duration</div>
              <Tooltip
                placement="right"
                message="The amount of time left on your borrower position. This will be automatically readjusted with slashing."
              />
            </div>
            <div
              className="border border-grey150  rounded-lg text-sm py-2 px-4 my-4 mx-3 cursor-pointer"
              onClick={handleResetDuration}>
              Reset to {secondstoM(defaultDuration)}M
            </div>
            <div className="text-textLabel">{secondsToDHM(duration)}</div>
          </div>
        ) : (
          <div className="relative flex flex-col items-center border border-borderColor rounded-lg p-2">
            <div className="flex items-center">
              <div className="text-textLabel font-semibold mr-1">Duration</div>
              <Tooltip message="The length of the borrower's position. By default it is set at 12 months. If slashing occurs the duration of the borrowed position will readjust." />
            </div>
            <div className="text-sm py-2 px-4 my-4 mx-12" onClick={handleResetDuration}>
              {secondstoM(defaultDuration)}
            </div>
            <div className="text-textLabel">months</div>
          </div>
        )}
        <NumberAdjusterWithTextInput
          title="Max Liveness"
          value={maxLiveness}
          incrementValue={0.0001}
          // alignLeft={true}
          placement="right"
          label="ETH"
          className="w-1/3"
          disabled={borrowAmount === '0'}
          tooltip="Set the max slashing amount for liveness. It is considered a minor form of slashing. This is similar to attestations in Ethereum PoS. There can be multiple liveness rules per borrower position."
          setValue={setMaxLiveness}
        />
        <NumberAdjusterWithTextInput
          title="Max Corruption"
          value={maxCorruption}
          incrementValue={0.0001}
          // alignLeft={true}
          placement="right"
          label="ETH"
          className="w-1/3"
          disabled={borrowAmount === '0'}
          tooltip="Set the max slashing amount for Corruption. It is considered a major form of slashing. This is similar to slashings in Ethereum PoS. There can be multiple Corruption rules per borrower position."
          setValue={setMaxCorruption}
        />
      </div>

      <div className="pt-4 gap-2 flex flex-col">
        {debtPostionResult?.isDebtPostionAvailable && (
          <div className="w-full flex justify-between items-center">
            <div className="flex items-center gap-2">
              <img src={EthIcon} alt="eth-icon" />
              <p className="text-yellowAlert font-medium text-sm flex items-center gap-2">
                Outstanding interest amount
                <Tooltip
                  placement="bottom"
                  message="Amount currently owed due to borrowing time and slashing which will need to be repaid to update your position."
                />
              </p>
            </div>
            {interestResult && (
              <p className="text-sm text-yellowAlert font-medium">
                {`${roundNumber(bigToNum(interestResult?.outstandingInterest), 3)} kETH`}
              </p>
            )}
          </div>
        )}
        <div className="w-full flex justify-between items-center">
          <div className="flex items-center gap-2">
            <img src={EthIcon} alt="eth-icon" />
            <p className="text-textBase font-medium text-sm flex items-center gap-2">
              Interest Due
              <Tooltip
                placement="bottom"
                message="The amount required to pay ETH stakers over 12 months. Remember you can cancel the position anytime."
              />
            </p>
          </div>
          {interestResult?.interest && (
            <p className="text-sm text-textLabel font-medium">
              {borrowAmount === '0'
                ? '--'
                : `${roundNumber(bigToNum(interestResult?.interest), 3)} kETH`}
            </p>
          )}
        </div>
        {/* <div className="w-full flex justify-between items-center">
          <div className="flex items-center gap-2">
            <img src={EthIcon} alt="eth-icon" />
            <p className="text-textBase font-medium text-sm flex items-center gap-2">
              Fee
              <Tooltip message="The LSD Optimizer is free to use :)" />
            </p>
          </div>
          <p className="text-sm text-textLabel font-medium">
            {borrowAmount === '0' ? '--' : `$${fee}`}
          </p>
        </div> */}

        <Button
          disabled={
            !borrowAmount ||
            borrowAmount === '0' ||
            (isRSTActivated && rstValidationError.length > 0) ||
            Number(borrowAmount) > availableToBorrow ||
            maxLiveness >= maxLivenessUpperLimit ||
            maxCorruption >= maxCorruptionUpperLimit ||
            designatedVerifier === '' ||
            (debtPostionResult?.isDebtPostionAvailable &&
              (maxLiveness < debtPostionResult.maxSlashableAmountPerLiveness ||
                maxCorruption < debtPostionResult.maxSlashableAmountPerCorruption)) ||
            ((lsdTokenBalance.get(KETH.id) || ethers.BigNumber.from(0)).lt(
              interestResult?.interest || 0
            ) &&
              lsdTokenBalance.has(STETH.id) &&
              (lsdTokenBalance.get(STETH.id) || ethers.BigNumber.from(0)).lt(
                payAmountResult?.stETHpayAmount || 0
              ) &&
              lsdTokenBalance.has(DETH.id) &&
              (lsdTokenBalance.get(DETH.id) || ethers.BigNumber.from(0)).lt(
                payAmountResult?.dETHpayAmount || 0
              ) &&
              lsdTokenBalance.has(RETH.id) &&
              (lsdTokenBalance.get(RETH.id) || ethers.BigNumber.from(0)).lt(
                payAmountResult?.rETHpayAmount || 0
              ) &&
              lsdTokenBalance.has(ETH.id) &&
              (lsdTokenBalance.get(ETH.id) || ethers.BigNumber.from(0)).lt(
                payAmountResult?.ETHpayAmount || 0
              )) ||
            (debtPostionResult?.slashAmount !== undefined && debtPostionResult?.slashAmount > 0)
          }
          className="mt-4"
          onClick={handleBorrowClick}>
          {borrowBottonLabel}
        </Button>
      </div>
      <ConfirmModal
        title="Transaction Summary"
        message={
          <div className="px-6 pb-2">
            <div className="text-white pb-4">
              {!debtPostionResult?.isDebtPostionAvailable
                ? 'You are borrowing:'
                : 'You are extending your current position:'}
            </div>

            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <span className="text-white pr-2">Amount </span>
              </div>
              <span className="text-primaryGreen">
                {debtPostionResult?.isDebtPostionAvailable ? '+' : ''}
                {borrowAmount} ETH
              </span>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <span className="text-white pr-2">Duration </span>
              </div>
              <span className="text-primaryGreen">
                {!debtPostionResult?.isDebtPostionAvailable || resetDurationToSixMonths
                  ? '6 months'
                  : debtPostionResult?.durationString}{' '}
              </span>
            </div>

            <hr className="border-white my-4" />
            <div className="text-left">You need to pay</div>

            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <span className="text-white pr-2">Interest </span>
              </div>
              {interestResult?.interest && (
                <span className="text-primaryGreen">{`${roundNumber(
                  bigToNum(interestResult?.interest),
                  3
                )} kETH`}</span>
              )}
            </div>

            {debtPostionResult?.isDebtPostionAvailable && (
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <span className="text-white pr-2">Slashing Debt</span>
                  <Tooltip message="You must pay outstanding slashing debt prior to extending your position." />
                </div>
                {debtPostionResult?.isDebtPostionAvailable && (
                  <span className="text-yellowAlert">{`${roundNumber(
                    debtPostionResult?.slashAmount,
                    3
                  )} kETH`}</span>
                )}
              </div>
            )}
          </div>
        }
        confirmButtonContent="Confirm"
        onConfirm={handleBorrowConfirmationClick}
        onClose={handleConfirmModalClose}
        open={openConfirmModal}
        confirmButtonDisabled={
          debtPostionResult?.slashAmount !== undefined && debtPostionResult?.slashAmount > 0
        }
      />
      <ConfirmModal
        title="Transaction Summary"
        message={
          <div className="px-6 pb-2">
            <div className="text-white pb-4">
              Interests are usually paid in kETH but you can use your LSD tokens to pay for the
              interest.
            </div>
            <TextInput
              type="text"
              className={styles.dropdowninput}
              value={bigToNum(lsdTokenBalance.get(selectedLSDToken.id) || ethers.BigNumber.from(0))}
              disabled={true}
            />

            <div className="absolute top-60 right-16">
              <TokenSelectorDropdown
                setSelectedLSDToken={setSelectedLSDToken}
                lsdTokenBalance={lsdTokenBalance}>
                <div className="flex items-center space-x-2 rounded-full p-1 pr-2 bg-background300 text-textBase ">
                  <div
                    style={{
                      backgroundImage: `url(${selectedLSDToken.icon})`,
                      width: '16px',
                      height: '16px',
                      backgroundSize: 'cover'
                    }}></div>
                  <span>{selectedLSDToken.symbol}</span>
                  <ArrowDownIcon />
                </div>
              </TokenSelectorDropdown>
            </div>

            <div className="flex justify-between items-center mb-1">
              <div className="flex items-center">
                <span className="text-textLabel pr-2">Interest Due </span>
                <Tooltip message="Interest Due" />
              </div>
              {interestResult?.interest && (
                <>
                  <span className="text-white">{`${roundNumber(
                    bigToNum(interestResult?.interest),
                    3
                  )} kETH`}</span>
                </>
              )}
            </div>

            <div className="w-full flex justify-between items-center mb-2">
              <div className="flex items-center gap-2">
                <p className="text-textLabel font-medium text-sm flex items-center gap-2">
                  Fee
                  <Tooltip message="The LSD Optimizer is free to use :)" />
                </p>
              </div>
              <p className="text-sm text-white font-medium">${fee}</p>
            </div>
          </div>
        }
        confirmButtonContent="Confirm"
        onConfirm={handleLSDSelectorConfirmationClick}
        onClose={handleLSDTokenSelectModalClose}
        open={openLSDTokenSelectorModal}
        confirmButtonDisabled={lsdTokenBalance
          .get(selectedLSDToken.id)
          ?.lt(interestResult?.interest || 0)}
      />
      <LoadingModal
        open={
          isKETHApproving ||
          isDETHAppriving ||
          isRETHApproving ||
          isSTETHApproving ||
          isBorrowing ||
          isLoading
        }
        title={`Borrowing ${borrowAmount} ETH`}
        onClose={() => {}}
      />
      <ErrorModal
        open={!!failedBorrow}
        onClose={() => {
          setFailedBorrow(false)
        }}
        title="Error!"
        message={'It looks like something went wrong.'}
        actionButtonContent="Try Again"
        onAction={() => {
          setFailedBorrow(false)
        }}
      />
      <ModalDialog open={!!txResult} onClose={handleCloseSuccessModal}>
        <CompletedTxView
          goToContent="Home"
          title="Success"
          txLink={makeEtherscanLink(hash)}
          onGoToClick={handleGoToHome}
          message={<span className="text-sm text-grey300">{`You've successfully borrowed.`}</span>}
        />
      </ModalDialog>
    </div>
  )
}

const InputRightElement = styled.div`
  ${tw`absolute top-[55px] right-2 flex gap-3 items-center`}
`
