import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import { mainnet, useNetwork } from 'wagmi'

import ArrowIcon from '@/assets/images/icon-arrow-right-with-circle.svg'
import KETHIcon from '@/assets/images/icon-eth.svg'
import { useConfig, useK2BorrowSummaryValues } from '@/hooks'
import { useK2TVLandAPR } from '@/hooks/useK2TVLandAPR'
import { readablePrice, roundNumber } from '@/utils/global'

export const BorrowInfo = () => {
  const { result } = useK2BorrowSummaryValues()
  const { result: k2Data } = useK2TVLandAPR()
  const { chain: activeChain } = useNetwork()
  const config = useConfig()
  const isMainnet = activeChain?.id == mainnet.id && config.isPrivateMainnet == 'true'

  const navigate = useNavigate()

  return (
    <div className="flex gap-4 w-full mb-10">
      <div className="flex-1 p-4 bg-primary150 border border-borderColor rounded-2xl">
        <p className="text-textLabel pb-1.5">Available to Borrow</p>
        <div className="flex justify-between items-center mt-4">
          <Price>
            {isMainnet ? (
              <span className="text-xl">Restricted Info</span>
            ) : (
              <>${readablePrice(result?.availableToBorrow || 0)}</>
            )}

            <img
              src={ArrowIcon}
              alt="K2"
              className="w-6 cursor-pointer"
              onClick={() => navigate('/borrow')}
            />
          </Price>
          <div className="flex flex-col gap-2">
            <div className="min-w-32 flex items-center gap-3">
              <div className="flex gap-1 items-center">
                <img src={KETHIcon} alt="K2" className="w-4" />
                <p className="text-sm text-textBase">ETH</p>
              </div>
              <div className="flex gap-1 items-center">
                <p className="text-sm text-textLabel">
                  {isMainnet ? 'Restricted Info' : roundNumber(result?.kethTotalAvailable || 0, 3)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 p-4 flex flex-col  bg-primary150 border border-borderColor rounded-2xl">
        <p className="text-textLabel pb-1.5">TVL</p>
        <div className="flex justify-between items-center mt-4">
          <Price>
            {isMainnet ? (
              <span className="text-xl">Restricted Info</span>
            ) : (
              <>${readablePrice(k2Data?.tvl || 0)}</>
            )}
            <img
              src={ArrowIcon}
              alt="K2"
              className="w-6 cursor-pointer"
              onClick={() => navigate('/supply')}
            />
          </Price>
          <div className="flex flex-col gap-2">
            <div className="min-w-32 flex items-center gap-3">
              <div className="flex gap-1 items-center">
                <img src={KETHIcon} alt="K2" className="w-4" />
                <p className="text-sm text-textBase">ETH</p>
              </div>
              <div className="flex gap-1 items-center">
                <p className="text-sm text-textLabel">
                  {isMainnet ? 'Restricted Info' : roundNumber(k2Data?.totalETHBacking || 0, 3)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Price = tw.div`text-primaryGreen text-[40px] font-semibold flex items-center gap-4`
