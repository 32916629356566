import { goerli, mainnet } from 'wagmi'

import { holesky } from '../utils/holesky'

export type EnvConfig = {
  networkId: number
  dETHTokenAddress: string | undefined
  rETHTokenAddress: string | undefined
  stETHTokenAddress: string | undefined
  wstETHTokenAddress: string | undefined
  savETHTokenAddress: string | undefined
  kETHTokenAddress: string | undefined
  kwETHTokenAddress: string | undefined
  bsnTokenAddress: string | undefined
  gETHTokenAddress: string | undefined
  GRAPHQL_URL: string | undefined
  LSD_GRAPHQL_URL: string | undefined
  kETHStrategyContractAddress: string | undefined
  dETHVaultContractAddress: string | undefined
  bsnFarmingContractAddress: string | undefined
  kETHVaultContractAddress: string | undefined
  k2PoolContractAddress: string | undefined
  reporterRegistryAddress: string | undefined
  k2LendingDepositorAddress: string | undefined
  interestRateModelAddress: string | undefined
  partitionedLinearInterestRateModelAddress: string | undefined
  rstModuleContractAddress: string | undefined
  supplyInfo: string | undefined
  consensusLayerUrl: string | undefined
  isPrivateMainnet: string | undefined
}

export const envConfigs: { [key: number]: EnvConfig } = {
  [mainnet.id]: {
    networkId: mainnet.id,
    isPrivateMainnet: process.env.REACT_APP_IS_PRIVATE_MAINNET,
    dETHTokenAddress: process.env.REACT_APP_MAINNET_DETH_TOKEN_ADDRESS,
    rETHTokenAddress: process.env.REACT_APP_MAINNET_RETH_TOKEN_ADDRESS,
    stETHTokenAddress: process.env.REACT_APP_MAINNET_STETH_TOKEN_ADDRESS,
    wstETHTokenAddress: process.env.REACT_APP_MAINNET_WSTETH_TOKEN_ADDRESS,
    savETHTokenAddress: process.env.REACT_APP_MAINNET_SAVETH_TOKEN_ADDRESS,
    kETHTokenAddress: process.env.REACT_APP_MAINNET_KETH_TOKEN_ADDRESS,
    kwETHTokenAddress: process.env.REACT_APP_MAINNET_KWETH_TOKEN_ADDRESS,
    bsnTokenAddress: process.env.REACT_APP_MAINNET_BSN_TOKEN_ADDRESS,
    gETHTokenAddress: process.env.REACT_APP_MAINNET_GETH_TOKEN_ADDRESS,
    GRAPHQL_URL: process.env.REACT_APP_MAINNET_GRAPHQL_URL,
    LSD_GRAPHQL_URL: process.env.REACT_APP_MAINNET_LSD_GRAPHQL_URL,
    /////////////////
    kETHStrategyContractAddress: process.env.REACT_APP_MAINNET_KETH_STRATEGY_ADDRESS,
    dETHVaultContractAddress: process.env.REACT_APP_MAINNET_DETH_VAULT_ADDRESS,
    bsnFarmingContractAddress: process.env.REACT_APP_MAINNET_BSN_FARMING_ADDRESS,
    kETHVaultContractAddress: process.env.REACT_APP_MAINNET_KETH_VAULT_ADDRESS,
    k2PoolContractAddress: process.env.REACT_APP_MAINNET_K2POOL_ADDRESS,
    reporterRegistryAddress: process.env.REACT_APP_MAINNET_REPORTER_REGISTRY_ADDRESS,
    k2LendingDepositorAddress: process.env.REACT_APP_MAINNET_K2_LENDING_DEPOSITOR_ADDRESS,
    interestRateModelAddress: process.env.REACT_APP_MAINNET_INTEREST_RATE_MODEL_ADDRESS,
    partitionedLinearInterestRateModelAddress:
      process.env.REACT_APP_MAINNET_PARTITIONED_LINEAR_INTEREST_RATE_MODEL_ADDRESS,
    rstModuleContractAddress: process.env.REACT_APP_MAINNET_RST_MODULE_CONTRACT_ADDRESS,
    supplyInfo: process.env.REACT_APP_MAINNET_INFO,
    consensusLayerUrl: process.env.REACT_APP_MAINNET_CONSENSUS_LAYER_URL
  },
  [goerli.id]: {
    networkId: goerli.id,
    isPrivateMainnet: process.env.REACT_APP_IS_PRIVATE_MAINNET,
    dETHTokenAddress: process.env.REACT_APP_GOERLI_DETH_TOKEN_ADDRESS,
    rETHTokenAddress: process.env.REACT_APP_GOERLI_RETH_TOKEN_ADDRESS,
    stETHTokenAddress: process.env.REACT_APP_GOERLI_STETH_TOKEN_ADDRESS,
    wstETHTokenAddress: process.env.REACT_APP_GOERLI_WSTETH_TOKEN_ADDRESS,
    savETHTokenAddress: process.env.REACT_APP_GOERLI_SAVETH_TOKEN_ADDRESS,
    kETHTokenAddress: process.env.REACT_APP_GOERLI_KETH_TOKEN_ADDRESS,
    kwETHTokenAddress: process.env.REACT_APP_GOERLI_KWETH_TOKEN_ADDRESS,
    bsnTokenAddress: process.env.REACT_APP_GOERLI_BSN_FARMING_ADDRESS,
    gETHTokenAddress: process.env.REACT_APP_GOERLI_GETH_TOKEN_ADDRESS,
    GRAPHQL_URL: process.env.REACT_APP_GOERLI_GRAPHQL_URL,
    LSD_GRAPHQL_URL: process.env.REACT_APP_LSD_GRAPHQL_URL,
    /////////////////
    kETHStrategyContractAddress: process.env.REACT_APP_GOERLI_KETH_STRATEGY_ADDRESS,
    dETHVaultContractAddress: process.env.REACT_APP_GOERLI_DETH_VAULT_ADDRESS,
    bsnFarmingContractAddress: process.env.REACT_APP_GOERLI_BSN_FARMING_ADDRESS,
    kETHVaultContractAddress: process.env.REACT_APP_GOERLI_KETH_VAULT_ADDRESS,
    k2PoolContractAddress: process.env.REACT_APP_GOERLI_K2POOL_ADDRESS,
    reporterRegistryAddress: process.env.REACT_APP_GOERLI_REPORTER_REGISTRY_ADDRESS,
    k2LendingDepositorAddress: process.env.REACT_APP_GOERLI_K2_LENDING_DEPOSITOR_ADDRESS,
    interestRateModelAddress: process.env.REACT_APP_GOERLI_INTEREST_RATE_MODEL_ADDRESS,
    partitionedLinearInterestRateModelAddress:
      process.env.REACT_APP_GOERLI_PARTITIONED_LINEAR_INTEREST_RATE_MODEL_ADDRESS,
    supplyInfo: process.env.REACT_APP_GOERLI_INFO,
    rstModuleContractAddress: process.env.REACT_APP_GOERLI_RST_MODULE_CONTRACT_ADDRESS,
    consensusLayerUrl: process.env.REACT_APP_GOERLI_CONSENSUS_LAYER_URL
  },
  [holesky.id]: {
    networkId: holesky.id,
    isPrivateMainnet: process.env.REACT_APP_IS_PRIVATE_MAINNET,
    dETHTokenAddress: process.env.REACT_APP_HOLESKY_DETH_TOKEN_ADDRESS,
    rETHTokenAddress: process.env.REACT_APP_HOLESKY_RETH_TOKEN_ADDRESS,
    stETHTokenAddress: process.env.REACT_APP_HOLESKY_STETH_TOKEN_ADDRESS,
    wstETHTokenAddress: process.env.REACT_APP_HOLESKY_WSTETH_TOKEN_ADDRESS,
    savETHTokenAddress: process.env.REACT_APP_HOLESKY_SAVETH_TOKEN_ADDRESS,
    kETHTokenAddress: process.env.REACT_APP_HOLESKY_KETH_TOKEN_ADDRESS,
    kwETHTokenAddress: process.env.REACT_APP_HOLESKY_KWETH_TOKEN_ADDRESS,
    bsnTokenAddress: process.env.REACT_APP_HOLESKY_BSN_FARMING_ADDRESS,
    gETHTokenAddress: process.env.REACT_APP_HOLESKY_GETH_TOKEN_ADDRESS,
    GRAPHQL_URL: process.env.REACT_APP_HOLESKY_GRAPHQL_URL,
    LSD_GRAPHQL_URL: process.env.REACT_APP_LSD_GRAPHQL_URL,
    /////////////////
    kETHStrategyContractAddress: process.env.REACT_APP_HOLESKY_KETH_STRATEGY_ADDRESS,
    dETHVaultContractAddress: process.env.REACT_APP_HOLESKY_DETH_VAULT_ADDRESS,
    bsnFarmingContractAddress: process.env.REACT_APP_HOLESKY_BSN_FARMING_ADDRESS,
    kETHVaultContractAddress: process.env.REACT_APP_HOLESKY_KETH_VAULT_ADDRESS,
    k2PoolContractAddress: process.env.REACT_APP_HOLESKY_K2POOL_ADDRESS,
    reporterRegistryAddress: process.env.REACT_APP_HOLESKY_REPORTER_REGISTRY_ADDRESS,
    k2LendingDepositorAddress: process.env.REACT_APP_HOLESKY_K2_LENDING_DEPOSITOR_ADDRESS,
    interestRateModelAddress: process.env.REACT_APP_HOLESKY_INTEREST_RATE_MODEL_ADDRESS,
    partitionedLinearInterestRateModelAddress:
      process.env.REACT_APP_HOLESKY_PARTITIONED_LINEAR_INTEREST_RATE_MODEL_ADDRESS,
    supplyInfo: process.env.REACT_APP_HOLESKY_INFO,
    rstModuleContractAddress: process.env.REACT_APP_HOLESKY_RST_MODULE_CONTRACT_ADDRESS,
    consensusLayerUrl: process.env.REACT_APP_HOLESKY_CONSENSUS_LAYER_URL
  }
}

// export const config = envConfigs[Number(process.env.REACT_APP_NETWORK_ID!)]
