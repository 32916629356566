import { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  CompletedTxView,
  ErrorModal,
  LoadingModal,
  ModalDialog,
  TextInputWithSelector
} from '@/components/shared'
import { FarmingModeT } from '@/constants/farmingModes'
import {
  useK2Withdraw,
  useK2WithdrawableAmount,
  useMakeRealTxHash,
  useNetworkBasedLinkFactories,
  useRemainedLockUp,
  useTokens
} from '@/hooks'
import { convertDateToString, roundNumber } from '@/utils/global'

import { TAB } from './index'
import styles from './styles.module.scss'

interface WithdrawSectionProps {
  selectedMode: FarmingModeT
  setActiveTab: Dispatch<SetStateAction<TAB>>
}

const WITHDRAW_PERCENTAGE = [
  { id: 0, value: 0.25, label: '25%' },
  { id: 1, value: 0.5, label: '50%' },
  { id: 2, value: 0.75, label: '75%' },
  { id: 3, value: 1, label: 'MAX' }
]

export const WithdrawSection = ({ selectedMode, setActiveTab }: WithdrawSectionProps) => {
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const [withdrawable, setWithdrawable] = useState(false)
  const [failedWithdraw, setFailedWithdraw] = useState(false)

  const navigate = useNavigate()
  const { KETH } = useTokens()
  const { remainedLockUp } = useRemainedLockUp(selectedMode)
  const { withdrawableAmount, fetchWithdrawableAmount } = useK2WithdrawableAmount()
  const { onWithdraw, isWithdrawing, txResult, onClear } = useK2Withdraw()
  const { hash } = useMakeRealTxHash(txResult?.hash)
  const { makeEtherscanLink } = useNetworkBasedLinkFactories()

  useEffect(() => {
    if (remainedLockUp > 0) {
      setWithdrawable(false)
    } else {
      setWithdrawable(true)
    }
  }, [remainedLockUp])

  const handleChangeWithdrawAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (!isNaN(Number(value)) && Number(value) <= Number(withdrawableAmount)) {
      setWithdrawAmount(value)
    }
  }

  const handleWithdraw = async () => {
    const wasSucceed = await onWithdraw(withdrawAmount)

    if (!wasSucceed) setFailedWithdraw(true)
    setWithdrawAmount('')
    fetchWithdrawableAmount()
  }

  const handleCloseSuccessModal = () => {
    onClear()
  }
  const handleGoToHome = () => {
    navigate('/')
  }
  const handleGoStakePage = () => {
    setActiveTab(TAB.DEPOSIT)
  }

  const isValidAmount = useMemo(() => {
    if (withdrawAmount && Number(withdrawAmount) > 0) return true

    return false
  }, [withdrawAmount])

  return (
    <div className="px-2 py-4 flex flex-col">
      <div className="relative">
        <TextInputWithSelector
          label="Withdraw Amount"
          type="text"
          selectorList={WITHDRAW_PERCENTAGE}
          className={styles.input}
          pattern="(\.\d{0,1}|\d*\.\d{0,2})"
          value={withdrawAmount}
          setAmount={setWithdrawAmount}
          maxAmount={withdrawableAmount}
          onChange={handleChangeWithdrawAmount}
          enabled={withdrawable}
        />

        <div className="absolute top-14 right-2 flex gap-3 items-center">
          <div className="rounded-full p-1 pr-2 bg-background400 flex items-center gap-2">
            <img src={KETH.icon} alt="token_icon" className="w-6" />
            <p className="text-textBase text-xl font-medium leading-5">{KETH.symbol}</p>
          </div>
        </div>
      </div>
      {withdrawable ? (
        <>
          <div>
            <p className="text-textLabel text-sm text-right">
              Available to withdraw: {roundNumber(Number(withdrawableAmount), 2)} {KETH.symbol}
            </p>
          </div>
          <div className="py-4 flex flex-row gap-4">
            <Button
              className="flex-1 mt-4"
              disabled={isWithdrawing || !isValidAmount}
              onClick={handleWithdraw}>
              {isWithdrawing ? 'Withdrawing' : 'Confirm'}
            </Button>
          </div>
        </>
      ) : selectedMode && selectedMode.route == 'k2' ? (
        <div className="rounded-2xl bg-primary100 px-6 py-4 flex flex-col gap-4">
          <div>
            <p className="text-primary500 text-center">
              Your kETH are Locked for {convertDateToString(remainedLockUp)} more.
            </p>
            <p className="text-primary500 text-center">Want to earn while waiting?</p>
          </div>
          <p className="text-white">
            You can earn more by depositing your kETH into the pool and earning BSN.
          </p>
          <Button onClick={handleGoStakePage}>Earn More</Button>
          {/* <a
            className="text-sm text-grey700 text-center underline cursor-pointer"
            onClick={handleAllowWithdrawable}>
            Simulate {convertDateToString(remainedLockUp)} have passed
          </a> */}
        </div>
      ) : (
        <div className="rounded-2xl bg-primary100 px-6 py-4 flex flex-col gap-4">
          <div>
            <p className="text-primary500 text-center">
              kwETH is locked for {convertDateToString(remainedLockUp)}.
            </p>
          </div>
        </div>
      )}
      <LoadingModal open={isWithdrawing} title="Confirmation Pending" onClose={() => {}} />
      <ErrorModal
        open={!!failedWithdraw}
        onClose={() => setFailedWithdraw(false)}
        title="Error!"
        message={'It looks like something went wrong.'}
        actionButtonContent="Try Again"
        onAction={() => setFailedWithdraw(false)}
      />
      <ModalDialog open={!!txResult} onClose={handleCloseSuccessModal}>
        <CompletedTxView
          goToContent="Home"
          title="Success"
          txLink={makeEtherscanLink(hash)}
          onGoToClick={handleGoToHome}
          message={
            <span className="text-sm text-grey300">{`You've successfully withdrawn ETH.`}</span>
          }
        />
      </ModalDialog>
    </div>
  )
}
