import './styles.module.scss'

import { Menu, Transition } from '@headlessui/react'
import { ethers } from 'ethers'
import { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react'

import { TokenT, useTokens } from '@/hooks'
import { bigToNum, truncateToThreeDecimals } from '@/utils/global'

interface IProps {
  lsdTokenBalance: Map<number, ethers.BigNumber>
  setSelectedLSDToken: Dispatch<SetStateAction<TokenT>>
}

export const TokenSelectorDropdown: FC<PropsWithChildren<IProps>> = ({
  children,
  lsdTokenBalance,
  setSelectedLSDToken
}) => {
  const { LSD_TOKEN_SELECTOR_OPTIONS } = useTokens()
  return (
    <Menu as="div" className="relative" style={{ height: 38 }}>
      <Menu.Button>{children}</Menu.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0">
        <Menu.Items className="menu__popup">
          {LSD_TOKEN_SELECTOR_OPTIONS.map((token: TokenT) => (
            <div key={token.symbol}>
              <Menu.Item>
                {({ active, disabled }) => (
                  <div
                    className={`${active ? 'menu__item--selected' : 'menu__item'}
                       ${disabled ? 'disabled' : ''}`}
                    onClick={() => setSelectedLSDToken(token)}>
                    <span className="icon-label-row">
                      <div
                        style={{
                          backgroundImage: `url(${token.icon})`,
                          width: '16px',
                          height: '16px',
                          backgroundSize: 'cover'
                        }}></div>
                      <span className="label">{token.symbol}</span>
                      <span className="label">
                        {truncateToThreeDecimals(
                          bigToNum(lsdTokenBalance.get(token.id) || ethers.BigNumber.from(0))
                        )}
                      </span>
                    </span>
                  </div>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
