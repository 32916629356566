import 'twin.macro'

import { FC, forwardRef, InputHTMLAttributes, LegacyRef, ReactNode } from 'react'

import { CloseIcon } from '@/components/shared'

import { Tooltip } from '../Tooltip'
import styles from './styles.module.scss'

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode
  tooltip?: ReactNode
  clearInput?: () => void
}

const TextInputWithInsideLabel = (
  { label, tooltip, disabled, clearInput, value, ...otherProps }: IProps,
  ref: LegacyRef<HTMLInputElement>
) => {
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.background} ${disabled ? styles.disabled : ''}`}></div>
      <div className={`${styles.labelTooltipContainer} ${disabled ? styles.disabled : ''}`}>
        {label && (
          <label className={`${styles.insideLabel} ${disabled ? styles.disabled : ''}`}>
            {label}
          </label>
        )}
        {tooltip && <Tooltip message={tooltip} />}
      </div>
      {value && (
        <div className="absolute right-4 bottom-3 cursor-pointer z-10" onClick={clearInput}>
          <CloseIcon />
        </div>
      )}
      <input
        {...otherProps}
        value={value}
        ref={ref}
        className={`${styles.input} ${otherProps.className}`}
        disabled={disabled}
      />
    </div>
  )
}

export default forwardRef(TextInputWithInsideLabel)
