import 'twin.macro'

import { useQuery } from '@apollo/client'
import { formatEther } from 'ethers/lib/utils.js'
import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as ArrowTopRightIcon } from '@/assets/images/icon-arrow-top-right.svg'
import { ReactComponent as EarnedRCIcon } from '@/assets/images/icon-earned-rc.svg'
import { ReactComponent as PlusIcon } from '@/assets/images/icon-plus.svg'
import { Button, ClipboardCopy, Tooltip } from '@/components/shared/'
import { NODE_CLOUD_TAB } from '@/constants'
import { K2TotalDelegationsInETHQuery, K2ValidatorsQuery } from '@/graphql/queries/NodeRunners'
import { useCustomAccount, useNetworkBasedLinkFactories } from '@/hooks'
import { paginate } from '@/utils/global'
import { humanReadableAddress, makeBeaconLink } from '@/utils/global'

import Pagination from '../../shared/Pagination'
import ModalConfirmLearnDelegate from '../Modals/ModalConfirmLearnDelegate'
import ModalNativeDelegation from '../Modals/ModalNativeDelegation'
import styles from './styles.module.scss'

type IProps = {
  activeTab: NODE_CLOUD_TAB
  validators: any[]
  loading: boolean
}

export const CloudTable: FC<IProps> = ({ activeTab, validators, loading }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number | string>(1)
  const [totalPages, setTotalPages] = useState<number>(0)

  const navigate = useNavigate()
  const { makeEtherscanLink } = useNetworkBasedLinkFactories()

  const { account } = useCustomAccount()

  const handlePageChange = (pageNumber: number | string) => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [activeTab])

  const tableHeader =
    activeTab === NODE_CLOUD_TAB.DASHBOARD
      ? ['Preferred Node Operator', 'ETH Delegated']
      : activeTab === NODE_CLOUD_TAB.MY_DELEGATION
      ? ['PNO Name', 'Delegated K2 ETH', 'RC Points']
      : ['Address', 'ETH Received']

  useEffect(() => {
    const length = validators ? validators.length : 0
    setTotalPages(Math.ceil(length / 5))
  }, [validators])

  const tableData = useMemo(() => {
    if (validators && validators.length > 0) return paginate(validators, 5, currentPage)

    return []
  }, [currentPage, validators])

  const handleLearn = () => {
    window.open(
      'https://docs.restaking.cloud/middleware/tutorials/install-native-delegation',
      '_blank'
    )
    setIsOpen(false)
    // setIsConfirmModalOpen(true)
    // setTimeout(() => {
    //   setIsConfirmModalOpen(false)
    //   navigate('/supply')
    // }, 2000)
  }

  return (
    <div className={styles.table}>
      <div className="flex items-center justify-between pb-6">
        <div className={styles.title}>
          {activeTab === NODE_CLOUD_TAB.DASHBOARD
            ? 'Available Preferred Node Operators'
            : activeTab === NODE_CLOUD_TAB.MY_DELEGATION
            ? 'Delegation to'
            : 'Delegations received'}
        </div>
      </div>

      <table className={styles['auto-table']}>
        <thead className={styles.thead}>
          <tr className={`text-xs flex w-full border-b border-t border-grey750`}>
            {tableHeader.map((header, idx) => (
              <th
                key={idx}
                className={`font-medium py-5 text-grey150 text-left w-full ${
                  !idx ? 'flex items-center text-left ml-4 mr-56' : 'text-center'
                }`}>
                <div className="inline-flex items-center">
                  <span className="mr-2">{header}</span>
                  {idx === 0 ? <Tooltip message="Validator Public Key" /> : ''}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={`${styles.tbody} max-h-80 overflow-y-auto`}>
          {tableData && tableData.length === 0 && !loading && (
            <tr className="w-full flex">
              <td colSpan={2} className="w-full">
                <div className="w-full py-5 text-center">No Data</div>
              </td>
            </tr>
          )}
          {tableData &&
            tableData.length > 0 &&
            tableData.map((item: any, index: number) => (
              <tr key={index} className={styles.tr}>
                <td className={`${styles.td} ${styles['txn-cell']}`}>
                  <span className="pr-2 text-sm">
                    {item.name ? item.name : humanReadableAddress(item.id)}
                  </span>

                  {item.id && (
                    <>
                      <ClipboardCopy copyText={item.id} />
                      <a
                        href={makeEtherscanLink(item.id, false, true)}
                        target="_blank"
                        rel="noreferrer"
                        className={'pl-1'}>
                        <ArrowTopRightIcon />
                      </a>
                    </>
                  )}
                </td>

                <td className="font-medium py-5 w-full text-center text-sm">
                  {Number(
                    formatEther(
                      activeTab === NODE_CLOUD_TAB.MY_PNO
                        ? item.delegatedAmountInETH ?? 0
                        : item.totalDelegationsInETH ?? 0
                    )
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 3
                  })}
                </td>
                {activeTab === NODE_CLOUD_TAB.MY_DELEGATION && (
                  <td className="font-medium w-full py-5 text-center text-sm">
                    <div className="flex items-center justify-center gap-2">
                      - <EarnedRCIcon className="w-4 h-4" />
                    </div>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
        {tableData && tableData.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </table>
      <ModalNativeDelegation
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={handleLearn}
      />
      <ModalConfirmLearnDelegate
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    </div>
  )
}
