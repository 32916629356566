import { useContext, useState } from 'react'

import { ContractContext } from '@/context/ContractContext'
import { withdrawK2Share } from '@/utils/contractFunctions'
import { notifyHash } from '@/utils/global'

import { useConfig } from './useConfig'
import { useCustomAccount } from './useCustomAccount'

export const useK2Withdraw = () => {
  const { k2PoolContract } = useContext(ContractContext)
  const { account, isGnosis } = useCustomAccount()
  const config = useConfig()

  const [txResult, setTxResult] = useState<any>()
  const [isWithdrawing, setIsWithdrawing] = useState(false)

  const onWithdraw = async (amount: string) => {
    if (!k2PoolContract || !account) return

    setIsWithdrawing(true)
    let tx = await withdrawK2Share(k2PoolContract, amount)

    if (tx) {
      if (!isGnosis) notifyHash(tx.hash, config.networkId)
      await tx.wait()
      setTxResult(tx)
      setIsWithdrawing(false)
      return true
    } else {
      setIsWithdrawing(false)
      return false
    }
  }

  const onClear = () => setTxResult(undefined)

  return { onWithdraw, onClear, isWithdrawing, txResult }
}
