import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils.js'
import { useCallback, useContext, useEffect, useState } from 'react'

import { ContractContext } from '@/context/ContractContext'
import { bigToNum, handleErr, notifyHash, noty } from '@/utils/global'

import { BEACON_NODE_URL } from '../constants/chains'
import { useConfig } from './useConfig'
import { useCustomAccount } from './useCustomAccount'
import { useSDK } from './useSDK'

export type kETHEarningsT = {
  kETHEarnings: string
  kETHEarningsUSD: number
}

export const useK2kETHEarnings = () => {
  const { account, isGnosis, ethPrice } = useCustomAccount()
  const { k2SDK: sdk } = useSDK()
  const config = useConfig()
  const { k2PoolContract, kETHVaultContract } = useContext(ContractContext)

  const [result, setResult] = useState<kETHEarningsT>()
  const [txResults, setTxResults] = useState<any[]>([])
  const [isClaiming, setIsClaiming] = useState(false)

  const fetchData = async () => {
    try {
      const kETHEarningBN: BigNumber = await k2PoolContract?.callStatic['claimableKETH(address)'](
        account.address
      )

      const kethEarningsAsNodeOperator = await sdk?.utils.getClaimableKETHForNodeOperator(
        account.address
      )

      const kethEarningAsNodeOperator = kethEarningsAsNodeOperator.reduce(
        (sum: BigNumber, current: BigNumber) => {
          return sum.add(current)
        },
        BigNumber.from('0')
      )

      const kETHValutTotalSupply: BigNumber = await kETHVaultContract?.totalSupply()
      const kETHValutTotalAssets: BigNumber = await kETHVaultContract?.totalAssets()
      const kETHTotalBN = kethEarningAsNodeOperator.add(kETHEarningBN)
      const ethValue = bigToNum(kETHTotalBN.mul(kETHValutTotalAssets).div(kETHValutTotalSupply))
      const kETHEarningsUSD = ethValue * ethPrice

      setResult({
        kETHEarnings: formatEther(kETHTotalBN),
        kETHEarningsUSD
      })
    } catch (err) {
      console.log('useK2kETHEarnings:k2PoolContract, kETHVault reading err: ', err)
    }
  }

  useEffect(() => {
    if (k2PoolContract && kETHVaultContract && account && sdk) fetchData()
  }, [k2PoolContract, kETHVaultContract, account, sdk])

  const onClaim = async () => {
    setIsClaiming(true)
    let success = false
    let _txResults: any[] = []

    try {
      const kETHEarningBN: BigNumber = await k2PoolContract?.callStatic['claimableKETH(address)'](
        account.address
      )

      const kethEarningsAsNodeOperator = await sdk?.utils.getClaimableKETHForNodeOperator(
        account.address
      )

      const kethEarningAsNodeOperator = kethEarningsAsNodeOperator.reduce(
        (sum: BigNumber, current: BigNumber) => {
          return sum.add(current)
        },
        BigNumber.from('0')
      )
      if (kETHEarningBN.gt(BigNumber.from('0'))) {
        const tx = await k2PoolContract?.['claimKETH(address)'](account.address)
        if (tx) {
          if (!isGnosis) notifyHash(tx.hash, config.networkId)
          await tx.wait()
          _txResults = [..._txResults, { name: 'Normal Deposit Earning Claim', tx }]
        }
      }

      if (kethEarningAsNodeOperator.gt(BigNumber.from('0'))) {
        const tx = await sdk?.utils.claimEffectiveBalance(BEACON_NODE_URL, account.address)
        if (tx) {
          if (!isGnosis) notifyHash(tx.hash, config.networkId)
          await tx.wait()
          _txResults = [..._txResults, { name: 'Node Operator Earning Claim', tx }]
        }
      }

      success = true

      setTxResults(_txResults)
    } catch (err) {
      console.log('Claim error: ', err)
      success = false
      noty(handleErr(err, 'Something went wrong. Claiming failed.'))
    }

    setIsClaiming(false)
    return success
  }

  const onClear = () => {
    setTxResults([])
  }

  return { result, onClaim, onClear, isClaiming, txResults }
}
