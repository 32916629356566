import { useCallback, useContext, useEffect, useState } from 'react'

import { ContractContext } from '@/context/ContractContext'
import { bigToNum, formatDuration, numToBig } from '@/utils/global'

import { useCustomAccount } from './useCustomAccount'

export type DebtPostionT = {
  isDebtPostionAvailable: boolean
  designatedVerifier: string
  principalAmount: number
  slashAmount: number
  duration: number
  durationString: string
  maxSlashableAmountPerLiveness: number
  maxSlashableAmountPerCorruption: number
}

export const useK2DebtPostion = () => {
  const { account, isRefetch } = useCustomAccount()
  const { k2PoolContract } = useContext(ContractContext)

  const [result, setResult] = useState<DebtPostionT>()
  const [defaultDuration, setDefaultDuration] = useState<number>(0)

  const fetchData = async () => {
    if (k2PoolContract && account) {
      try {
        const debtPostion = await k2PoolContract.getDebtor(account.address)
        const isDebtPostionAvailable =
          debtPostion.debtor.toLowerCase() === account.address.toLowerCase()

        const currentUnixTimestamp: number = Math.floor(Date.now() / 1000)
        const duration = debtPostion.endTimestamp - currentUnixTimestamp
        const durationString = formatDuration(debtPostion.endTimestamp, currentUnixTimestamp)

        const principalAmount = bigToNum(debtPostion.principalAmount)
        const slashAmount = bigToNum(debtPostion.slashAmount)
        const designatedVerifier = debtPostion.designatedVerifier
        const maxSlashableAmountPerLiveness = bigToNum(debtPostion.maxSlashableAmountPerLiveness)
        const maxSlashableAmountPerCorruption = bigToNum(
          debtPostion.maxSlashableAmountPerCorruption
        )

        const defaultDuration = await k2PoolContract.borrowDuration()
        setDefaultDuration(defaultDuration.toNumber())

        setResult({
          isDebtPostionAvailable,
          designatedVerifier,
          principalAmount,
          slashAmount,
          duration,
          durationString,
          maxSlashableAmountPerLiveness,
          maxSlashableAmountPerCorruption
        })
      } catch (err) {
        console.log('k2PoolContract reading err: ', err)
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [k2PoolContract, account, isRefetch])

  return { result, defaultDuration, fetchData }
}
