import { BigNumber, constants, ethers } from 'ethers'
import { useCallback, useEffect, useState } from 'react'
import { useSigner } from 'wagmi'

import { getERCToken, notifyHash } from '@/utils/global'

import { useConfig } from './useConfig'
import { useCustomAccount } from './useCustomAccount'

export const useAllowanceCheck = (
  tokenAddress: string | undefined,
  sender: string,
  disabled: boolean = false
) => {
  const [allowance, setAllowance] = useState<BigNumber>()
  const [isApproving, setApproving] = useState(false)

  const { data: signer } = useSigner()
  const { account, isGnosis } = useCustomAccount()
  const config = useConfig()

  const fetchAllowance = useCallback(async () => {
    if (signer && account && tokenAddress && sender) {
      const tokenContract = getERCToken(signer, tokenAddress)

      if (tokenContract) {
        try {
          const allowance = await tokenContract.allowance(account.address, sender)
          setAllowance(allowance)
        } catch (err) {
          console.log('fetchAllowance error: ', err)
        }
      }
    }
  }, [tokenAddress, sender, signer, account])

  useEffect(() => {
    if (!disabled) fetchAllowance()
  }, [signer, account, tokenAddress, sender, disabled])

  const handleApproveToken = async (amount: BigNumber) => {
    if (signer && tokenAddress) {
      const tokenContract = getERCToken(signer, tokenAddress)

      if (tokenContract) {
        setApproving(true)
        try {
          const tx = await tokenContract.approve(sender, amount)
          if (!isGnosis) notifyHash(tx.hash, config.networkId)
          await tx.wait()
          await fetchAllowance()
          setApproving(false)
          return false
        } catch (err) {
          setApproving(false)
          console.log('handleApproveToken error: ', err)
          return true
        }
      }
    }

    return true
  }

  return { allowance, isApproving, handleApproveToken }
}
