import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { ACTIVITY_TYPE, ACTIVITY_TYPE_DISPLAY } from '@/constants/activity'
import { ActivityQuery } from '@/graphql/queries/ActivityQuery'
import { bigToNum, secondsSince, timeDifference } from '@/utils/global'

import { useCustomAccount } from './useCustomAccount'

export type Transaction = {
  txnHash: string
  ageAgo: string
  amount: number | string
  secondsSince: number
  type: string
}

export const useK2Activites = () => {
  const { account } = useCustomAccount()
  const [activitiesForHomePage, setActivitiesForHomePage] = useState<Transaction[]>([])
  const [activitiesForClaimPage, setActivitiesForClaimPage] = useState<Transaction[]>([])

  const {
    loading,
    data: { events: transactions } = {},
    refetch
  } = useQuery(ActivityQuery, {
    variables: { account: account?.address ? account.address.toLowerCase() : '' },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !account
  })

  useEffect(() => {
    if (transactions === undefined) return
    const transactionsForHomePage = transactions
      .filter((tx: any) => {
        return (
          tx.type === ACTIVITY_TYPE.K2Deposit ||
          tx.type === ACTIVITY_TYPE.K2Withdraw ||
          tx.type === ACTIVITY_TYPE.K2NodeOperatorClaim ||
          tx.type === ACTIVITY_TYPE.K2Claim
        )
      })
      .map((tx: any) => {
        return {
          txnHash: tx.tx,
          amount: bigToNum(tx.type === 'K2.NodeOperatorClaim' ? tx.values[2] : tx.values[1]),
          ageAgo: timeDifference(tx.blockTimestamp),
          secondsSince: secondsSince(tx.blockTimestamp),
          type: ACTIVITY_TYPE_DISPLAY[tx.type as ACTIVITY_TYPE]
        }
      })
    const transactionsForClaimPage = transactions
      .filter((tx: any) => {
        return tx.type === ACTIVITY_TYPE.K2NodeOperatorClaim || tx.type === ACTIVITY_TYPE.K2Claim
      })
      .map((tx: any) => {
        return {
          txnHash: tx.tx,
          amount: bigToNum(tx.type === 'K2.NodeOperatorClaim' ? tx.values[2] : tx.values[1]),
          ageAgo: timeDifference(tx.blockTimestamp),
          secondsSince: secondsSince(tx.blockTimestamp),
          type: ACTIVITY_TYPE_DISPLAY[tx.type as ACTIVITY_TYPE]
        }
      })
    setActivitiesForHomePage(transactionsForHomePage)
    setActivitiesForClaimPage(transactionsForClaimPage)
  }, [transactions])

  return { loading, transactions, activitiesForHomePage, activitiesForClaimPage, refetch }
}
