import { BigNumber } from 'ethers'
import { useContext, useState } from 'react'

import { ContractContext } from '@/context/ContractContext'
import { handleErr, notifyHash, noty } from '@/utils/global'

import { useConfig } from './useConfig'
import { useCustomAccount } from './useCustomAccount'

export const useK2TopUpSlashAmount = () => {
  const { k2PoolContract } = useContext(ContractContext)
  const { account, isGnosis } = useCustomAccount()
  const config = useConfig()

  const [txResult, setTxResult] = useState<any>()
  const [isTopingUp, setIsTopingUp] = useState<boolean>(false)

  const onTopUp = async (amount: BigNumber) => {
    if (!k2PoolContract) return

    setIsTopingUp(true)
    let tx
    try {
      tx = await k2PoolContract.topUpSlashAmount(account.address, amount)
      if (tx) {
        if (!isGnosis) notifyHash(tx.hash, config.networkId)
        await tx.wait()
        setTxResult(tx)
      }
    } catch (err) {
      console.log('Top up slash amount error: ', err)
      setIsTopingUp(false)
      noty(handleErr(err, 'Something went wrong. Depositing token failed.'))
      return false
    }

    setIsTopingUp(false)

    return true
  }

  const onClear = () => {
    setTxResult(undefined)
  }

  return { onTopUp, onClear, isTopingUp, txResult }
}
