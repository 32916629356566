import { ethers } from 'ethers'
import { FC, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { mainnet, useNetwork } from 'wagmi'

import { ReactComponent as YellowAlertIcon } from '@/assets/images/icon-alert-yellow.svg'
import { ReactComponent as RightArrowWithCircleIcon } from '@/assets/images/icon-arrow-right-with-circle.svg'
import { ReactComponent as TopRightYellowIcon } from '@/assets/images/icon-arrow-top-right-yellow.svg'
import { ReactComponent as EmptyPieChartIcon } from '@/assets/images/icon-pie-chart-empty.svg'
import { ReactComponent as GreenPieChartIcon } from '@/assets/images/icon-pie-chart-green.svg'
import { ReactComponent as WhitePieChartIcon } from '@/assets/images/icon-pie-chart-white.svg'
import { ReactComponent as TrendUpIcon } from '@/assets/images/icon-trend-up-arrow.svg'
import { ModalSlashDebtRepayment } from '@/components/app/Modals'
import { Button } from '@/components/shared'
import { useConfig, useK2kETHEarnings, useK2PositionSummaryValues } from '@/hooks'
import { formatNumber, readablePrice } from '@/utils/global'

import { SummaryValueT } from '../../../hooks/useK2PositionSummary'

export const HeaderSummary: FC<SummaryValueT> = ({
  kETHBorrowed,
  kETHDeposited,
  kETHSlash,
  kETHSlashAmount,
  userDebtPosition,
  userLenderPosition
}) => {
  const navigate = useNavigate()
  const { result: earningsResult } = useK2kETHEarnings()
  const [fee, setFee] = useState(0)
  const [rewardPercentage, setRewardPercentage] = useState(3.4)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const { chain: activeChain } = useNetwork()
  const config = useConfig()
  const isMainnet = activeChain?.id == mainnet.id && config.isPrivateMainnet == 'true'

  const handleGoToSupply = () => {
    navigate('/supply')
  }

  const handleGoToBorrow = () => {
    navigate('/borrow')
  }

  const handleGoToClaim = () => {
    navigate('/claim')
  }

  const handleConfirmModalOpen = () => {
    setOpenConfirmModal(true)
  }

  return (
    <>
      <div className="flex gap-4 w-full mb-10">
        <div className="flex flex-1 flex-row items-center gap-4 p-4 bg-background100 border border-borderColor rounded-2xl">
          {userDebtPosition ? (
            <>
              <WhitePieChartIcon />
              <div className="flex-1 flex flex-col p-4">
                <p className="text-textBase pb-1.5 font-semibold">Stake Borrower Position</p>
                <div className="flex justify-between mt-4">
                  <div className="cursor-pointer" onClick={handleGoToSupply}>
                    <div className="flex flex-col">
                      <p className="text-textGreyishLabel text-left text-sm font-semibold pb-1">
                        Deposited
                      </p>
                      <p className="text-textBase text-xl font-semibold">
                        ${readablePrice(kETHDeposited || 0)}
                      </p>
                    </div>
                  </div>
                  <div className="cursor-pointer" onClick={handleGoToBorrow}>
                    <div className="flex flex-col">
                      <p className="text-textGreyishLabel text-left text-sm font-semibold pb-1">
                        Borrowed
                      </p>
                      <p className="text-textBase text-xl font-semibold">
                        ${readablePrice(kETHBorrowed || 0)}
                      </p>
                    </div>
                  </div>
                  <div
                    className={kETHSlash > 0 ? 'cursor-pointer' : 'cursor-not-allowed'}
                    onClick={kETHSlash > 0 ? handleConfirmModalOpen : () => {}}>
                    <div className="flex flex-col">
                      <p className="text-textGreyishLabel text-left text-sm font-semibold pb-2">
                        Unpaid Debt
                      </p>
                      <div className="flex items-center text-yellowAlert text-md font-semibold">
                        <YellowAlertIcon className="mr-2" />${readablePrice(kETHSlash || 0)}
                        <TopRightYellowIcon className="ml-2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <EmptyPieChartIcon />
              <div className="flex-1 flex flex-col p-2  ">
                <p className="text-textBase pb-6 font-semibold">Your Position</p>

                <div className="flex items-center">
                  <div>
                    <p className="text-grey125 text-sm ">Your Position will show up here.</p>
                    <p className="text-grey125 text-sm ">
                      Start by borrowing assets for economic restaking collateral.
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      navigate('/borrow')
                    }}>
                    <RightArrowWithCircleIcon />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-1 flex-row items-center gap-4 p-4 bg-background100 border border-borderColor rounded-2xl">
          {Number(earningsResult?.kETHEarnings || 0) > 0 ? (
            <>
              <GreenPieChartIcon />
              <div className="flex-1 flex flex-col p-4">
                <p className="text-textBase pb-1.5 font-semibold">Earned Rewards</p>
                <div className="flex justify-between items-end mt-4">
                  <div className="flex items-end gap-5">
                    <div className="flex flex-col">
                      <p className="text-textGreyishLabel text-left text-sm font-semibold pb-1">
                        Claimable
                      </p>
                      <p className="text-textBase text-2xl font-semibold">
                        ${formatNumber(Number(earningsResult?.kETHEarnings || 0))}
                      </p>
                    </div>
                    <Button onClick={handleGoToClaim} className="h-10">
                      Claim now
                    </Button>
                  </div>
                  {/* <div className="flex flex-col">
                    <div className="flex items-center text-mediumGreen text-md font-semibold">
                      <TrendUpIcon className="mr-1" />
                      {rewardPercentage}%
                    </div>
                  </div> */}
                </div>
              </div>
            </>
          ) : (
            <>
              <EmptyPieChartIcon />
              <div className="flex-1 flex flex-col p-2  ">
                <p className="text-textBase pb-6 font-semibold">Earn Rewards</p>

                <div className="flex items-center">
                  <div>
                    <p className="text-grey125 text-sm ">Your Earned Rewards will show up here.</p>
                    <p className="text-grey125 text-sm ">Deposit your assets to start earning.</p>
                  </div>
                  <button
                    className="ml-22"
                    onClick={() => {
                      navigate('/supply')
                    }}>
                    <RightArrowWithCircleIcon />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ModalSlashDebtRepayment
        open={openConfirmModal}
        setOpenConfirmModal={setOpenConfirmModal}
        unpaidDebt={kETHSlashAmount || ethers.BigNumber.from('0')}
        unpaidDebtUsd={kETHSlash || 0}
        fee={fee}
      />
    </>
  )
}
