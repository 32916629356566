import {
  Dispatch,
  FC,
  forwardRef,
  InputHTMLAttributes,
  LegacyRef,
  ReactNode,
  SetStateAction
} from 'react'

import { Tooltip } from '../Tooltip'

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode
  tooltip?: ReactNode
  selectorList: any[]
  setAmount: Dispatch<SetStateAction<string>>
  maxAmount: string
  enabled: boolean
  isError?: boolean
}

const TextInputWithSelector = (
  {
    label,
    tooltip,
    selectorList,
    setAmount,
    maxAmount,
    enabled,
    isError = false,
    ...otherProps
  }: IProps,
  ref: LegacyRef<HTMLInputElement>
) => {
  const handleSelectedItem = (value: number) => {
    if (value === 1) {
      setAmount(maxAmount)
    } else {
      setAmount((value * Number(maxAmount)).toString())
    }
  }
  const { className } = otherProps

  return (
    <div className="flex flex-col gap-1.5 pb-2">
      {label && (
        <div className="flex justify-between items-center gap-2">
          <label className={`text-md font-medium text-textInputTitle${!enabled ? ' pb-4' : ''}`}>
            {label}
          </label>

          {enabled && (
            <div className="flex items-center gap-2">
              <span className="bg-grey750 rounded-md flex items-center p-1">
                {selectorList.map((item) => {
                  return (
                    <button
                      className="outline-none p-1 rounded-lg text-sm px-3"
                      key={item.id}
                      onClick={() => {
                        handleSelectedItem(item.value)
                      }}>
                      {item.label}
                    </button>
                  )
                })}
              </span>
              {tooltip && <Tooltip message={tooltip} />}
            </div>
          )}
        </div>
      )}

      <input
        {...otherProps}
        className={`${className} ${isError ? 'border-error' : 'border-borderColor'} outline-none`}
        ref={ref}
      />
    </div>
  )
}

export default forwardRef(TextInputWithSelector)
