export enum ACTIVITY_TYPE {
  K2Deposit = 'K2.Deposit',
  K2Withdraw = 'K2.Withdraw',
  K2Borrow = 'K2.Borrow',
  K2Claim = 'K2.Claim',
  K2NodeOperatorDeposit = 'K2.NodeOperatorDeposit',
  K2NodeOperatorWithdraw = 'K2.NodeOperatorWithdraw',
  K2NodeOperatorClaim = 'K2.NodeOperatorClaim',
  K2Slash = 'K2.Slash',
  K2Terminate = 'K2.Terminate',
  K2Liquidate = 'K2.Liquidate',
  K2TopUp = 'K2.TopUp',
  K2IncreaseDebt = 'K2.IncreaseDebt'
}

export const ACTIVITY_TYPE_DISPLAY: { [key in ACTIVITY_TYPE]: string } = {
  [ACTIVITY_TYPE.K2Deposit]: 'Deposit',
  [ACTIVITY_TYPE.K2Withdraw]: 'Withdraw',
  [ACTIVITY_TYPE.K2Borrow]: 'Borrow',
  [ACTIVITY_TYPE.K2Claim]: 'Claim rewards',
  [ACTIVITY_TYPE.K2NodeOperatorDeposit]: 'Node Operator Deposit',
  [ACTIVITY_TYPE.K2NodeOperatorWithdraw]: 'Node Operator Withdraw',
  [ACTIVITY_TYPE.K2NodeOperatorClaim]: 'Claim node Operator rewards',
  [ACTIVITY_TYPE.K2Slash]: 'Slash',
  [ACTIVITY_TYPE.K2Terminate]: 'Terminate',
  [ACTIVITY_TYPE.K2Liquidate]: 'Liquidate',
  [ACTIVITY_TYPE.K2TopUp]: 'Top Up',
  [ACTIVITY_TYPE.K2IncreaseDebt]: 'Increase Debt'
}
