import { useContext, useEffect, useState } from 'react'

import { ContractContext } from '@/context/ContractContext'
import { handleErr, notifyHash, noty } from '@/utils/global'

import { useConfig } from './useConfig'
import { useCustomAccount } from './useCustomAccount'

export const useK2ReposterRegister = () => {
  const { reporterRegisterContract } = useContext(ContractContext)
  const { account, isGnosis } = useCustomAccount()
  const config = useConfig()

  const [txResult, setTxResult] = useState<any>()
  const [isRegistering, setIsRegistering] = useState(false)
  const [isReporterRegisterAvailable, setIsReporterRegisterAvailable] = useState(false)

  const fetchData = async () => {
    if (reporterRegisterContract && account) {
      try {
        const isReporterActive = await reporterRegisterContract.isReporterActive(account.address)
        const isReporterRagequitted = await reporterRegisterContract.isReporterRagequitted(
          account.address
        )
        setIsReporterRegisterAvailable(!isReporterActive && !isReporterRagequitted)
      } catch (err) {
        console.log('reporterRegisterContract reading err: ', err)
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [reporterRegisterContract, account])

  const onRegister = async () => {
    if (!reporterRegisterContract || !account) return

    setIsRegistering(true)
    let success = false
    let tx
    try {
      tx = await reporterRegisterContract.registerReporter()
      if (tx) {
        if (!isGnosis) notifyHash(tx.hash, config.networkId)
        await tx.wait()
        setTxResult(tx)
        success = true
      }
    } catch (err) {
      console.log('Reporter register error: ', err)
      noty(handleErr(err, 'Something went wrong. Reporter register failed.'))
    }

    setIsRegistering(false)
    return success
  }

  const onClear = () => {
    setTxResult(undefined)
  }

  return { onRegister, onClear, isRegistering, isReporterRegisterAvailable, txResult, fetchData }
}
