import { mainnet, useNetwork } from 'wagmi'

import KETHIcon from '@/assets/images/icon-keth-new.svg'
import { useConfig, useK2BorrowSummaryValues } from '@/hooks'
import { readablePrice, roundNumber } from '@/utils/global'

export const BorrowHeader = () => {
  const { result } = useK2BorrowSummaryValues()
  const { chain: activeChain } = useNetwork()
  const config = useConfig()
  const isMainnet = activeChain?.id == mainnet.id && config.isPrivateMainnet == 'true'

  return (
    <div className="flex gap-4 w-full mb-10">
      <div className="flex-1 p-4 bg-background100 border border-borderColor rounded-2xl">
        <p className="text-textLabel pb-1.5">Available to Borrow</p>
        <div className={`flex justify-between items-center ${isMainnet ? '' : 'mt-4'}`}>
          <p className={`text-primaryGreen ${isMainnet ? 'text-xl' : 'text-5xl'} font-semibold`}>
            {isMainnet ? 'Restricted Info' : '$' + readablePrice(result?.availableToBorrow || 0)}
          </p>
          <div className="flex flex-col gap-2">
            <div className="min-w-32 flex items-center gap-3">
              <div className="flex gap-1 items-center">
                <img src={KETHIcon} alt="K2" className="w-4" />
                <p className="text-sm text-textBase">ETH</p>
              </div>
              <div className="flex gap-1 items-center">
                <p className="text-sm text-textLabel">
                  {isMainnet ? 'Restricted Info' : roundNumber(result?.kethTotalAvailable || 0, 3)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 p-4 flex flex-col  bg-background100 border border-borderColor rounded-2xl">
        <p className="text-textLabel pb-1.5">Current Borrow Rate</p>
        <div className="flex justify-between items-center flex-1">
          <div className="flex items-center gap-6">
            <p className={`text-primaryGreen ${isMainnet ? 'text-xl' : 'text-5xl'} font-semibold`}>
              {isMainnet ? 'Restricted Info' : <>{result?.currentBorrowRate || '0'}%</>}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
