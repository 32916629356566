import { BigNumber, Contract, ethers } from 'ethers'

import { bigToNum, handleErr, noty, numToBig } from '@/utils/global'

export const depositTokenToK2Depositor = async (
  k2Depositor: Contract,
  amount: number,
  tokenAddress: string,
  isETH: boolean = false
) => {
  try {
    let tx

    if (isETH)
      tx = await k2Depositor.deposit(tokenAddress, ethers.utils.parseEther(`${amount}`), {
        value: ethers.utils.parseEther(`${amount}`)
      })
    else tx = await k2Depositor.deposit(tokenAddress, ethers.utils.parseEther(`${amount}`))

    return tx
  } catch (err) {
    console.log('depositToken error: ', err)
    noty(handleErr(err, 'Something went wrong. Depositing token failed.'))
    return undefined
  }
}

export const withdrawK2Share = async (contract: Contract, amount: string) => {
  try {
    const weiAmount = ethers.utils.parseEther(amount)
    const shareAmount = weiAmount
    const tx = await contract.withdraw(shareAmount, false)
    return tx
  } catch (err) {
    console.log('withdrawKETH error: ', err)
    noty(handleErr(err, 'Something went wrong. Withdraw transaction failed.'))
    return undefined
  }
}

// RAY = 1e27
export const RAY = ethers.BigNumber.from('1000000000000000000000000000')
