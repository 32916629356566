import { Dialog } from '@headlessui/react'
import { FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as CloseCircleIcon } from '@/assets/images/close-circle.svg'
import { ReactComponent as CloseCircleDarkIcon } from '@/assets/images/close-circle-dark.svg'
import { Button, Modal, Spinner } from '@/components/shared'
import { ThemeContext } from '@/context/ThemeContext'

import styles from './styles.module.scss'

interface IProps {
  open: boolean
  onClose: () => void
}

const ModalConfirmLearnDelegate: FC<IProps> = ({ open, onClose }) => {
  const { theme } = useContext(ThemeContext)

  return (
    <Modal open={open} onClose={onClose}>
      <Dialog.Panel className={styles.modalLayoutBig}>
        <div className="absolute top-3 right-3 cursor-pointer" onClick={onClose}>
          {theme === 'dark' ? <CloseCircleIcon /> : <CloseCircleDarkIcon />}
        </div>
        <div className="mt-5 mb-10">
          <Spinner size={60} />
        </div>
        <div className="text-lg text-white font-medium">
          External Blog or &apos;How To Tutorial&apos;
        </div>
      </Dialog.Panel>
    </Modal>
  )
}

export default ModalConfirmLearnDelegate
