import './App.css'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter as Router } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import { configureChains, createClient, goerli, mainnet, WagmiConfig } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { SafeConnector } from 'wagmi/connectors/safe'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

import { rpcUrls, supportedChains } from '@/constants/chains'

import SubApp from './SubApp'
import { holesky } from './utils/holesky'

if (!window.Buffer) {
  window.Buffer = Buffer
}

const { chains, provider } = configureChains(
  [goerli, mainnet, holesky],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        return { http: rpcUrls[chain.id] }
      }
    })
  ]
)

const client = createClient({
  autoConnect: false,
  connectors: [
    new InjectedConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        showQrModal: true,
        projectId: `${process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID}`
      }
    }),
    new SafeConnector({
      chains: supportedChains
    })
  ],
  provider
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

function App() {
  return (
    <Router>
      <IntercomProvider appId="xg5qffph" apiBase="https://api-iam.intercom.io" autoBoot>
        <WagmiConfig client={client}>
          <QueryClientProvider client={queryClient}>
            <SubApp />
          </QueryClientProvider>
        </WagmiConfig>
      </IntercomProvider>
    </Router>
  )
}

export default App
