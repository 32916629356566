import { ethers } from 'ethers'
import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react'

import BgGradient1 from '@/assets/images/bg-gradient-1.png'
import BgGradient2 from '@/assets/images/bg-gradient-2.png'
import BgGradient3 from '@/assets/images/bg-gradient-3.png'
import BgGradient4 from '@/assets/images/bg-gradient-4.png'
import BgGradient5 from '@/assets/images/bg-gradient-5.png'
import BlackETHIcon from '@/assets/images/icon-black-eth.svg'
import BlueETHIcon from '@/assets/images/icon-blue-eth.svg'
import BSNToken from '@/assets/images/icon-bsn.svg'
import CircleLogoIcon from '@/assets/images/icon-circle-logo.svg'
import GreenETHIcon from '@/assets/images/icon-deth.svg'
import DETHLPIcon from '@/assets/images/icon-deth-lp.svg'
import ETHIcon from '@/assets/images/icon-eth.svg'
import GreenPurpleETHIcon from '@/assets/images/icon-green-purple-eth.svg'
import KETHIcon from '@/assets/images/icon-keth-new.svg'
import YelloETHIcon from '@/assets/images/icon-yellow-eth.svg'
import { TokenT, useConfig } from '@/hooks'

import { FarmingModeT } from '../constants/farmingModes'

export interface EnvironmentContextProps {
  STETH: TokenT
  WSTETH: TokenT
  RETH: TokenT
  DETH: TokenT
  KETH: TokenT
  kwETH: TokenT
  dETHLP: TokenT
  ETH: TokenT
  BSN: TokenT
  GETH: TokenT
  K2Pool: TokenT
  KETH_LP_TOKENS: TokenT[]
  KETH_LP_TOKENS_FOR_ACTIVITY: TokenT[]
  DETH_LP_TOKENS: TokenT[]
  BSN_LP_TOKENS: TokenT[]
  K2_LP_TOKENS: TokenT[]
  DETH_WITHDRAW_TOKENS: TokenT[]
  KETH_WITHDRAW_TOKENS: TokenT[]
  BSN_WITHDRAW_TOKENS: TokenT[]
  K2_WITHDRAW_TOKENS: TokenT[]
  DEPOSIT_TOKENS_FOR_BALANCE_QUERY: TokenT[]
  WITHDRAW_TOKENS_FOR_BALANCE_QUERY: TokenT[]
  LSD_TOKEN_SELECTOR_OPTIONS: TokenT[]
  K2_FARMING_MODE: FarmingModeT
  FARMING_MODES: FarmingModeT[]
  isLoaded: boolean
}

export const EnvironmentContext = createContext<EnvironmentContextProps>({
  STETH: {} as TokenT,
  WSTETH: {} as TokenT,
  RETH: {} as TokenT,
  DETH: {} as TokenT,
  KETH: {} as TokenT,
  kwETH: {} as TokenT,
  dETHLP: {} as TokenT,
  ETH: {} as TokenT,
  BSN: {} as TokenT,
  GETH: {} as TokenT,
  K2Pool: {} as TokenT,
  KETH_LP_TOKENS: [],
  KETH_LP_TOKENS_FOR_ACTIVITY: [],
  DETH_LP_TOKENS: [],
  BSN_LP_TOKENS: [],
  K2_LP_TOKENS: [],
  DETH_WITHDRAW_TOKENS: [],
  KETH_WITHDRAW_TOKENS: [],
  BSN_WITHDRAW_TOKENS: [],
  K2_WITHDRAW_TOKENS: [],
  DEPOSIT_TOKENS_FOR_BALANCE_QUERY: [],
  WITHDRAW_TOKENS_FOR_BALANCE_QUERY: [],
  LSD_TOKEN_SELECTOR_OPTIONS: [],
  K2_FARMING_MODE: {} as FarmingModeT,
  FARMING_MODES: [],
  isLoaded: false
})

const EnvironmentProvider: FC<PropsWithChildren> = ({ children }) => {
  const config = useConfig()
  const [tokens, setTokens] = useState<any>({})

  const [modes, setModes] = useState<any>({})
  const [isLoaded, setIsLoaded] = useState<any>(false)

  useEffect(() => {
    const K2_FARMING_MODE: FarmingModeT = {
      id: 3,
      icons: [GreenPurpleETHIcon],
      bg: BgGradient5,
      route: 'k2',
      address: config.k2LendingDepositorAddress,
      lp: 'K2',
      title: 'K2',
      description: 'Restake ETH to earn additional rewards.',
      tvl: 500,
      apr: 18
    }

    const FARMING_MODES: FarmingModeT[] = [
      {
        id: 0,
        icons: [BlueETHIcon, YelloETHIcon, BlackETHIcon],
        bg: BgGradient1,
        route: 'kETH',
        address: config.kETHVaultContractAddress,
        lp: 'kETH',
        title: 'kETH Vault',
        description: 'Deposit stETH, rETH, dETH to receive kETH',
        tvl: 500,
        apr: 18
      },
      {
        id: 1,
        icons: [BlackETHIcon],
        bg: BgGradient2,
        route: 'dETH',
        address: config.dETHVaultContractAddress,
        lp: 'ETH',
        title: 'dETH Vault',
        description: 'Deposit dETH to receive ETH',
        tvl: 500,
        apr: 18
      },
      {
        id: 2,
        icons: [CircleLogoIcon],
        bg: BgGradient3,
        route: 'bsn',
        address: config.bsnFarmingContractAddress,
        lp: 'BSN',
        title: 'BSN Farming',
        description: 'Deposit kETH to Receive BSN',
        tvl: 500,
        apr: 18
      },
      K2_FARMING_MODE
    ]

    setModes({ K2_FARMING_MODE, FARMING_MODES })

    const STETH: TokenT = {
      id: 0,
      symbol: 'stETH',
      label: 'Lido ETH',
      icon: BlueETHIcon,
      address: config.stETHTokenAddress
    }

    const WSTETH: TokenT = {
      id: 0,
      symbol: 'wstETH',
      label: 'Wrapped Lido ETH',
      icon: BlueETHIcon,
      address: config.wstETHTokenAddress
    }

    const RETH: TokenT = {
      id: 1,
      symbol: 'rETH',
      label: 'Rocket Pool ETH',
      icon: YelloETHIcon,
      address: config.rETHTokenAddress
    }

    const DETH: TokenT = {
      id: 2,
      symbol: 'dETH',
      label: 'Stakehouse ETH',
      icon: GreenETHIcon,
      address: config.dETHTokenAddress
    }

    const KETH: TokenT = {
      id: 3,
      symbol: 'kETH',
      label: 'Optimized LSTs',
      icon: KETHIcon,
      address: config.kETHTokenAddress
    }

    const kwETH: TokenT = {
      id: 3,
      symbol: 'kwETH',
      label: 'Optimized LSTs',
      icon: KETHIcon,
      address: config.kwETHTokenAddress
    }
    const dETHLP: TokenT = {
      id: 4,
      symbol: 'dETH LP Token',
      label: 'Derivative Ethereum',
      icon: DETHLPIcon,
      address: config.kwETHTokenAddress
    }

    const ETH: TokenT = {
      id: 5,
      symbol: 'ETH',
      label: 'Ethereum',
      icon: ETHIcon,
      address: ethers.constants.AddressZero
    }

    const BSN: TokenT = {
      id: 6,
      symbol: 'BSN',
      label: 'Blockswap Network Token',
      icon: BSNToken,
      address: config.bsnTokenAddress
    }

    const GETH: TokenT = {
      id: 7,
      symbol: 'gETH',
      label: 'GiantETHLP',
      icon: KETHIcon,
      address: config.gETHTokenAddress
    }

    const K2Pool: TokenT = {
      id: 8,
      symbol: 'K2',
      label: 'K2 Pool',
      icon: KETHIcon,
      address: config.k2PoolContractAddress
    }

    const KETH_LP_TOKENS: TokenT[] = [STETH, RETH, DETH]
    const KETH_LP_TOKENS_FOR_ACTIVITY: TokenT[] = [STETH, WSTETH, RETH, DETH]
    const DETH_LP_TOKENS: TokenT[] = [DETH]
    const BSN_LP_TOKENS: TokenT[] = [KETH]
    const K2_LP_TOKENS: TokenT[] = [KETH, STETH, RETH, DETH, ETH]

    const DETH_WITHDRAW_TOKENS: TokenT[] = [ETH, DETH]
    const KETH_WITHDRAW_TOKENS: TokenT[] = [KETH, BSN]
    const BSN_WITHDRAW_TOKENS: TokenT[] = [KETH]
    const K2_WITHDRAW_TOKENS: TokenT[] = [KETH, BSN]

    const DEPOSIT_TOKENS_FOR_BALANCE_QUERY: TokenT[] = [STETH, RETH, DETH, KETH, ETH]
    const WITHDRAW_TOKENS_FOR_BALANCE_QUERY: TokenT[] = [KETH, BSN, DETH]

    const LSD_TOKEN_SELECTOR_OPTIONS: TokenT[] = [KETH, STETH, RETH, DETH, ETH]

    setTokens({
      STETH,
      WSTETH,
      RETH,
      DETH,
      KETH,
      kwETH,
      dETHLP,
      ETH,
      BSN,
      GETH,
      K2Pool,
      KETH_LP_TOKENS,
      KETH_LP_TOKENS_FOR_ACTIVITY,
      DETH_LP_TOKENS,
      BSN_LP_TOKENS,
      K2_LP_TOKENS,
      DETH_WITHDRAW_TOKENS,
      KETH_WITHDRAW_TOKENS,
      BSN_WITHDRAW_TOKENS,
      K2_WITHDRAW_TOKENS,
      DEPOSIT_TOKENS_FOR_BALANCE_QUERY,
      WITHDRAW_TOKENS_FOR_BALANCE_QUERY,
      LSD_TOKEN_SELECTOR_OPTIONS
    })

    setIsLoaded(true)
  }, [config])

  return (
    <EnvironmentContext.Provider value={{ ...tokens, ...modes, isLoaded }}>
      {children}
    </EnvironmentContext.Provider>
  )
}

export default EnvironmentProvider
