import './index.scss'

import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as ArrowLeftDarkIcon } from '@/assets/images/icon-arrow-left-dark.svg'
import { ReactComponent as ArrowLeftIcon } from '@/assets/images/icon-back.svg'
import { CardFarmingInfo, CardFarmingManage } from '@/components/app'
import { ValidatorTable } from '@/components/app/Tables/ValidatorTable'
import { ComingSoon } from '@/components/shared'
import { ThemeContext } from '@/context/ThemeContext'
import { useFarmingModes } from '@/hooks'

export const Supply = () => {
  const navigation = useNavigate()

  const { theme } = useContext(ThemeContext)

  const { K2_FARMING_MODE: selectedMode, isLoaded } = useFarmingModes()

  const handleGoBack = () => navigation('/home')

  return (
    <div className="farming">
      {/* <ComingSoon /> */}
      <div className="content">
        <div className="flex items-center gap-4 mt-10 mb-22">
          <a onClick={handleGoBack} className="cursor-pointer">
            {theme === 'dark' ? <ArrowLeftIcon /> : <ArrowLeftDarkIcon />}
          </a>
          <h2 className="text-4xl text-textBase font-semibold">Supply</h2>
        </div>
        {/* <p className="text-white mb-18">{selectedMode.description || ''}</p> */}
        {isLoaded && (
          <div className="w-full rounded-2xl border border-innerBorder py-10 px-8 flex flex-col md:flex-row gap-6 mb-10">
            <CardFarmingManage selectedMode={selectedMode} />
            <CardFarmingInfo selectedMode={selectedMode} />
          </div>
        )}
        <ValidatorTable />
      </div>
    </div>
  )
}
