import axios from 'axios'

import { EnvConfig } from '@/constants/environment'

const NUM_OF_SECONDS_IN_DAY = 86400
const DAY_RANGE_MULTIPLIER: any[] = []

const DEFAULT_MULTIPLIER = 3
const DEFAULT_POINTS = 2500
const VALIDATORS_API = 'eth/v1/beacon/states/finalized/validators'

export const getMultipleValidatorState = async (beaconNode: string, blsPublicKeys: string[]) => {
  if (!blsPublicKeys || blsPublicKeys.length === undefined || blsPublicKeys.length === 0) {
    return null
  }

  const batchLength = 75
  let beaconChainReports = []
  try {
    const numberOfBLSKeys = blsPublicKeys.length
    //console.log(`Fetching data for ${numberOfBLSKeys} BLS keys in batches of ${batchLength} to the consensus URL ${beaconNode}`);

    for (let i = 0; i < Math.ceil(numberOfBLSKeys / batchLength); ++i) {
      //console.log(`Processing batch ${i + 1} of ${Math.ceil(numberOfBLSKeys / batchLength)}\n`);
      let query = ''
      let blsPublicKeysBatch = blsPublicKeys.slice(
        i * batchLength,
        Math.min(numberOfBLSKeys, (i + 1) * batchLength)
      )

      for (let j = 0; j < blsPublicKeysBatch.length; ++j) {
        if (j === blsPublicKeysBatch.length - 1) {
          query = query + 'id=' + blsPublicKeysBatch[j]
        } else {
          query = query + 'id=' + blsPublicKeysBatch[j] + '&'
        }
      }

      const queryUrl = `https://${beaconNode}/${VALIDATORS_API}?${query}`
      let axiosResponse = await axios.get(queryUrl)

      let validatorData = []
      validatorData = axiosResponse.data.data

      for (let j = 0; j < validatorData.length; ++j) {
        const { balance, validator, index, status } = validatorData[j]
        const { pubkey, effective_balance, slashed } = validator
        //console.log(`Got validator data: [${pubkey}] [${index.toString()}] [${status}]`);

        beaconChainReports.push({
          status,
          validatorIndex: index.toString(),
          blsPublicKey: pubkey,
          slashed,
          activeBalance: balance.toString(),
          effectiveBalance: effective_balance.toString()
        })
      }
    }
  } catch (e) {
    console.log(
      'Error attempting to fetch effective balances for multiple BLS public keys',
      beaconNode
    )
    return null
  }

  if (beaconChainReports.length !== blsPublicKeys.length) {
    console.log('Failed to get the required number of beacon chain reports')
    return null
  }

  return beaconChainReports
}

export const getAllBLSKeysForNodeOperatorStillNativelyDelegated = async (
  nodeRunner: string,
  config: EnvConfig
) => {
  // TODO - handle more than 1k
  const query = `
    {
      blspublicKeys(
        first: 1000
        where: { nodeRunner: "${nodeRunner}", kicked: false }
      ) {
        id
      }
    }
  `

  const result = await axios.post(config.GRAPHQL_URL ?? '', {
    query
  })

  return result.data.data.blspublicKeys
} // TODO - check when this matters i.e. after private

export const getActiveBLSPublicKeys = async (blsPublicKeys: string[], config: EnvConfig) => {
  const beaconChainReports =
    (await getMultipleValidatorState(config.consensusLayerUrl ?? '', blsPublicKeys)) ?? []

  const activeKeys = []
  for (let i = 0; i < beaconChainReports.length; i++) {
    let report = beaconChainReports[i]
    if (report.status === 'active_ongoing') {
      activeKeys.push(report.blsPublicKey.toLowerCase())
    }
  }
  return activeKeys
}

export const getAllBalanceChangesForNodeRunners = async (nodeRunner: string, config: EnvConfig) => {
  const query = `
    {
      nativeDelegationBalanceChangeEvents(where: {
        nodeRunner: "${nodeRunner}"
      }, orderBy: timestamp, orderDirection: asc) {
        id  
        newNativeDelegationBalance
        timestamp
      }
    }
  `

  const result = await axios.post(config.GRAPHQL_URL ?? '', {
    query
  })

  return result.data.data.nativeDelegationBalanceChangeEvents
}

export const getDaysSinceStart = (startTimestamp: number, currentTimestamp: number) => {
  // TODO - num of days since the start of the point program given a timestamp
  const timeDifference = currentTimestamp - startTimestamp
  return Math.floor(timeDifference / NUM_OF_SECONDS_IN_DAY)
}
export const getMultiplierAndPoints = (currentDay: number) => {
  // Given a day since the start of the point program, what is the multiplier in force for calculations
  let currentDayNumber = Number(currentDay)
  let multiplier = DEFAULT_MULTIPLIER
  let points = DEFAULT_POINTS
  let found = false
  for (let i = 0; i < DAY_RANGE_MULTIPLIER.length; i++) {
    const dayRangeMultiplier = DAY_RANGE_MULTIPLIER[i]
    if (
      currentDayNumber >= dayRangeMultiplier.fromDay &&
      currentDayNumber <= dayRangeMultiplier.toDay
    ) {
      multiplier = dayRangeMultiplier.multiplier
      points = dayRangeMultiplier.points
      found = true
      break
    }
  }

  if (!found)
    console.log(`Day ${currentDay} does not have a configuration - falling back to defaults`)

  return {
    multiplier,
    points
  }
}
