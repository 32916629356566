import { BigNumber, ethers } from 'ethers'
import { useCallback, useContext, useEffect, useState } from 'react'

import { ContractContext } from '@/context/ContractContext'
import { UserContext } from '@/context/UserContext'
import { RAY } from '@/utils/contractFunctions'

import { useCustomAccount } from './useCustomAccount'

export type TVLandAPRT = {
  tvl: number

  totalETHBacking: number
}

export const useK2TVLandAPR = () => {
  const { account } = useCustomAccount()
  const { k2PoolContract } = useContext(ContractContext)
  const { ethPrice } = useContext(UserContext)

  const [result, setResult] = useState<TVLandAPRT>()

  const fetchData = useCallback(async () => {
    if (k2PoolContract && account && ethPrice) {
      try {
        const assumedLiquidit = await k2PoolContract.assumedLiquidity()

        let totalSupply = await k2PoolContract.totalSupply()

        let totalRestakedBN = assumedLiquidit.sub(totalSupply)
        let totalETHBacking = ethers.utils
          .formatEther(
            totalRestakedBN
              .div(ethers.BigNumber.from('24000000000000000000'))
              .mul(ethers.BigNumber.from('32000000000000000000'))
          )
          .toString()

        const totalETHBackingUsd = Number(totalETHBacking) * ethPrice

        setResult({
          totalETHBacking: Number(totalETHBacking),
          tvl: totalETHBackingUsd
        })
      } catch (err) {
        console.log('k2PoolContract reading err: ', err)
      }
    }
  }, [k2PoolContract, account, ethPrice])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { result }
}
