import './index.scss'

import { useQuery } from '@apollo/client'
import { formatEther } from 'ethers/lib/utils.js'
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import { mainnet, useNetwork } from 'wagmi'

import { ReactComponent as ArrowLeftDarkIcon } from '@/assets/images/icon-arrow-left-dark.svg'
import { ReactComponent as ArrowLeftIcon } from '@/assets/images/icon-back.svg'
import { ReactComponent as DelegatedIcon } from '@/assets/images/icon-delegated.svg'
import { ReactComponent as EarnedRCIcon } from '@/assets/images/icon-earned-rc.svg'
import { CloudTable } from '@/components/app'
import { StatBox } from '@/components/shared'
import { NODE_CLOUD_TAB } from '@/constants'
import { ThemeContext } from '@/context/ThemeContext'
import {
  K2DelegatesQuery,
  K2PNODelegationsQuery,
  K2TotalDelegationsInETHQuery
} from '@/graphql/queries/NodeRunners'
import { useCustomAccount } from '@/hooks'
import { humanReadableAddress } from '@/utils/global'

export const NodeCloud = () => {
  const navigation = useNavigate()

  const { theme } = useContext(ThemeContext)
  const { account } = useCustomAccount()
  const [activeTab, setActiveTab] = useState<NODE_CLOUD_TAB>(NODE_CLOUD_TAB.DASHBOARD)

  const handleGoBack = () => navigation('/home')
  const { chain: activeChain } = useNetwork()

  useEffect(() => {
    if (activeChain?.id == mainnet.id) handleGoBack()
  }, [activeChain?.id])

  const { loading, data: { nodeRunners } = {} } = useQuery(K2TotalDelegationsInETHQuery, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })
  const { loading: delegatesLoading, data: { delegate } = {} } = useQuery(K2DelegatesQuery, {
    variables: { account: account?.address ? account.address.toLowerCase() : '' },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })

  const { loading: pnoLoading, data: { nodeRunners: delegates } = {} } = useQuery(
    K2PNODelegationsQuery,
    {
      variables: { account: account?.address ? account.address.toLowerCase() : '' },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true
    }
  )

  const totalETHReceived = useMemo(
    () =>
      delegate && delegate.pnoLiquidDelegations && delegate.pnoLiquidDelegations.length > 0
        ? Number(
            delegate.pnoLiquidDelegations.reduce((prev: number, current: any) => {
              return Number(formatEther(current.totalDelegationsInETH)) + prev
            }, 0)
          ).toLocaleString(undefined, { maximumFractionDigits: 3 })
        : '--',
    [delegate]
  )

  const totalETHDelegated = useMemo(
    () =>
      delegates && delegates[0]?.LiquidDelegators && delegates[0]?.LiquidDelegators.length > 0
        ? Number(
            delegates[0]?.LiquidDelegators.reduce(
              (prev: number, current: any) =>
                Number(formatEther(current.delegatedAmountInETH)) + prev,
              0
            )
          ).toLocaleString(undefined, { maximumFractionDigits: 3 })
        : '--',
    [delegates]
  )

  const validators =
    activeTab === NODE_CLOUD_TAB.DASHBOARD
      ? nodeRunners
      : activeTab === NODE_CLOUD_TAB.MY_DELEGATION
      ? delegate
        ? delegate.pnoLiquidDelegations
        : []
      : delegates
      ? delegates[0]?.LiquidDelegators
        ? delegates[0].LiquidDelegators
        : []
      : []

  return (
    <div className="farming">
      <div className="content">
        {/* <div className="flex items-center gap-4 mt-10 mb-22">
          <a onClick={handleGoBack} className="cursor-pointer">
            {theme === 'dark' ? <ArrowLeftIcon /> : <ArrowLeftDarkIcon />}
          </a>
          <h2 className="text-4xl text-textBase font-semibold">Private Node Cloud</h2>
        </div> */}
        <div className="w-full flex flex-col gap-4 mt-10">
          <div className="text-4xl font-bold my-10">
            {activeTab === NODE_CLOUD_TAB.DASHBOARD ? (
              'Dashboard'
            ) : activeTab === NODE_CLOUD_TAB.MY_DELEGATION ? (
              'My Delegations'
            ) : (
              <div className="flex gap-3">
                Operator{' '}
                <OperatorAddress>
                  {delegates && delegates.name
                    ? delegates.name
                    : humanReadableAddress(account?.address)}
                </OperatorAddress>
              </div>
            )}
          </div>
          <div className="flex">
            <div className="text-grey300 bg-tabBackground flex items-center rounded-lg p-1 text-sm">
              <TabItem
                active={activeTab === NODE_CLOUD_TAB.DASHBOARD}
                onClick={() => setActiveTab(NODE_CLOUD_TAB.DASHBOARD)}>
                Dashboard
              </TabItem>
              <TabItem
                active={activeTab === NODE_CLOUD_TAB.MY_DELEGATION}
                onClick={() => setActiveTab(NODE_CLOUD_TAB.MY_DELEGATION)}>
                My Delegation
              </TabItem>
              <TabItem
                active={activeTab === NODE_CLOUD_TAB.MY_PNO}
                onClick={() => setActiveTab(NODE_CLOUD_TAB.MY_PNO)}>
                My PNO
              </TabItem>
            </div>
          </div>

          {activeTab != NODE_CLOUD_TAB.DASHBOARD && (
            <div className="flex w-full gap-6 mb-8">
              <StatBox
                icon={<DelegatedIcon />}
                value={activeTab == NODE_CLOUD_TAB.MY_PNO ? totalETHDelegated : totalETHReceived}
                isPNO={activeTab == NODE_CLOUD_TAB.MY_PNO}
                label={activeTab == NODE_CLOUD_TAB.MY_PNO ? 'Delegation Received' : 'You delegated'}
              />
              <StatBox
                icon={<EarnedRCIcon />}
                value="--"
                isPNO={activeTab == NODE_CLOUD_TAB.MY_PNO}
                label="Earned RC Point"
              />
            </div>
          )}
          <CloudTable
            loading={loading || delegatesLoading || pnoLoading}
            activeTab={activeTab}
            validators={validators}
          />
        </div>
      </div>
    </div>
  )
}

const TabItem = styled.div<{
  active: boolean
}>`
  ${tw`flex justify-center items-center py-2 px-3 cursor-pointer rounded-lg`}
  ${(props) => props.active && tw`text-primary700 font-bold bg-[#00ED7B1A]`}
`
const OperatorAddress = tw.div`text-[#FFE712]`
