import { BigNumber, ethers } from 'ethers'
import { useCallback, useContext, useEffect, useState } from 'react'
import { mainnet, useNetwork } from 'wagmi'

import { ContractContext } from '@/context/ContractContext'
import { UserContext } from '@/context/UserContext'

import { bigToNum, numToBig } from '../utils/global'
import { useCustomAccount } from './useCustomAccount'

export type SummaryValueT = {
  userDebtPosition: boolean
  kETHDeposited: number
  kETHBorrowed: number
  kETHSlash: number
  kETHSlashAmount: ethers.BigNumber
  userLenderPosition: boolean
}

export const useK2PositionSummaryValues = () => {
  const { account } = useCustomAccount()
  const { k2PoolContract, kETHVaultContract } = useContext(ContractContext)
  const { ethPrice } = useContext(UserContext)
  const { chain: activeChain } = useNetwork()
  const [result, setResult] = useState<SummaryValueT>()

  const fetchData = useCallback(async () => {
    if (k2PoolContract && account && kETHVaultContract) {
      try {
        const k2PoolUserBalance: BigNumber = await k2PoolContract.balanceOf(account.address)
        const assumedLiquidit: BigNumber = await k2PoolContract.assumedLiquidity()
        const k2PoolTotalSupply: BigNumber = await k2PoolContract.totalSupply()
        const kETHDeposited = k2PoolUserBalance.mul(assumedLiquidit).div(k2PoolTotalSupply)

        const kETHValutTotalSupply: BigNumber = await kETHVaultContract.totalSupply()
        const kETHValutTotalAssets: BigNumber = await kETHVaultContract.totalAssets()
        const kETHDepositedEthValue = bigToNum(
          kETHDeposited.mul(kETHValutTotalAssets).div(kETHValutTotalSupply)
        )

        const debtorPosition = await k2PoolContract.getDebtor(account.address)
        const userDebtPosition = debtorPosition.debtor !== ethers.constants.AddressZero
        const kETHBorrowed = debtorPosition.principalAmount
        const kETHBorrowedEthValue = bigToNum(
          kETHBorrowed.mul(kETHValutTotalAssets).div(kETHValutTotalSupply)
        )
        const kETHSlash = debtorPosition.slashAmount
        const kETHSlashEthValue = bigToNum(
          kETHSlash.mul(kETHValutTotalAssets).div(kETHValutTotalSupply)
        )

        const userLenderPosition = (
          (await k2PoolContract.balanceOf(account.address)) as BigNumber
        ).gt(0)
        // if (activeChain?.id == mainnet.id)
        setResult({
          userDebtPosition,
          kETHDeposited: kETHDepositedEthValue * ethPrice,
          kETHBorrowed: kETHBorrowedEthValue * ethPrice,
          kETHSlash: kETHSlashEthValue * ethPrice,
          kETHSlashAmount: kETHSlash,
          userLenderPosition
        })
        // Left for future testing
        // else
        // setResult({
        //   userDebtPosition: true,
        //   kETHDeposited: 25555555,
        //   kETHBorrowed: 23423423,
        //   kETHSlash: 32423423423,
        //   kETHSlashAmount: BigNumber.from('3423423423423'),
        //   userLenderPosition: false
        // })
      } catch (err) {
        console.log('k2PoolContract reading err: ', err)
        setResult({
          userDebtPosition: false,
          kETHDeposited: 0,
          kETHBorrowed: 0,
          kETHSlash: 0,
          kETHSlashAmount: BigNumber.from('0'),
          userLenderPosition: false
        })
      }
    }
  }, [k2PoolContract, kETHVaultContract, account, activeChain?.id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { result }
}
