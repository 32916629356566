import { K2 as K2SDK } from '@blockswaplab/k2-sdk'
import { StakehouseSDK } from '@blockswaplab/stakehouse-sdk'
import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react'
import { useConnect, useNetwork, useSigner } from 'wagmi'

import { supportedChains } from '@/constants/chains'
import { useConfig } from '@/hooks'
import { TK2SDK, TStakehouseSDK } from '@/types'

interface IContextProps {
  sdk: TStakehouseSDK | null
  k2SDK: TK2SDK | null
}

const AUTOCONNECTED_CONNECTOR_IDS = ['safe']

function useAutoConnect() {
  const { connect, connectors } = useConnect()

  useEffect(() => {
    AUTOCONNECTED_CONNECTOR_IDS.forEach((connector) => {
      const connectorInstance = connectors.find((c) => c.id === connector && c.ready)

      if (connectorInstance) {
        connect({ connector: connectorInstance })
      }
    })
  }, [connect, connectors])
}

export const BlockswapSDKContext = createContext<IContextProps>({
  sdk: null,
  k2SDK: null
})

const BlockswapSDKProvider: FC<PropsWithChildren> = ({ children }) => {
  const [sdk, setSDK] = useState<TStakehouseSDK | null>(null)
  const [k2SDK, setK2SDK] = useState<TK2SDK | null>(null)

  const { data: signer } = useSigner()
  const { chain: activeChain, chains } = useNetwork()
  const config = useConfig()
  useAutoConnect()

  useEffect(() => {
    if (signer && activeChain?.id === config.networkId) {
      try {
        const sdk = new StakehouseSDK(signer)
        const k2SDK = new K2SDK(signer)
        setSDK(sdk)
        setK2SDK(k2SDK)
      } catch (err) {
        console.log('err: ', err)
      }
    }
  }, [signer, activeChain])

  return (
    <BlockswapSDKContext.Provider value={{ sdk, k2SDK }}>{children}</BlockswapSDKContext.Provider>
  )
}

export default BlockswapSDKProvider
