import { BigNumber, ethers } from 'ethers'
import { useContext, useState } from 'react'

import { ContractContext } from '@/context/ContractContext'

import { useCustomAccount } from './useCustomAccount'

export type DefaultMaxLivenessAndMaxCorruptionT = {
  maxLivenessDefault: ethers.BigNumber
  maxCorruptionDefault: ethers.BigNumber
  maxLivenessUpperLimit: ethers.BigNumber
  maxCorruptionUpperLimit: ethers.BigNumber
}

export const useK2DefaultMaxLivenessAndMaxCorruption = () => {
  const { account } = useCustomAccount()
  const { k2PoolContract, partitionedLinearInterestRateModelContract } = useContext(ContractContext)

  const [result, setResult] = useState<DefaultMaxLivenessAndMaxCorruptionT>()

  const fetchData = async (borrowAmount: ethers.BigNumber) => {
    if (k2PoolContract && partitionedLinearInterestRateModelContract && account) {
      try {
        const totalBorrowableAmount: BigNumber = await k2PoolContract.getTotalBorrowableAmount()
        const totalBorrowedAmount: BigNumber = await k2PoolContract.borrowedLiquidity()

        const maxLivenessUpperLimit =
          await partitionedLinearInterestRateModelContract.maxSlashableAmountPerLivenessUpper(
            totalBorrowableAmount,
            totalBorrowedAmount,
            borrowAmount
          )
        const maxCorruptionUpperLimit =
          await partitionedLinearInterestRateModelContract.maxSlashableAmountPerCorruptionUpper(
            totalBorrowableAmount,
            totalBorrowedAmount,
            borrowAmount
          )
        setResult({
          maxLivenessUpperLimit,
          maxCorruptionUpperLimit,
          maxLivenessDefault: maxLivenessUpperLimit.div(2),
          maxCorruptionDefault: maxCorruptionUpperLimit.div(2)
        })
      } catch (err) {
        setResult({
          maxLivenessDefault: ethers.BigNumber.from(0),
          maxCorruptionDefault: ethers.BigNumber.from(0),
          maxLivenessUpperLimit: ethers.BigNumber.from(0),
          maxCorruptionUpperLimit: ethers.BigNumber.from(0)
        })
        console.log('k2PoolContract, partitionedLinearInterestRateModelContract reading err: ', err)
      }
    }
  }
  return { result, fetchData }
}
