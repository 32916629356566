import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { goerli, mainnet, useNetwork, useSwitchNetwork } from 'wagmi'

import { ReactComponent as ChevronUpIcon } from '@/assets/images/icon-arrow-up.svg'
import { ReactComponent as ArrowChevronDownIcon } from '@/assets/images/icon-chevron-down.svg'
import { ReactComponent as EthIcon } from '@/assets/images/network-eth-icon.svg'
import { ReactComponent as GoerliIcon } from '@/assets/images/network-goerli-icon.svg'
import { ReactComponent as HoleskyIcon } from '@/assets/images/network-holesky-icon.svg'
import { ReactComponent as TickIcon } from '@/assets/images/tick.svg'
import { addHoleskyNetwork } from '@/utils/addNetwork'
import { holesky } from '@/utils/holesky'

export const NetworkMenu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const { switchNetwork } = useSwitchNetwork({
    onError(error) {
      console.log('Error', error.name)
      if (error.name == 'AddChainError') {
        addHoleskyNetwork()
      }
    }
  })

  const { chain: activeChain } = useNetwork()

  const changeNetwork = (id: number) => {
    try {
      console.log('Switching Network', id)
      switchNetwork?.(id)
    } catch (error) {
      console.log('Switching Error')
    }
  }

  const networkOptions = [
    {
      id: mainnet.id,
      type: 'menu',
      label: 'Private Beta (Mainnet)',
      icon: <EthIcon />,
      onClick: () => changeNetwork(mainnet.id)
    },
    {
      id: holesky.id,
      type: 'menu',
      label: 'Holesky (ETH Testnet)',
      icon: <HoleskyIcon />,
      onClick: () => changeNetwork(holesky.id)
    }
    /*,
    {
      id: goerli.id,
      type: 'menu',
      label: 'Goerli',
      icon: <GoerliIcon />,
      onClick: () => changeNetwork(goerli.id)
    }*/
  ]
  const activeItem = useMemo(
    () =>
      networkOptions.filter((item) => item.id == activeChain?.id).length > 0
        ? networkOptions.filter((item) => item.id == activeChain?.id)[0]
        : networkOptions[0],
    [activeChain]
  )

  return (
    <div
      className="relative flex items-center justify-center rounded-lg hover:bg-grey800"
      onClick={() => setIsOpen(!isOpen)}>
      <div className="flex p-2 items-center gap-2 text-grey150">
        {activeItem.icon}
        {isOpen ? <ArrowChevronDownIcon /> : <ChevronUpIcon />}
      </div>
      {isOpen && (
        <div className="text-grey100 text-sm border-border border rounded-lg p-2 absolute top-full mt-2 right-0 z-50 w-max bg-black">
          {networkOptions.map((item, index) => (
            <div
              key={index}
              className="flex items-center py-2 px-4 gap-3 cursor-pointer rounded-md transition-all select-none  hover:bg-grey200"
              onClick={item.onClick}>
              {item.icon}
              {item.label}
              {activeChain?.id == item.id && (
                <span className="text-primary">
                  <TickIcon />
                </span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
