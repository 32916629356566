import { useContext, useState } from 'react'

import { FarmingModeT } from '@/constants/farmingModes'
import { ContractContext } from '@/context/ContractContext'
import { depositTokenToK2Depositor } from '@/utils/contractFunctions'
import { handleErr, notifyHash, noty } from '@/utils/global'

import { useConfig } from './useConfig'
import { useCustomAccount } from './useCustomAccount'
import { TokenT } from './useTokens'

export const useK2Deposit = (selectMode: FarmingModeT, token: TokenT) => {
  const { k2LendingDepositorContract } = useContext(ContractContext)
  const { account, isGnosis } = useCustomAccount()
  const config = useConfig()

  const [txResult, setTxResult] = useState<any>()
  const [isDepositing, setIsDepositing] = useState(false)

  const onDeposit = async (amount: number, tokenAddress: string, isETH: boolean = false) => {
    if (!k2LendingDepositorContract || !account) return

    if (selectMode.lp !== 'K2') return
    setIsDepositing(true)
    let tx = await depositTokenToK2Depositor(
      k2LendingDepositorContract,
      amount,
      tokenAddress,
      isETH
    )

    let success = false

    if (tx) {
      if (!isGnosis) notifyHash(tx.hash, config.networkId)
      await tx.wait()
      setTxResult(tx)
      success = true
    }

    setIsDepositing(false)
    return success
  }

  const onClear = () => {
    setTxResult(undefined)
  }

  return { onDeposit, onClear, isDepositing, txResult }
}
