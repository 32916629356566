import { ethers } from 'ethers'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { mainnet, useBalance, useNetwork } from 'wagmi'

import { ReactComponent as ArrowChevronDownIcon } from '@/assets/images/icon-chevron-down.svg'
import EthIcon from '@/assets/images/icon-eth.svg'
import {
  Button,
  CompletedTxView,
  ErrorModal,
  LoadingModal,
  ModalDialog,
  TextInputWithSelector,
  Tooltip
} from '@/components/shared'
import { MAX_GAS_FEE } from '@/constants'
import { FarmingModeT } from '@/constants/farmingModes'
import {
  TokenT,
  useAllowanceCheck,
  useConfig,
  useCurrentLockUp,
  useCustomAccount,
  useEstimatedK2Recieved,
  useFarmingModes,
  useK2Deposit,
  useMakeRealTxHash,
  useMinimumDepositAmount,
  useNetworkBasedLinkFactories,
  useTokens
} from '@/hooks'
import { convertDateToString, roundNumber, truncateToThreeDecimals } from '@/utils/global'

import { ModalTokens } from '../../Modals'
import styles from './styles.module.scss'

interface DepositSectionProps {
  selectedMode: FarmingModeT
}
const DEPOSIT_PERCENTAGE = [
  { id: 0, value: 0.25, label: '25%' },
  { id: 1, value: 0.5, label: '50%' },
  { id: 2, value: 0.75, label: '75%' },
  { id: 3, value: 1, label: 'MAX' }
]

export const DepositSection = ({ selectedMode }: DepositSectionProps) => {
  const {
    BSN_LP_TOKENS,
    DEPOSIT_TOKENS_FOR_BALANCE_QUERY,
    DETH_LP_TOKENS,
    K2_LP_TOKENS,
    KETH_LP_TOKENS
  } = useTokens()
  const [depositAmount, setDepositAmount] = useState('')
  const [selectedToken, setSelectedToken] = useState<TokenT>(KETH_LP_TOKENS[0])
  const [selectedTokenList, setSelectedTokenList] = useState<TokenT[]>(KETH_LP_TOKENS)

  const [openTokenModal, setOpenTokenModal] = useState(false)
  const [userTokenBalance] = useState<Map<number, number>>(new Map<number, number>())
  const [depositFailed, setDepositFailed] = useState(false)
  const { FARMING_MODES } = useFarmingModes()
  const { chain: activeChain } = useNetwork()
  const config = useConfig()
  const isMainnet = activeChain?.id == mainnet.id && config.isPrivateMainnet == 'true'

  const navigate = useNavigate()
  const { account } = useCustomAccount()

  const { minDepositAmount } = useMinimumDepositAmount(selectedMode, selectedToken)
  const { estimatedK2Recieved } = useEstimatedK2Recieved(Number(depositAmount), selectedToken)
  const { currentLockUp } = useCurrentLockUp('POOL', selectedMode)
  const { onDeposit, isDepositing, txResult, onClear } = useK2Deposit(selectedMode, selectedToken)
  const { hash } = useMakeRealTxHash(txResult?.hash)
  const { makeEtherscanLink } = useNetworkBasedLinkFactories()
  const { allowance, isApproving, handleApproveToken } = useAllowanceCheck(
    selectedToken?.address,
    selectedMode.address!,
    selectedToken?.symbol == 'ETH'
  )

  let tokenAddress: `0x${string}` = '0x0'

  if (selectedToken?.address?.startsWith('0x')) {
    tokenAddress = selectedToken?.address as `0x${string}`
  } else {
    console.log(`Invalid token address ${selectedToken.symbol}`)
    // throw new Error('Invalid token address')
  }

  const { data: { formatted: MAX_AMOUNT } = {}, refetch } = useBalance(
    selectedToken?.symbol == 'ETH'
      ? {
          address: account?.address,
          formatUnits: 'ether',
          chainId: config.networkId
        }
      : {
          address: account?.address,
          formatUnits: 'ether',
          token: tokenAddress,
          chainId: config.networkId
        }
  )

  DEPOSIT_TOKENS_FOR_BALANCE_QUERY.forEach(async (token: TokenT) => {
    const { data: { formatted: balance } = {} } = await useBalance(
      token.symbol != 'ETH'
        ? {
            address: account?.address,
            formatUnits: 'ether',
            token: token.address as `0x${string}`,
            chainId: config.networkId
          }
        : {
            address: account?.address,
            formatUnits: 'ether',
            chainId: config.networkId
          }
    )
    if (balance) {
      userTokenBalance.set(token.id, Number(balance))
    } else {
      userTokenBalance.set(token.id, 0)
    }
  })

  useEffect(() => {
    switch (selectedMode.route) {
      case 'kETH': {
        setSelectedToken(KETH_LP_TOKENS[0])
        setSelectedTokenList(KETH_LP_TOKENS)
        break
      }
      case 'dETH': {
        setSelectedToken(DETH_LP_TOKENS[0])
        setSelectedTokenList(DETH_LP_TOKENS)
        break
      }
      case 'bsn': {
        setSelectedToken(BSN_LP_TOKENS[0])
        setSelectedTokenList(BSN_LP_TOKENS)
        break
      }
      case 'k2': {
        setSelectedToken(K2_LP_TOKENS[0])
        setSelectedTokenList(K2_LP_TOKENS)
        break
      }
      default:
        break
    }
  }, [selectedMode])

  useEffect(() => {
    setDepositAmount('')
  }, [selectedToken])

  const handleChangeDepositAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (!value) setDepositAmount('')

    if (value === '.' || !isNaN(Number(value))) {
      setDepositAmount(value)
    }
  }

  const getMaxAmount = () => {
    if (MAX_AMOUNT) {
      return Number(MAX_AMOUNT)
    } else {
      return 0
    }
  }

  const handleOpenTokenModal = () => {
    if (selectedTokenList.length > 1) setOpenTokenModal(true)
  }
  const handleCloseTokenModal = () => setOpenTokenModal(false)
  const handleSelectToken = (token: TokenT) => {
    setSelectedToken(token)
    handleCloseTokenModal()
  }

  const handleCloseSuccessModal = () => {
    onClear()
  }

  const handleGoToHome = () => {
    navigate('/')
  }

  const handleGoToSupply = () => {
    navigate('/supply')
  }

  const handleDeposit = async () => {
    if (!selectedToken?.address) return
    let wasSucceed: boolean | undefined = false

    if (selectedToken?.symbol != 'ETH') {
      if (!allowance || allowance.lt(ethers.utils.parseEther(`${depositAmount}`))) {
        await handleApproveToken(ethers.utils.parseEther(depositAmount))
      }

      wasSucceed = await onDeposit(Number(depositAmount), selectedToken?.address)
    } else wasSucceed = await onDeposit(Number(depositAmount), selectedToken?.address, true)

    if (!wasSucceed) setDepositFailed(true)
    setDepositAmount('')
  }

  const isValidAmount = useMemo(() => {
    if (depositAmount && Number(depositAmount) >= minDepositAmount) return true

    return false
  }, [depositAmount, minDepositAmount])

  const fundEligibility = useMemo(() => {
    if (depositAmount && Number(depositAmount) <= Number(MAX_AMOUNT)) return true

    return false
  }, [depositAmount, MAX_AMOUNT])

  return (
    <div className="px-2 py-4 flex flex-col gap-4">
      <div className="relative">
        <TextInputWithSelector
          label="Supply Amount"
          type="text"
          selectorList={DEPOSIT_PERCENTAGE}
          className={styles.input}
          pattern="(\.\d{0,1}|\d*\.\d{0,2})"
          value={depositAmount}
          setAmount={setDepositAmount}
          maxAmount={MAX_AMOUNT || '0'}
          onChange={handleChangeDepositAmount}
          enabled={true}
        />
        {depositAmount && Number(depositAmount) < minDepositAmount ? (
          <p className="text-sm text-error">
            Deposit amount must be greater than {minDepositAmount}
          </p>
        ) : depositAmount && Number(depositAmount) > Number(MAX_AMOUNT) ? (
          <p className="text-sm text-error">Insufficient funds.</p>
        ) : (
          <></>
        )}
        <div className="absolute top-14 right-2 flex gap-3 items-center text-white">
          <div
            className="rounded-full p-1 pr-2 bg-background400 flex items-center gap-2 cursor-pointer"
            onClick={handleOpenTokenModal}>
            <img src={selectedToken?.icon} alt="token_icon" className="w-6" />
            <p className="text-textBase text-xl font-medium leading-5">{selectedToken?.symbol}</p>
            {selectedTokenList.length > 1 && <ArrowChevronDownIcon />}
          </div>
        </div>
        <div>
          <p className="text-textLabel text-sm text-right">
            Available to Deposit:{' '}
            {isMainnet ? (
              'Restricted Info'
            ) : (
              <>
                {`${truncateToThreeDecimals(getMaxAmount())}`} {selectedToken?.symbol}
              </>
            )}
          </p>
        </div>
      </div>

      <Button
        disabled={isApproving || isDepositing || !isValidAmount || !fundEligibility}
        onClick={handleDeposit}>
        {isApproving ? 'Approving' : isDepositing ? 'Depositing' : 'Confirm'}
      </Button>
      <div className="pt-4 gap-2 flex flex-col">
        {selectedMode !== FARMING_MODES[2] && (
          <>
            <div className="w-full flex justify-between items-center">
              <div className="flex items-center gap-2">
                <img src={EthIcon} alt="eth-icon" />
                <p className="text-textBase font-medium text-sm flex items-center gap-2">
                  Estimated {selectedMode.lp} received{' '}
                  <Tooltip
                    placement="bottom"
                    message="The amount of K2 you receive will depend on the current value of your LSD tokens and current weightings in the LST Optimizer."
                  />
                </p>
              </div>
              <p className="text-sm text-textLabel font-medium">
                {isMainnet ? 'Restricted Info K2' : roundNumber(Number(estimatedK2Recieved), 3)}
              </p>
            </div>
            {/* <div className="w-full flex justify-between items-center">
              <div className="flex items-center gap-2">
                <img src={EthIcon} alt="eth-icon" />
                <p className="text-textBase font-medium text-sm flex items-center gap-2">
                  Current lockup
                  <Tooltip message="Your kETH will be yielding during lockup and can be restaked in the BSN vault. However, it cannot be withdrawn until the lockup period is over." />
                </p>
              </div>
              <p className="text-sm text-textLabel font-medium">
                {convertDateToString(currentLockUp)}
              </p>
            </div> */}
          </>
        )}
        <div className="w-full flex justify-between items-center">
          <div className="flex items-center gap-2">
            <img src={EthIcon} alt="eth-icon" />
            <p className="text-textBase font-medium text-sm flex items-center gap-2">
              Fee
              <Tooltip placement="bottom" message="K2 is free for suppliers." />
            </p>
          </div>
          <p className="text-sm text-textLabel font-medium">
            {isMainnet ? 'Restricted Info K2' : '0.0 %'}
          </p>
        </div>
      </div>
      <ModalTokens
        open={openTokenModal}
        onClose={handleCloseTokenModal}
        onSelect={handleSelectToken}
        tokens={selectedTokenList}
        balanceDisplay={true}
        userTokenBalance={userTokenBalance}
      />
      <LoadingModal
        open={isApproving || isDepositing}
        title="Confirmation Pending"
        onClose={() => {}}
      />
      <ModalDialog open={!!txResult} onClose={handleCloseSuccessModal}>
        <CompletedTxView
          goToContent="Home"
          title="Success"
          txLink={makeEtherscanLink(hash)}
          onGoToClick={handleGoToHome}
          message={
            <span className="text-sm text-grey300">{`You've successfully made a deposit.`}</span>
          }
        />
      </ModalDialog>
      <ErrorModal
        open={!!depositFailed}
        onClose={() => {
          setDepositFailed(false)
          handleGoToSupply()
        }}
        title="Error!"
        message={'It looks like something went wrong.'}
        actionButtonContent="Try Again"
        onAction={() => {
          setDepositFailed(false)
          handleGoToSupply()
        }}
      />
    </div>
  )
}
