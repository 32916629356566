import 'twin.macro'

import { useQuery } from '@apollo/client'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as ArrowTopRightIcon } from '@/assets/images/icon-arrow-top-right.svg'
import { ReactComponent as PlusIcon } from '@/assets/images/icon-plus.svg'
import { Button, ClipboardCopy, Tooltip } from '@/components/shared/'
import { K2ValidatorsQuery } from '@/graphql/queries/NodeRunners'
import { useCustomAccount, useNetworkBasedLinkFactories } from '@/hooks'
import { humanReadableAddress, makeBeaconLink } from '@/utils/global'

import ModalConfirmLearnDelegate from '../Modals/ModalConfirmLearnDelegate'
import ModalNativeDelegation from '../Modals/ModalNativeDelegation'
import styles from './styles.module.scss'

const tableHeader = ['Validator Address', 'Status']

export const ValidatorTable: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)

  const navigate = useNavigate()
  const { makeBeaconLink } = useNetworkBasedLinkFactories()

  const { account } = useCustomAccount()

  const { loading, data: { blspublicKeys: validators } = {} } = useQuery(K2ValidatorsQuery, {
    variables: { account: account?.address ? account.address.toLowerCase() : '' },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !account
  })

  const handleLearn = () => {
    window.open(
      'https://docs.restaking.cloud/middleware/tutorials/install-native-delegation',
      '_blank'
    )
    setIsOpen(false)
    // setIsConfirmModalOpen(true)
    // setTimeout(() => {
    //   setIsConfirmModalOpen(false)
    //   navigate('/supply')
    // }, 2000)
  }

  return (
    <div className={styles.table}>
      <div className="flex items-center justify-between pb-6">
        <div className={styles.title}>Your Validators</div>
        <div>
          <Button variant="secondary" className="text-grey300" onClick={() => setIsOpen(true)}>
            <div className="flex items-center gap-2">
              <PlusIcon /> Add Validators
            </div>
          </Button>
        </div>
      </div>

      <table className={styles['auto-table']}>
        <thead className={styles.thead}>
          <tr className={`text-xs flex w-full border-b border-t border-grey750`}>
            {tableHeader.map((header, idx) => (
              <th
                key={idx}
                className={`font-medium py-5 text-grey150 text-left ${
                  idx == 1 ? 'w-60' : 'w-full'
                } ${!idx ? 'flex items-center text-left ml-4 mr-56' : ''}`}>
                <div className="inline-flex items-center">
                  <span className="mr-2">{header}</span>
                  {idx === 0 ? <Tooltip message="Validator Public Key" /> : ''}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={`${styles.tbody} max-h-80 overflow-y-auto`}>
          {validators && validators.length === 0 && !loading && (
            <tr className="w-full flex">
              <td colSpan={2} className="w-full">
                <div className="w-full py-5 text-center">No Validator</div>
              </td>
            </tr>
          )}
          {validators &&
            validators.map((item: any, index: number) => (
              <tr key={index} className={styles.tr}>
                <td className={`${styles.td} ${styles['txn-cell']}`}>
                  <span className="pr-2 text-sm">{humanReadableAddress(item.id, 10)}</span>
                  <ClipboardCopy copyText={item.id} />
                  <a
                    href={makeBeaconLink(item.id)}
                    target="_blank"
                    rel="noreferrer"
                    className={'pl-1'}>
                    <ArrowTopRightIcon />
                  </a>{' '}
                </td>

                <td className="font-medium py-5 w-60">
                  <div
                    className={`${
                      item.kicked ? 'text-undelegated' : 'text-primary'
                    } flex items-center gap-2 w-full`}>
                    <div
                      className={`${
                        item.kicked ? 'bg-undelegated' : 'bg-primary'
                      } rounded-full w-2 h-2`}
                    />
                    {item.kicked ? 'Undelegated' : 'Delegated'}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <ModalNativeDelegation
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={handleLearn}
      />
      <ModalConfirmLearnDelegate
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    </div>
  )
}
