import { useContext } from 'react'

import { BlockswapSDKContext } from '@/context/BlockswapSDKContext'

export function useSDK() {
  const { sdk, k2SDK } = useContext(BlockswapSDKContext)

  return {
    sdk,
    k2SDK
  }
}
