import { Dialog } from '@headlessui/react'
import { FC, ReactNode } from 'react'

import { ReactComponent as BackArrow } from '@/assets/images/arrow-left.svg'
import { ReactComponent as CloseCircleIcon } from '@/assets/images/close-circle.svg'

import Modal from '../index'
import styles from './styles.module.scss'

export interface ModalDialogProps {
  children: ReactNode
  open: boolean
  onClose: () => void
  controlsClosableOnly?: boolean
  useCloseIcon?: boolean
  useBackArrow?: boolean
}

export const ModalDialog: FC<ModalDialogProps> = ({
  children,
  open,
  onClose,
  controlsClosableOnly = false,
  useCloseIcon = true,
  useBackArrow = false
}) => {
  return (
    <Modal open={open} onClose={controlsClosableOnly ? () => {} : onClose}>
      <Dialog.Panel className={styles.modalDialog}>
        {useCloseIcon && <CloseCircleIcon className={styles.closeIcon} onClick={onClose} />}
        {useBackArrow && <BackArrow className={styles.backArrow} onClick={onClose} />}

        {children}
      </Dialog.Panel>
    </Modal>
  )
}
