import { useQuery } from '@apollo/client'
import { mainnet, useNetwork } from 'wagmi'

import { Spinner } from '@/components/shared'
import { FarmingModeT } from '@/constants/farmingModes'
import { K2ValidatorsQuery } from '@/graphql/queries/NodeRunners'
import { useConfig, useCustomAccount } from '@/hooks'
import { useRcPoints } from '@/hooks'
import { useK2TVLandAPR } from '@/hooks/useK2TVLandAPR'
import { formatCompactNumber, readablePrice } from '@/utils/global'

interface CardFarmingInfoProps {
  selectedMode: FarmingModeT
}

export const CardFarmingInfo = ({ selectedMode }: CardFarmingInfoProps) => {
  const { result } = useK2TVLandAPR()
  const { ethPrice, account } = useCustomAccount()

  const { loading, data: { blspublicKeys: validators } = {} } = useQuery(K2ValidatorsQuery, {
    variables: { account: account?.address ? account.address.toLowerCase() : '' },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !account
  })
  const { rcPoints, isLoading } = useRcPoints()

  const { chain: activeChain } = useNetwork()
  const config = useConfig()
  const isMainnet = activeChain?.id == mainnet.id && config.isPrivateMainnet == 'true'

  return (
    <div className="w-full items-stretch rounded-2xl bg-background100 border border-borderColor overflow-hidden">
      <div className="w-full h-28 overflow-hidden">
        <img src={selectedMode.bg} alt="card-bg" className="w-full h-full object-fill" />
      </div>
      <div className="w-full p-4 flex flex-col gap-4">
        <div className="w-full -mt-12 flex flex-col">
          <div className="relative h-18 mb-2.5">
            {selectedMode.icons.map((icon, idx) => (
              <div key={idx} className="absolute top-0" style={{ left: 48 * idx }}>
                {<img src={icon} alt="icon" />}
              </div>
            ))}
          </div>
          <p className="text-2xl text-textBase font-semibold mb-1">{selectedMode.title}</p>
          <p className="text-textLabel">{selectedMode.description}</p>
        </div>
        <div className="w-full bg-grey600" style={{ height: 1 }}></div>
        <div className="w-full pt-2 flex gap-4">
          <div className="flex-1 h-22 rounded-2xl bg-background200 py-2 px-4">
            <div className="flex items-center text-textLabel text-sm mb-3">
              <p className="pr-2">{isMainnet ? 'Your TVL' : 'TVL'}</p>
            </div>
            {validators && (
              <p className="text-3xl text-primary font-bold">
                {activeChain?.id == mainnet.id ? (
                  <>${readablePrice((validators.length || 0) * 32 * ethPrice)}</>
                ) : (
                  <>${readablePrice(result?.tvl || 0)}</>
                )}
              </p>
            )}
          </div>
          {(selectedMode.id === 0 || selectedMode.id === 3) && (
            <div className="flex-1 h-22 rounded-2xl bg-grey950 py-2 px-4">
              <div className="flex items-center text-textLabel text-sm mb-3">
                <p className="pr-2">RC Points </p>
              </div>
              <p className="text-3xl text-primary font-bold">
                {activeChain?.id === mainnet.id ? (
                  !isLoading ? (
                    <>{formatCompactNumber(rcPoints)}</>
                  ) : (
                    <Spinner size={24} />
                  )
                ) : (
                  '--'
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
