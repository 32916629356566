import './index.scss'

import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as ArrowLeftDarkIcon } from '@/assets/images/icon-arrow-left-dark.svg'
import { ReactComponent as ArrowLeftIcon } from '@/assets/images/icon-back.svg'
import { CardClaim, CardClaimInfo, TransactionTable } from '@/components/app'
import { ThemeContext } from '@/context/ThemeContext'
import { useTokens } from '@/hooks'

export const Claim = () => {
  const navigation = useNavigate()

  const { theme } = useContext(ThemeContext)
  const { KETH } = useTokens()

  const handleGoBack = () => navigation('/home')

  return (
    <div className="claiming">
      <div className="content">
        <div className="flex items-center gap-4 mt-10 mb-4">
          <a onClick={handleGoBack} className="cursor-pointer">
            {theme === 'dark' ? <ArrowLeftIcon /> : <ArrowLeftDarkIcon />}
          </a>
          <h1 className="text-4xl text-textBase font-semibold">Claim kETH</h1>
        </div>
        <p className="text-grey600 mb-18">{KETH.address || ''}</p>
        <div className="w-full rounded-2xl border border-innerBorder py-10 px-8 flex flex-col md:flex-row gap-6">
          <CardClaim />
          <CardClaimInfo />
        </div>
        <div className="transactions">
          <TransactionTable />
        </div>
      </div>
    </div>
  )
}
