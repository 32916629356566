import { ReactNode } from 'react'
import tw, { styled } from 'twin.macro'

export const StatBox = ({
  isPNO,
  value,
  label,
  icon
}: {
  isPNO: boolean
  value: string
  label: string
  icon: ReactNode
}) => {
  const Figure = () => (
    <div className="w-full flex flex-col bg-grey850 rounded-xl gap-6 p-6">
      <div className="flex items-center gap-4 font-semibold">
        {icon} {label}
      </div>
      <Label>{value}</Label>
    </div>
  )
  return (
    <>
      {isPNO ? (
        <Box>
          <Figure />
        </Box>
      ) : (
        <Figure />
      )}
    </>
  )
}

const Label = tw.div`font-semibold text-[40px]`
const Box = styled.div`
  ${tw`rounded-xl p-px w-full`}
  background: linear-gradient(90deg, #00ed76, #78a7ff, #d1adff);
`
