import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useNetwork } from 'wagmi'

import { NodeRunnerQuery } from '@/graphql/queries/NodeRunners'
import {
  getActiveBLSPublicKeys,
  getAllBalanceChangesForNodeRunners,
  getAllBLSKeysForNodeOperatorStillNativelyDelegated,
  getDaysSinceStart,
  getMultiplierAndPoints
} from '@/utils/rcPoints'

import { START_DATE_TIMESTAMP } from '../constants'
import { EnvConfig } from '../constants/environment'
import {
  BOOST_END_DATE_TIMESTAMP,
  BOOST_START_DATE_TIMESTAMP,
  SMOOTH_SOLO_STAKERS
} from '../constants/smoothSoloStakers'
import { useConfig } from './useConfig'
import { useCustomAccount } from './useCustomAccount'

export const useRcPoints = () => {
  const { account } = useCustomAccount()
  const config = useConfig()
  const { chain: activeChain } = useNetwork()
  const [rcPoints, setRcPoints] = useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { loading, data: { nodeRunner } = {} } = useQuery(NodeRunnerQuery, {
    variables: { account: account?.address ? account.address.toLowerCase() : '' },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !account
  })

  useEffect(() => {
    const fetchPoints = async () => {
      setIsLoading(true)

      const pts = await calculatePointsForNodeRunner(account?.address.toLowerCase(), config)

      setRcPoints(pts)
      setIsLoading(false)
    }

    if (nodeRunner) fetchPoints()
  }, [nodeRunner, config, account?.address, activeChain?.id])

  return { rcPoints: nodeRunner ? rcPoints : 0, isLoading: isLoading || loading }
}

const calculatePointsForNodeRunner = async (nodeRunner: string, config: EnvConfig) => {
  const blsPublicKeys = await getAllBLSKeysForNodeOperatorStillNativelyDelegated(
    nodeRunner.toLowerCase(),
    config
  )
  if (blsPublicKeys.length === 0) {
    console.log(
      `Skipping node operator [${nodeRunner.toLowerCase()}] as they do not have BLS public keys that are still natively delegated`
    )
    return 0
  }

  const blsPublicKeysThatAreStillActiveOnConsensusLayer = await getActiveBLSPublicKeys(
    blsPublicKeys.map((b: any) => b.id),
    config
  )

  let events = await getAllBalanceChangesForNodeRunners(nodeRunner.toLowerCase(), config)
  events = events.filter((event: any) => {
    const id = event.id
    const idParts = id.split('-')
    const blsPublicKey = idParts[0].toLowerCase()
    // TODO - this would need adjusting to be smarter about when exit is allowed i.e. after private mainnet it shouldn't matter if the validator exited if it was active for entire private mainnet
    return _.indexOf(blsPublicKeysThatAreStillActiveOnConsensusLayer, blsPublicKey) !== -1
  })

  let totalPoints = 0

  const smoothSoloStakers = SMOOTH_SOLO_STAKERS.map((x) => x.trim().toLowerCase())

  for (let j = 0; j < events.length; j++) {
    let event = events[j]
    const ETHBalance = Number(event.newNativeDelegationBalance / 1e18)

    const day = getDaysSinceStart(START_DATE_TIMESTAMP, event.timestamp)
    let { multiplier, points } = getMultiplierAndPoints(day)

    if (
      smoothSoloStakers.includes(nodeRunner.toLowerCase().trim()) &&
      event.timestamp >= BOOST_START_DATE_TIMESTAMP &&
      event.timestamp <= BOOST_END_DATE_TIMESTAMP
    ) {
      multiplier = multiplier * 3
    }

    const accrual = ETHBalance * points * multiplier
    //console.log(`Node runner ${nodeRunner} balance ${ETHBalance} ETH at event ${i}. Multi [${multiplier}] - Points [${points}] - Accrual ${accrual}`);

    totalPoints += accrual
  }

  return totalPoints
}
