export const SMOOTH_SOLO_STAKERS = [
  '0xdd7b216f2360cd9a30805a27e1a7c96ec1934b78',
  '0xc5aca2a9fd3486232084314959ebf583cadeed05',
  '0x2f405912248c60ce5d297c0ae88476ad52305332',
  '0xb64e2d92b22d6f3ce8346a8e4b2ebe896ac65dad',
  '0x9475e4bfdfadb5cf4ce7cf805044c0db5a8a6f8e',
  '0xe75afa985de4f013accfc8b4dd744551c6eeb5a9',
  '0x50bd01a5b83cbead5d1217c97f91ff0e67b947ed',
  '0xf0e8450d666e69a5cdfb7b4e85a0fe88b9142598',
  '0x54e3110c567ffba372e705dee13e03bf5ffd66e8',
  '0xf3329318e02fede4d0eec205216fbdbe2520e7fc',
  '0x6793df2609e5064eea0134349bbecdeaf88411f4',
  '0xccde6d870b06aaa8682477128033f1f136d5eb6b',
  '0xc80aa78dc2ad53a0db4653de027d15e5e3ea646a',
  '0x081ea40e753cd6c8cb2b03d52798999691b85299',
  '0x2a5b95c0770bd74b66d7214e60ea6619fd233687',
  '0x5c866c6ae5187130c234bc474a9fd55e3ea0ac5d',
  '0x2f191e142c20f418d2676210bcc321ea1caeb2a5',
  '0xca6b1df43188ff9199be2f64cd6e4face6d7747b',
  '0xff80562d2dc23e89194b868263de8e1987d34e72',
  '0xc23fdb28fd5312afb130a7146f898136d013442b',
  '0x0c46c148ad7406c24a944b09099fd5316d28198e',
  '0x2d981664dd74a0f84d2a4894cfb21924bed334a6',
  '0xead087dfbcad3b48c9b05a9a4e3ff79c88d97340',
  '0x2ad46eea2b43389b4aba403012a4c77fab8250ba',
  '0xcc7994e33a21702364e93e04f33927018b2a62ed',
  '0x57fa257ff4e49bbe785dd89b3419f0895a3a6092',
  '0xb2d351485de36fc8b97a7806e086f02a13a24562',
  '0x19c539943d7b3eec8e80f15b68e68ad6245f24fb',
  '0xa39f9c96c33868c87f089f4ea365f5fb373a893a',
  '0x5c2c42c476608a141cf4880afd01096e10f2a2e7',
  '0x4a96ad63ea62c4b1d57f2404258bcd96110d5d54',
  '0x32d9289304d9c3fcb44a729feed17d93a8bc60e9',
  '0xc300f21a9f3065143fec28905ba3d77dce63625a',
  '0x0857d7771aa13a0988f7aac9d72bfeea6b69d73c',
  '0x7368bb2da044d97ef5d382dbbdf8ef8cb7675097',
  '0xad999c7ec97be966dbe3ecc2ef969527635f44f1',
  '0x33f0d38f5dbf7837ae5f8a4888a376dd94c8cd5f',
  '0xbcd92f8d2e8e2c9083aebf2ccf50e03690af4087',
  '0x15df928af1772925025a0ff9a6b0ebdd7b223cbe',
  '0xbed932dca2e8160c791c47beea2e288637e0bba8',
  '0x83fbbf3bae03e2ea7de97115095e4e351354afb2',
  '0x6af2e2ffde7036f80c2ee8b4cbb08767aada1ed5',
  '0x95b3de813ae589ab5ce7210990cd856b2719d79e',
  '0xc058f776dd3e8f967a7747fb411e074832e2f9c6',
  '0xcafc5980c21d930891ad16766a60f0aa0c931793',
  '0xe74cdca69a3244753df97753008afd1ae7c642ad',
  '0x47749f19a18b7bc6f7ef321d75e9bb4d748e81d8',
  '0xea51ba9b2f12948e6bafdf68f8c99a0bd0c4dbde',
  '0xf05640c09bf280b6481abd5a8075a6bdd08e5e13',
  '0x35289f8340cf0d07c97f78b65f1d1e26aa6d91a1',
  '0x97550f107db6dd6ca03604b085bfbe07030435a9',
  '0xb567c78da68b01d4b3e40a9e892c6a9121d61374',
  '0x6a5afba23d585699f057351b4929e369d398d3dd',
  '0x66a863d18762f9530d1169bd76fa15d7495483b8',
  '0x70cc3de72b4b51b92843ae13fb9583306204afa8',
  '0x2ba193ba1f7a66d1316f51828b711a37134aed45',
  '0x460ca99ae173f4bb16f9ebc21f974cdcdc599a67',
  '0xe6f4fd1d3ef0d096b69bf46ecaf4d92f3f5a6fa9',
  '0xa91634c1c5b1bab9ca7e09a3d68d7fb8e1186425',
  '0xb2518e800bb686127ea049c94ec68708cd21f0d8',
  '0xaa5af5fdfa7d37730c687de1042be05193d36414',
  '0x02611294bbda9fdf900782f85a831fe94c07ac15',
  '0x1a2ac7c672dd8a9c131e00cbac89412d1c718032',
  '0x53a76d34dab912c3d1d555d80193421f9a6eaa48',
  '0x531e787cc5fa4e55db052df89686785eb88602af',
  '0x32b1c5e0560b09e13501b575a9dc481fa04df7d5',
  '0xbf91ca6aeae7f659d0c27073f785ab0c4d042658',
  '0x583163cbe883c26053e4d327f1da8c33ae49d094',
  '0x30a5efb7d8a1e538655c3c9fccf5cbecaab4e0df',
  '0x38c12c927af7c3be6dfbb4559198a8f61659ad5e',
  '0x677525a6263a1dc4744741119ea5150c2bce15fc',
  '0xc0c08c046fba70e65540e7c12f175a0ff5bdecd4',
  '0x821a245d69ef998888de3e86edfaab8f086e7eaa',
  '0x38aaa4a93bfb9715377f4ac3d6532da5eebe5fac',
  '0x4731486c6448cb9b27f3f500412bbef67b7c8bf8',
  '0x7d5caae6c57fbf3bed0e0911cd50b0ac17be6655',
  '0x0eb5985db084c2d8144c44b988873798bd7fa1cb',
  '0x3080fc98a410b21680910fc1aa018a9f61d28ceb',
  '0xbc42ff4e9b83294f03792952a1083222f595ec53',
  '0xa087db4d4e2ce9da6bf86f80601d189a78843d2c',
  '0x9c1ae32c81993146eb0cb0030f1277a931902419',
  '0xc7bd74c153d61b363b924ba6e3a43dfb00a20987',
  '0xce809d5f85d0a20b23b60990886afd437ebf4a72',
  '0xe0d7522d63e205c96ee8829f7b4167566567bc24',
  '0x917fc5cc9a99a4950225f3791191ab0164610b87',
  '0xb412f54c805386635817dd018f46f60d9a372e8d',
  '0x1d15a60073b81e658e40bcc500be409d581927d7',
  '0x1d9892c8439a90be075ab78e8fa254116f05ef9a',
  '0x69b994239560169ebc357e4d5299031559870f45',
  '0x8bc2da588cc281420fe977bcd84640030dce7543',
  '0x3a481b7867384a73f45c27bf08bd84e5e66850c9',
  '0x5d21ee69c587657ec1641bc6b6e329579950df1d',
  '0xe3229b6e91ff055f2965ac8e8ae3a401a11e18b8',
  '0x130ca9ab5eab1fc50f8f5d6cd33a59afdf93411b',
  '0x476bd6319198be0bd3893305a4a348975ab285f2',
  '0x0a22c6c60cc93b5b0ce18d478de93c27afb8ae79',
  '0x513f6fdfb63a207d2a989cb7b01af20066760efb',
  '0x4f912da19f9528eeb5253a4617b3c892e031082b',
  '0xe3852e4d06c6819e21931dc72c66ffd35ef89a36',
  '0x06c2c4db3776d500636de63e4f109386dcba6ae2',
  '0xbf758c3cb22670794a46e280d87fc52014468fef',
  '0x1382a5b78fa79b504a95e93033a7c0d65fadcc7d',
  '0x2e96f5d782258f2d58221942efcd4ca428c6666a',
  '0xbf53b3da7ad55e9704c6f30bdfb944105d42733f',
  '0x8ccd8bd30aa9fc8f9a16767870de6ebc2c31fa13',
  '0xc92b1d391ced58e14d36929c550510d6ada4c433',
  '0xcfe058a75895e6c036cee21a401ecd346b12ea23',
  '0x8a0db3f3bed045318df6c6d61afe986722e3daaa',
  '0xe642b55d36baec8392c6ff03bd6e9ddd1f3b6fed',
  '0x202631a1841914e8d89f0bbebf5e665b7247dac9',
  '0xb8147de60e2ce0de2f9887d5856d59e1f7ae29c2',
  '0x3cf764aa7a50a0e52c4d881a526bad2ba2c8a89d',
  '0xdc8233787c81659db945ab4ec856202af756344c',
  '0x8631730227b58d23b4c288fa7966913c395e01f9',
  '0x22670481f1ac7a1994fa3d2cae5909ecc4728389',
  '0x4c3a043b86a5952a75b29a91f0f582e239a53044',
  '0xf64c8987ca4e8bbc3366107598085d938e813793',
  '0xe13871d7f3ebaa432ee97e0f12b7b10c25bee5cd',
  '0xcefecab636175c9a79e642334e2f86bcb474faa1',
  '0x10926a1d4fe979943c86f4c928b182770809765d',
  '0x233d326d1262f85ba075a5feae6ae9caf42c4cfc',
  '0x73361361f17aeffc03c31eef1b9235a6b26d2789',
  '0xe0f905812dc5613a3f639186ac8e3c8da019a870',
  '0x829ebbdae564b0c898f79589579edcec8427dec2',
  '0x5e834523d203077b8565caf7ab7d1a3a89e60e7e',
  '0x46ede8ec6176b936b1c1b4e2564adf5ec8030194',
  '0xe587f426d7b50b7a458f04fa99c817b4865cafd8',
  '0xe5a21d42367d7fc23a1bfec2da58b7dd8e44a2a4',
  '0x852d0a99e5894a9173ffff8777f4f44d248a331f',
  '0x2e6c5757aa6017b70b03d67c91e3589488ceb28f',
  '0x8b631e73ef6e60e7aee377a26c5ea691b19c481d',
  '0xfadeb4b6f8545579d83ed786d0e5c02a1c2d905c',
  '0x253c435339030eb23012f0dbe9a5e6bbfb9a47d1',
  '0xde61aac9d4b07a9da4355813bebf54fe19f78800',
  '0x1af711e706b693147c2b6bff82cb0422d9f56c95',
  '0x1f2fce18798afbc4861b719d6c9a274da974586e',
  '0x8155a8b63368b7e1dbafa23236129aaa83c7dce8',
  '0x00e3ef121b19f47de8293f181f2ec50260982aad',
  '0xf70382d9af3d6bbc973b3c18af7d6e2c3c589781',
  '0x899d6c2c4b9dd9e2b8807d3ee5e665ae9e747c9c',
  '0xeb4b25bc18b4c6917a3ed18c2e65b71f7ba02961',
  '0xdff34fce6628353995be019187a6905b38846061',
  '0x24dbe688a8a8e07901890cab7d4bd23f527f8f45',
  '0x5963b0ea3fe4f330f6a6d45398835c563434e54c',
  '0xea155df0107e1f981294bfebb1f1c0b881defa4d',
  '0x24e5bec76fa3d750bfa97bdbf16b377f6b13af15',
  '0x71996343bad24e17fb5084e41a58b060e622badd',
  '0xc498cccf9b7a4cbf609318fb26efffe3288df6f4',
  '0x4b4edf0295e1c6dc2fa5e97c6164a1d769f49f22',
  '0x81f201b6700ab09ff687e2a2b4fdd9be9173e4a4',
  '0xf64ee4aa23a40fefd1560f04f0400a84da64f9d3',
  '0xaa0833da4dd529316f2a46957346986f615ffa62',
  '0xceb7215444192b40b37aebf6e36931e04a6e7012',
  '0xe524d6514f5c6c91796d223c3310ce211bc749c0',
  '0x3910aa2eb7583944dd03d3d723a338e0d457f5fa',
  '0xa19042b1436911c96a0d0a998f34acf31fe68bf7',
  '0x7cd1c43d541b162a7746864d03afc92a8e5561b7',
  '0xde1ed6510b33fd92d5c3f16a25c4a69cdcec1cfe',
  '0x0aa4b648f74c104b36373857115aeab2d160d0c6',
  '0xa4103e2de4ab7ba7a335f5fbda44f11fe4aa92c3',
  '0xe18cb4226fd51656392fe6842a60a34fa6a13f04',
  '0x7161e23310963fbb22452953d5737cf6b0d85e73',
  '0xe9f1a7fa22a50ba4658e4bfc52d0fbede701d541',
  '0x3b64e65aea4e0c9cdc58d9adcc729a40a1088358',
  '0xa4c90633047e27bc8334b8dbc57dd70e8ec9ed01',
  '0x4c9cfbbf1e25ae732d54ff32924613040ca0cefe',
  '0x02a7b0fa9f519a62c2d4ef47d716d041af9df7fd',
  '0x8e4cb65a57ba6dfe85495f993f4397064f391c34',
  '0x8a9720c3b1624ed00ac18ab16f7e7dba7e0fa6e8',
  '0x18eca2ef92a15cf155da9eefb502d518488b4d1c',
  '0x58c37a1dc3eccf4c313e228fcb1f6fa0ba186ed4',
  '0x442dec59ed911f4b944b1c405f6566b8daab44af',
  '0xa5dc6f2139f3a4aa045da8b0062060a4a8543405',
  '0xc3e925b3614e9714b75a67801edbe4603a54bd3e',
  '0xb33b93f896342fb11bd9459f30cce52a55c923ae',
  '0x6f36212aad5ffb626f09f1a32ade7c13b3cc389e',
  '0x0ab97e1c6e6537b847a6993221cca842f450d9d6',
  '0x07cfa4105eaa1ced1655cc5a4c550c674585099d',
  '0x4577e0c0d8c09d3a43a82658762555ff366f97a9',
  '0x287ce88763df399d64939f303f339e82192a37d4',
  '0xa1df779a7f574066545491d2bc0ea5dc6f6e2b20',
  '0x3be18040ad400ae765dd1a4dbcef7473122fc333',
  '0x4f86b85fda8a611fc87a7a1c7946217a5617bb66',
  '0xbe5b90ac316f108d36f7b1040d74425166cb6e11',
  '0x511882edd8ab3db50939f06c28fa10778377d4b9',
  '0xc29de0eb2c29ed0a2e9c5e3db8bb175a117ab3fd',
  '0x1a99bc4d0fba472e57f6852cd398ade092b4008e',
  '0x584a2aecf0d4e20ba228026f5495c05bb50835f4',
  '0x6ea68f8c8959d2b61f499b044a4871c4d7df0b0f',
  '0x6ee0e5226b0064f3cdc582b5f9c112899feff8d6',
  '0xa3e1ae2e67e4ec6f88bdc6d53c0b96e8388a2b96',
  '0x4781f6a1c504c36c4ab3ca6f4deef4ecbf2c72f4',
  '0x88f9b910f7b351d09475e06d0d48a7598fca5831',
  '0x219628bbb88944c631b940b5f79d60a026e4a23b',
  '0x8c9fac9c90757efd1009e254d9930473415496d1',
  '0x9a0f481EfcB8A91B616E1aCE06572F66334dac22',
  '0xB22981bA3FE1De2325935c91a3B717168fB86714',
  '0xB6647e02AE6Dd74137cB80b1C24333852E4AF890',
  '0xE3330351043314bc37493c9Ba601be7bBc332fd6',
  '0xd0C608C9a86229a7d16Cb8a97314AcE4f8E1e1F0',
  '0xE08Dc63DEEE327186D4631B8D08a7Ec92767B52c',
  '0xFB74c1c2Af529b8F055fD5473461A931dCc31943',
  '0x5fE43B44d8c21012530b1b33E7F2f14BA7d9EF98',
  '0xF35960302a07022aBa880DFFaEC2Fdd64d5BF1c1'
]

export const BOOST_START_DATE_TIMESTAMP = 1707664952 // 2024-02-11
export const BOOST_END_DATE_TIMESTAMP = 1717180200 // 2024-06-01
